import React, { useState } from "react";
// import { makeStyles } from '@mui/styles';
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import Fab from "@mui/material/Fab";
import SendIcon from "@mui/icons-material/Send";
import { useParams } from "react-router-dom";
import { api, useRevertGrievancesMutation } from "../services/api";
import moment from "moment";
import CustomizedProgressBars from "../Screens/CustomProgress/ProgressBar";
import { ToastContainer } from "react-toastify";

const classes = {
  table: {
    minWidth: 550,
  },
  chatSection: {
    width: "100%",
    height: "80vh",
  },
  headBG: {
    backgroundColor: "#e0e0e0",
  },
  borderRight500: {
    borderRight: "1px solid #e0e0e0",
  },
  messageArea: {
    height: "62vh",
    overflowY: "auto",
  },
  senderMessage: {
    backgroundColor: "#e0e0e0",
    padding: "10px",
    borderRadius: "10px",
    maxWidth: "60%",
    marginLeft: "auto",
    display: "inline-block",
    wordWrap: "break-word",
  },
  receiverMessage: {
    backgroundColor: "#9fe3f9",
    padding: "10px",
    borderRadius: "10px",
    // maxWidth: '60%',
    // marginRight: 'auto',
    // display: 'inline-block',
    // wordWrap: 'break-word',
  },
  senderTimestamp: {
    textAlign: "right",
    marginTop: "5px",
    fontSize: "0.8em",
    color: "#888",
  },
  receiverTimestamp: {
    textAlign: "left",
    marginTop: "5px",
    fontSize: "0.8em",
    color: "#888",
  },
};

const Chat = () => {
  const { id } = useParams();
  const [getChatData] = api.endpoints.singleRevertGrievances.useLazyQuery();
  const [closeGriev] = api.endpoints.singleGrievances.useLazyQuery();
  const [createGrievance] = useRevertGrievancesMutation();
  const [chatLog, setChatLog] = useState([]);
  const [msg, setMsg] = useState("");
  const [grievStatus, setGrievStatus] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const role = localStorage.getItem("role").toLowerCase();
  React.useEffect(() => {
      const UpdateApplication = async () => {
        if (id) {
          try {
            const res= await getChatData(id);
            if (res?.data?.success) {
              setChatLog(res?.data?.data);
            }
          } catch (error) {
            console.error("Error fetching chat data:", error);
          }
        }
      };
    UpdateApplication();
  }, [id, refresh]);
  const handleChat = async () => {
    const formData = new FormData();
    formData.append("message", msg);
    formData.append("grievance_id", id);
    formData.append("type", role === "user" ? "in" : "out");
    if(msg){
      try {
        const { data } = await createGrievance(formData);
        if (data?.success) {
          // Update refresh to trigger UpdateApplication useEffect
          setRefresh(prev=> !prev)
        }
        setMsg("");
      } catch (error) {
        console.error("Error sending message:", error);
      }
    }
  
  };
  React.useMemo(() => {
    const checkGrievance = async () => {
      try {
        const res = await closeGriev(id);
        if (res?.data.success) {
          const { status } = res?.data?.data;
          setGrievStatus(status);
        }
      } catch (error) {
        console.log("🚀 ~ checkGrievance ~ error:", error);
      }
    };
    checkGrievance();
  }, []);

  return (
    <Box >
      <Grid container>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "20%",
          flexDirection: "column",
          gap: "5px",
          marginTop: 8,
          padding:1
          // marginBottom: "2rem",
        }}
      >
        <Grid item md={6} lg={6} sm={6} mt={2}>
          <Typography variant="h5" align="center" fontWeight="600">
           Chat
          </Typography>
        </Grid>
        <Grid item md={6} lg={6} sm={6}>
          <CustomizedProgressBars val={50} />
        </Grid>
      </Grid>
      </Grid>
      <Paper className="paper-card">
        <Grid item xs={12}>
          <List sx={classes.messageArea}>
            {Array.isArray(chatLog) &&
              chatLog.map((val, index) => {
                return (
                  <ListItem key={val?._id}>
                    <Grid container>
                      <Grid
                        item
                        xs={7}
                        sx={
                          val?.type === "out"
                            ? classes.receiverMessage
                            : classes.senderMessage
                        }
                      >
                        <ListItemText align={"left"} primary={val?.message} />
                        <ListItemText
                          align={val?.type === "out" ? "left" : "right"}
                          secondary={moment(val?.broadcast_date).format("LT")}
                          sx={
                            val?.type === "out"
                              ? classes.receiverTimestamp
                              : classes.senderTimestamp
                          }
                        />
                      </Grid>
                    </Grid>
                  </ListItem>
                );
              })}
          </List>
          <Divider />
          <Grid container style={{ padding: "20px" }}>
            <Grid item xs={11}>
              <TextField
                id="outlined-basic-email"
                disabled={grievStatus === "closed" ? true : false}
                label="Type Something"
                fullWidth
                value={msg}
                onChange={(e) => {
                  setMsg(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={1} align="right">
              <Fab
                color="primary"
                aria-label="send"
                disabled={grievStatus === "closed" ? true : false}
                label="Type Something"
                onClick={() => handleChat()}
              >
                <SendIcon />
              </Fab>
            </Grid>
          </Grid>
        </Grid>
      
      </Paper>
    </Box>
  );
};

export default Chat;
