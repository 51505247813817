export default async function generateCertificate(data) {
    try {
    //     const response = await fetch(data);
    //   const blob = await response.blob();

      // Create a temporary URL for the blob
    //   const url = window.URL.createObjectURL(data);

      // Create an anchor element and trigger the download
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = data;
      a.target="_blank"
      a.download = 'certificate.pdf'; // Set the desired file name
      document.body.appendChild(a);
      a.click();

      // Cleanup
    //   window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      }
     catch (error) {
      console.error("Error fetching certificate:", error);
    }
  }