import * as React from "react";
import { Box, Paper } from "@mui/material";



export default function CustomizedSteppers({element}) {
  return (
    <>
    
      <Box mb={2}>
        <Paper
          elevation={3}
          sx={{ padding: 3, maxWidth: "100%", marginTop: 2, 
           }}
        >
          {element}
        </Paper>
      </Box>
    </>
  );
}
