import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  List,
  ListItem,
  Divider,
  Typography,
  Drawer,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import MuiAppBar from "@mui/material/AppBar";
import smallLogo from "../../Assests/hp_icon.png";
import sidebar from "../../Assests/sidebar-bg.jpg";
import Header from "../Header";
import Footerv2 from "../../Screens/Footer/indexv2";
import { store } from "../../store";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import PersonIcon from "@mui/icons-material/Person";
import SummarizeIcon from "@mui/icons-material/Summarize";
import PaymentIcon from "@mui/icons-material/Payment";
import AssignmentIcon from "@mui/icons-material/Assignment";
import PostAddIcon from "@mui/icons-material/PostAdd";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import CategoryIcon from "@mui/icons-material/Category";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import Hidden from "@mui/material/Hidden";
import PeopleIcon from "@mui/icons-material/People";
import GroupIcon from "@mui/icons-material/Groups2";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import SettingsIcon from "@mui/icons-material/Settings";
import LowPriorityIcon from "@mui/icons-material/LowPriority";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import ListAltIcon from "@mui/icons-material/ListAlt";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import BusinessIcon from "@mui/icons-material/Business";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import WidgetsIcon from "@mui/icons-material/Widgets";
import ApartmentIcon from "@mui/icons-material/Apartment";
import FolderSpecialIcon from "@mui/icons-material/FolderSpecial";
import FactoryIcon from "@mui/icons-material/Factory";
import ApprovalIcon from "@mui/icons-material/Approval";
import AccessibilityIcon from "@mui/icons-material/Accessibility";
import Face5Icon from "@mui/icons-material/Face5";
import ViewAgendaIcon from "@mui/icons-material/ViewAgenda";
import EventIcon from "@mui/icons-material/Event";
import FlagCircleIcon from "@mui/icons-material/FlagCircle";
import Face6Icon from "@mui/icons-material/Face6";
import "../../style.scss";
const drawerWidth = 300;

//==========================> Icons

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,

    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  // padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
  //   backgroundColor:'#fff',
  width: "100%",
}));

export default function Sidebar({ element }) {
  const [unival, setUniVal] = useState("");
  const [selectedTab, setSelectedTab] = useState(null);
  const location = useLocation();
  const currentPath = location.pathname;
  const [open, setOpen] = useState(true);
  const content = store.getState()?.data?.data?.sideBarContent;
  const role = localStorage.getItem("role").toLowerCase();

  useEffect(() => {
    const selectedTabInfo = content?.find(
      (item) => item.frontend_url === currentPath
    );
    if (selectedTabInfo) {
      setSelectedTab(selectedTabInfo._id);
      setUniVal(selectedTabInfo._id);
    } else {
      setSelectedTab(null);
      setUniVal("");
    }
  }, [content, currentPath]);
  useEffect(() => {
    const bodyElement = document.getElementsByTagName("body")[0];

    // Check the value of 'open' and add or remove the class accordingly
    if (!open) {
      // Add a class to the body element when 'open' is true
      bodyElement.classList.add("sidebar-body");
    } else {
      // Remove the class from the body element when 'open' is false
      bodyElement.classList.remove("sidebar-body");
    }

    // Clean up function to remove the class when component unmounts
    return () => {
      bodyElement.classList.remove("sidebar-body");
    };
  }, [open]);

  const handleToggle = (id) => {
    setSelectedTab(id === selectedTab ? null : id);
    setUniVal(id);
    // Handle other logic based on the id if needed
  };
  // const handleToggleChild = (id) => {
  //   setChildId(id);
  //   setToggle((prevState) => !prevState);
  //   // Handle other logic based on the id if needed
  // };

  const tabColor = {
    bg_white: {
      color: "#fff",
    },
  };
  const iconToRender = (name) => {
    switch (name) {
      case "Dashboard":
        return <DashboardIcon />;
      case "Routes":
        return <LocationOnIcon />;
      case "Application":
        return <DashboardIcon />;
      case "Incubation center":
        return <ViewInArIcon />;
      case "Permissions":
        return <LockPersonIcon />;
      case "Payment Logs":
        return <SummarizeIcon />;
      case "Roles":
        return <ManageAccountsIcon />;
      case "User":
        return <PersonIcon />;
      case "CMS":
        return <SettingsIcon />;
      case "Payment":
        return <PaymentIcon />;
      case "Application status":
        return <AssignmentIcon />;
      case "Category":
        return <CategoryIcon />;
      case "Custom Field":
        return <TextFieldsIcon />;
      case "Post Type":
        return <PostAddIcon />;
      case "Legislative Assembly Constituency":
        return <PeopleIcon />;
      case "Parliament Constituency":
        return <GroupIcon />;
      case "District":
        return <MyLocationIcon />;
      case "Tehsil":
        return <LocationCityIcon />;
      case "Sector":
        return <ListAltIcon />;
      case "Sub Sector":
        return <ListAltIcon />;
      case "Business Entity Type":
        return <BusinessIcon />;
      case "Platform":
        return <IntegrationInstructionsIcon />;
      case "Grievance":
        return <SummarizeIcon />;
      case "Priority":
        return <LowPriorityIcon />;
      case "State":
        return <ApartmentIcon />;
      case "Block":
        return <WidgetsIcon />;
      case "Specialization":
        return <FolderSpecialIcon />;
      // case "Industry":
      //   return <FactoryIcon />;
      case "All Application":
        return <WidgetsIcon />;
      case "Start Up Stage":
        return <ApprovalIcon />;
      case "Mentor":
        return <AccessibilityIcon />;
      case "Recognition Form":
        return <Face5Icon />;
      case "Add To Proceedings":
        return <ViewAgendaIcon />;
      case "Proceeding List":
        return <EventIcon />;
      case "Select Mentor":
        return <AccessibilityIcon />;
      case "Incubation Center":
        return <AccessibilityIcon />;
      case "Grievances":
        return <FlagCircleIcon />;
      case "All Recognition":
        return <Face6Icon />;
      case "Mentor Dashboard":
        return <DashboardIcon />;
      default:
        return null;
    }
  };
  const renderSidebarItems = (items = []) => {
    if (items?.length < 1) return null;

    return (
      <React.Fragment>
        {Array.isArray(items) &&
          items.map(({ _id, name, permission, frontend_url }, index) => {
            const isOpen = _id === unival;
            if (!permission?.show_menu) return null;
            if (name === "MPR") return;
            if (name === "sub-menu") return;
            if (name === "Industry") return;
            if (name === "Progress Report") return;
            if (name === "Select Mentor") return;
            if (name === "Profile") return;
            if (role !== "user" && name === "Application")
              return;
            if (name === "Recognition Form")
              return;
            return (
              <Box
                key={_id}
                sx={
                  {
                    //  backgroundColor: "#AD235E"
                  }
                }
              >
                <Link
                  to={frontend_url}
                  style={{
                    // color: "#AD235E",
                    textDecoration: "none",
                    height: "100vh",
                  }}
                >
                  <ListItem
                    disablePadding
                    sx={{
                      height: "4em",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor:
                        isOpen || selectedTab === _id ? "#AD235E" : null,
                    }}
                  >
                    <ListItemButton
                      onClick={() => {
                        handleToggle(_id);
                      }}
                    >
                      <ListItemIcon
                        sx={tabColor.bg_white}
                        key={_id}
                        className="sidebar_icon"
                      >
                        {iconToRender(name)}
                      </ListItemIcon>
                      <ListItemText
                        primary={name !== "sub-menu" ? name : null}
                        sx={{ color: "#fff" }}
                      />
                    </ListItemButton>
                  </ListItem>
                </Link>
                {/* {children && isOpen && toggle && (
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
          
                {children.map(({ id, icon, title, path }, index) => {
                  let istrue = childid === id;
                  return (
                    <Link
                      to={path}
                      style={{ color: "black", backgroundColor: "black" }}
                    >
                      <ListItem
                        disablePadding
                        sx={{
                          backgroundColor: istrue ? "green" : "black",
                          paddingLeft: "16px",
                          fontSize: "5px"
                        }}
                      >
                        <ListItemButton onClick={() => handleToggleChild(id)}>
                          <ListItemIcon sx={tabColor.bg_white}>
                            {icon}
                          </ListItemIcon>
                          <ListItemText
                            sx={{color: "white"}}
                            fontSize={"13px"}
                            primary={title}
                          />
                        </ListItemButton>
                      </ListItem>
                    </Link>
                  );
                })}
              </List>
            </Collapse>
          )} */}
              </Box>
            );
          })}
        {/* Hide these list items on smaller screens */}
        {/* <Hidden smDown>
          <Box sx={{ backgroundColor: "#AD235E" }}>
          </Box>
        </Hidden> */}
      </React.Fragment>
    );
  };

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar position="fixed" open={open}>
        {/* {addd header here} */}
        <Header {...{ open, setOpen }} />
      </AppBar>
      <Drawer
        className="sanjeev"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader
          className="site-logo-style"
          sx={{ gap: 2, justifyContent: "center" }}
        >
          <Link href="">
            <img src={smallLogo} alt="" style={{ width: "70px" }} />
          </Link>

          <Typography
            variant="h5"
            noWrap
            component="h6"
            color="#AD235E"
            fontWeight={"700"}
            fontSize={"26px"}
          >
            {"hp startup".toUpperCase()}
          </Typography>
        </DrawerHeader>
        <Divider />
        <List
          sx={{
            backgroundImage: `url(${sidebar})`,
            backgroundSize: "cover",
            padding: "0",
            backgroundRepeat: "no-repeat",
            overflowY: "auto",
            height: "100vh",
          }}
          className="menu-sidebar"
        >
          {renderSidebarItems(content)}
        </List>
      </Drawer>
      <Main
        open={open}
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? "#f5f7fb"
              : theme.palette.background.default,
          color: (theme) => theme.palette.text.primary,
        }}
      >
        {element}
        <Footerv2 />
      </Main>
    </Box>
  );
}
