import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Link, NavLink, useLocation } from "react-router-dom";
import Logo from "../../Assests/logo.png";
import about_startup from "../../Assests/aboutStartup.png";
import Container from "../Container";
import FlexBetween from "../FlexBetween";
import NavRouter, { NavRouting } from "./NavRouter";
import { useTranslation } from "react-i18next";
import Topbarv2 from "../Topbar/index2";
import { Badge, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import ChangeStatusDialog from "../Dialog";
const drawerWidth = 240;
// const REACT_APP_SSO_URL   = process.env;

function Navbar({handleSkipToContent, mainContentRef}) {
  const { t } = useTranslation();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [navbarBg, setNavbarBg] = React.useState("rgba(64, 64, 64, 0.7");
  const [open, setOpen] = React.useState(true);
  const [ssoName, setSsoName] = React.useState(null);
  const { ssoData } = useSelector((state) => state?.data?.data);
  const [Id, setId] = React.useState(1);
  const location = useLocation();

  const handleOpen = () => {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = "https://himstaging2.hp.gov.in/nodeapi/iframe/iframe.js";
      script.defer = true;

      script.onload = async () => {
        // Script loaded successfully, now you can access its functions
        try {
          // await getIframeSSO(REACT_APP_SSO_STAGING_SERVICE_ID, REACT_APP_SSO_STAGING_FRAME_TYPE, REACT_APP_SSO_STAGING_LOGIN_TYPE);
          setOpen(true);
          resolve();
        } catch (error) {
          reject(error);
        }
      };

      script.onerror = (error) => {
        // Error occurred while loading the script
        reject(error);
      };

      document.head.appendChild(script);
    });
  };

  // Usage example
  const handleClick = async () => {
    try {
      await handleOpen();
      // Script loaded successfully and function called
    } catch (error) {
      // Error handling if script fails to load or function call fails
      console.error("Error occurred:", error);
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    backgroundColor: "#ffffff",
    border: "2px solid #000",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    zIndex: 1000,
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        <Link to="/">
          <Box
            component="img"
            src={Logo}
            alt="Logo not found"
            sx={{
              height: "50px",
              maxWidth: "100%",
            }}
          />
        </Link>
      </Typography>
      <Divider />
      <List>
        {NavRouter.map((item) => {
          return (
            <ListItem key={item?.id}>
              <ListItemButton sx={{ textAlign: "center" }}>
                <Link
                  to={`${item?.key}`}
                  style={{
                    textDecoration: "none",
                    color: "#fff",
                    fontWeight: "large",
                  }}
                >
                  {t(item.value)}
                </Link>
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );

  React.useEffect(() => {
    const name = localStorage.getItem("name");
    localStorage.setItem("name", name);
    setSsoName(name || ssoData);
  }, [ssoName, ssoData]);

  return (
    <Box>
      <CssBaseline />

      <AppBar
        component="nav"
        position="sticky" // Fixed position
        style={{ backgroundColor: navbarBg }} // Set background color dynamically
        elevation={0}
        className="navbar-menu"
      >
        {/* TOPBAR */}
        <Box bgcolor="lightblue">
          <Topbarv2 number="+91 79823-56321" data={mainContentRef} handleSkipToContent={handleSkipToContent}/>
        </Box>
        <Container>
          <FlexBetween>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            {/* LOGO */}
            <Grid display="flex" justifyContent="space-between">
              {/* <Link to="/">
                <Box
                  component="img"
                  src={hp_icon}
                  alt="Logo"
                  sx={{
                    maxWidth: "100%",
                    height: 80,
                  }}
                />
              </Link> */}
              <Link to="/">
                <Box
                  component="img"
                  src={Logo}
                  alt="Logo"
                  sx={{
                    // width:50,
                    // height:50
                    maxWidth: "100%",
                    height: 80,
                    opacity: 1,
                    // marginLeft:-3
                  }}
                />
              </Link>
            </Grid>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
            ></Typography>

            {/* MENUS */}
            <Box sx={{ display: { xs: "none", sm: "block" } }} flexGrow={3}>
              <Box
                display={"flex"}
                justifyContent={"space-evenly"}
                // alignItems={"center"}
                spacing={5}
                sx={{ gap: 1 }}
              >
                {NavRouting.map(({ id, key, value }) => {
                  const isActive=Id === id
                  const isRefreshed=location.pathname===key 
                  return (
                    <Link
                      key={id}
                      to={`${key}`}
                      className={`${isActive &&isRefreshed?"active_tab":"tab"}`}
                      style={{
                        // textDecoration: "none",
                        color: (isActive ||isRefreshed )?"#192ed2" : "#ffffff",
                        fontWeight: (isActive || isRefreshed)? 600 : 300,
                        textDecoration: (isActive|| isRefreshed )? "underline" : "none",
                      }}
                      onClick={() => {
                        setId(id);
                      }}
                    >
                      {t(value)}
                    </Link>
                  );
                })}
              </Box>
            </Box>

            <Box>
              {/* <NavLink
                style={{
                  margin: "0 25px",
                }}
              >
                {ssoName && ssoName !== "null" ? (
                  <Badge
                    badgeContent={"Hii"}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    {" "}
                    <Typography>{ssoName}</Typography>
                  </Badge>
                ) : (
                  <Button
                    sx={{
                      color: "#fff",
                      backgroundColor: "#ff3b00d9",
                      border: "1px solid #fff",
                      borderRadius: 1,
                      "&:hover": {
                        backgroundColor: "#ff3b00d9",
                      },
                      height: 30,
                    }}
                    onClick={handleClick}
                  >
                    {"Login with SSO"}
                  </Button>
                )}
              </NavLink> */}
              {/* Open SSO Model */}
              {/* <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
                keepMounted
                hideBackdrop={false}
              >
                <Iframe
                  url={
                    "https://sso.hp.gov.in/login-iframe?service_id=10000056&is_iframe=true&login_type=Citizen"
                  }
                  width="640px"
                  height="520px"
                  id="dynamicIframe"
                  className="bg-white"
                  styles={{
                    ...style,
                    outline: "none",
                    borderWidth: "0px",
                  }}
                  display="block"
                  position="relative"
                />
              </Modal> */}
            </Box>
            <Link to="/">
              <Box
                component="img"
                src={about_startup}
                alt="Logo"
                sx={{
                  width: 70,
                  height: 70,
                  backgroundColor: "#fff",
                  borderRadius: 20,
                }}
              />
            </Link>
          </FlexBetween>
        </Container>
      </AppBar>

      {/* ... (rest of your existing code) */}
      {/* Mobile menu drawer */}
      <nav>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      {!localStorage.getItem("policy") ? (
        <ChangeStatusDialog
          open={open}
          handleClose={() => setOpen(false)}
          setOpen={setOpen}
        />
      ) : null}
    </Box>
  );
}

export default Navbar;
