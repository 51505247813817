import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import {
  api,
  useSelectMentorMutation,
  useSelectedMentorListQuery,
} from "../../services/api";
import {
  Grid,
  Paper,
  Box,
  Button,
  CardActions,
  FormControlLabel,
  Checkbox,
  TextField,
  Tooltip,
  IconButton,
} from "@mui/material";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import InfoIcon from "@mui/icons-material/Info";

const validationSchema = Yup.object({
  bank_name: Yup.string().required("Bank Name is required"),
  account_number: Yup.string()
    .matches(/^\d+$/, "Account Number must be a number")
    .required("Account Number is required"),
  ifsc_code: Yup.string().required("IFSC Code is required"),
});

export default function MentorCard() {
  const {
    data: selctedMentorList,
    isLoading,
    isError,
  } = useSelectedMentorListQuery();
  const { id } = useParams();
  const [selctedMentor] = useSelectMentorMutation();
  const navigate = useNavigate();

  const [check, setCheck] = React.useState(false);
  const [card_id, setCard_Id] = React.useState("");

  const [formValues, setFormValues] = React.useState({});
  const [formErrors, setFormErrors] = React.useState({});
  const handleCheck = (id, value) => {
    setCard_Id(id);
    setCheck(value);
  };

  const handleValue = async (_id) => {
    try {
      if (check) {
        const payload = {
          id,
          value: { mantor_id: card_id, bank_detail: formValues[_id] },
        };
        const data = await selctedMentor(payload);
        if (data?.data?.success) {
          toast.success(data?.data?.message, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setTimeout(() => {
            navigate("/application-status");
          }, 3000);
        } else if (data?.error) {
          toast.error(data?.error?.data?.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      }
    } catch (error) {
      console.log("🚀 ~ handleValue ~ error:", error);
    }
  };

  const handleInputChange = (e, mentor_id) => {
    const { name, value } = e.target;

    setFormValues((prevValues) => ({
      ...prevValues,
      [mentor_id]: {
        ...prevValues[mentor_id],
        [name]: value,
      },
    }));

    // Validate the specific field
    validationSchema
      .validateAt(name, { [name]: value })
      .then(() => {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          [mentor_id]: {
            ...prevErrors[mentor_id],
            [name]: "",
          },
        }));
      })
      .catch((err) => {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          [mentor_id]: {
            ...prevErrors[mentor_id],
            [name]: err.message,
          },
        }));
      });
  };

  const handleSubmit = (mentorId) => {
    const values = formValues[mentorId];
    const errors = validateForm(values);
    if (Object.keys(errors).length === 0) {
      console.log("Form Values:", values);
      // Implement further functionality here, e.g., form submission
    } else {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [mentorId]: errors,
      }));
    }
  };

  const validateForm = (values) => {
    const errors = {};
    if (!values.bank_name) errors.bank_name = "Bank Name is required";
    if (!values.account_number) {
      errors.account_number = "Account Number is required";
    } else if (!/^\d+$/.test(values.account_number)) {
      errors.account_number = "Account Number must be a number";
    }
    if (!values.ifsc_code) errors.ifsc_code = "IFSC Code is required";
    return errors;
  };

  return (
    <Box marginTop={"6em"} padding={4}>
      <ToastContainer />

      {/* <Paper
        elevation={3}
        sx={{
          width: "100%",
          padding: 2,
          borderRadius: "4px",
        }}
        className="paper-card"
      > */}
      <Grid container spacing={2} justifyContent="center">
        {Array.isArray(selctedMentorList?.data?.getAllMentor) &&
          selctedMentorList?.data?.getAllMentor.map(
            (
              {
                profile_pic,
                mentor_id,
                short_description,
                current_organization,
                name,
                _id,
              },
              index
            ) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={_id}>
                <Card
                  sx={{
                    maxWidth: 400,
                    minWidth: 250,
                    cursor: "pointer",
                    ":hover": { boxShadow: 6 },
                  }}
                  // onClick={() => handleCardClick(mentor_id)}
                >
                  <Grid textAlign={"right"}>
                    <Tooltip
                      title="Mentor Information"
                     
                    >
                      <IconButton  onClick={() => navigate(`/mentor-info/${_id}`)} color="primary">

                      <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <CardMedia
                    component="img"
                    sx={{ height: 140 }}
                    image={profile_pic}
                    title={name}
                  />
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h6"
                      component="div"
                      textAlign={"center"}
                    >
                      {"Mentor Name:" + " " + name}
                    </Typography>
                    <Typography
                      variant="body2"
                      fontSize={"15px"}
                      component="div"
                      color="text.primary"
                      textAlign={"center"}
                    >
                      {"Description:" + " " + short_description}
                    </Typography>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit(_id);
                      }}
                    >
                      <Typography gutterBottom variant="h6" mt={2}>
                        User Bank Details
                      </Typography>
                      <Grid
                        container
                        spacing={1}
                        justifyContent="center"
                        sx={{
                          maxWidth: 600,
                          width: "100%",
                          marginTop: 0,
                        }}
                      >
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            label="Bank Name"
                            name="bank_name"
                            value={formValues[_id]?.bank_name || ""}
                            onChange={(e) => handleInputChange(e, _id)}
                            error={formErrors[_id]?.bank_name ? true : false}
                            helperText={formErrors[_id]?.bank_name}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            label="Account Number"
                            name="account_number"
                            value={formValues[_id]?.account_number || ""}
                            onChange={(e) => handleInputChange(e, _id)}
                            error={
                              formErrors[_id]?.account_number ? true : false
                            }
                            helperText={formErrors[_id]?.account_number}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            label="IFSC Code"
                            name="ifsc_code"
                            value={formValues[_id]?.ifsc_code || ""}
                            onChange={(e) => handleInputChange(e, _id)}
                            error={formErrors[_id]?.ifsc_code ? true : false}
                            helperText={formErrors[_id]?.ifsc_code}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={_id === card_id ? check : false}
                                onChange={(e) => {
                                  handleCheck(_id, e.target.checked);
                                }}
                              />
                            }
                            label="Select Mentor"
                          />
                        </Grid>
                        <Grid item xs={12} textAlign={"center"}>
                          <Button
                            variant="contained"
                            size="medium"
                            // align
                            onClick={() => handleValue(_id)}
                          >
                            Select
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </CardContent>
                </Card>
              </Grid>
            )
          )}
      </Grid>
      {/* </Paper> */}
    </Box>
  );
}
