// ThemeContext.js
import React, { createContext, useMemo, useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';

export const ThemeContext = createContext({
  toggleColorMode: () => {},
  increaseFontSize: () => {},
  decreaseFontSize: () => {},
  resetFontSize: () => {},
});

const ThemeContextProvider = ({ children }) => {
  const [mode, setMode] = useState('light');
  const [fontSize, setFontSize] = useState(18);

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
      increaseFontSize: () => {
        setFontSize((prevFontSize) => prevFontSize + 2);
      },
      decreaseFontSize: () => {
        setFontSize((prevFontSize) => (prevFontSize > 10 ? prevFontSize - 2 : prevFontSize)); // Minimum font size limit
      },
      resetFontSize: () => {
        setFontSize(14); // Reset to default font size
      },
    }),
    []
  );

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
        },
       
    }),
    [mode]
  );

  return (
    <ThemeContext.Provider value={{ ...colorMode, fontSize }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeContextProvider;
