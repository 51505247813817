import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Typography,
  Grid,
  FormControl,
  MenuItem,
  TextField,
  Button,
  Paper,
  Select,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { Container, IconButton, InputLabel } from "@mui/material";
import {
  useGetPostTypeQuery,
  useCreateCustomFieldMutation,
  useGetSingleCustomFieldQuery,
  useUpdateCustomFieldMutation,
} from "../../services/api";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomizedProgressBars from "../CustomProgress/ProgressBar";
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";

const CustomField = () => {
  const { id } = useParams();
  const validationSchema = yup.object({
    post_type: id ? yup.array() : yup.string(),
    custom_fields: yup
      .array()
      .of(
        yup.object().shape({
          field: yup.string().required("Field is required"),
          type: yup.string().required("Type is required"),
        })
      )
      .required("Custom Fields are required"),
  });
  const { data: SingleField } = useGetSingleCustomFieldQuery(id);
  const [customFieldUpdate] = useUpdateCustomFieldMutation();
  const [createCustomField] = useCreateCustomFieldMutation();
  const { data: posttypeList, isLoading, isError } = useGetPostTypeQuery();
  const navigate = useNavigate();
  const [fieldsCount, setFieldsCount] = useState(1);
  const formik = useFormik({
    initialValues: {
      post_type: "",
      custom_fields: [{ field: "", type: "" }], // Initialize with one empty set of fields
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        if (id) {
          const obj = {
            id: id,
            payload: {
              post_typeId: formik.values.post_type,
              custom_fields: formik.values.custom_fields,
            },
          };
          const { data } = await customFieldUpdate(obj);

          if (data?.success) {
            toast.success("Custom Field is Updated successfully", {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            formik.setFieldValue("post_type", "");
            formik.setFieldValue("custom_fields", [{ field: "", type: "" }]);
            setTimeout(() => {
              navigate("/customfield");
            }, 3000);
          }
        } else {
          const { data } = await createCustomField({
            post_typeId: values.post_type,
            custom_fields: values.custom_fields,
          });
          if (data?.success) {
            toast.success("Custom Field is Created successfully", {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            formik.setFieldValue("post_type", "");
            formik.setFieldValue("custom_fields", [{ field: "", type: "" }]);
            setTimeout(() => {
              navigate("/customfield");
            }, 3000);
          } else {
            const errorMessage = data?.message || "Something went wrong";
            toast.error(errorMessage, {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        }
      } catch (error) {
        console.log("error=>", error);
      }
    },
  });

  const handleChange = (index, fieldName, value) => {
    const updatedCustomFields = [...formik.values.custom_fields];
    updatedCustomFields[index][fieldName] = value;
    formik.setFieldValue("custom_fields", updatedCustomFields);
  };
  useEffect(() => {
    (async () => {
      try {
        const { custom_fields, post_typeId } = await SingleField?.data;
        if (custom_fields?.length > 1) {
          setFieldsCount(custom_fields?.length);
        }
        if (Array.isArray(custom_fields)) {
          const updatedCustomFields = custom_fields.map(({ field, type }) => ({
            field,
            type,
          }));
          formik.setValues((prev) => ({
            ...prev,
            custom_fields: updatedCustomFields,
            post_type: post_typeId,
          }));
        }
        // Perform further operations with custom_fields and updatedCustomFields
      } catch (error) {
        console.error("Error occurred while fetching custom fields:", error);
      }
    })();
  }, [SingleField?.data]);

  const handleIncreaseFields = () => {
    setFieldsCount((prevCount) => prevCount + 1);
    formik.setFieldValue("custom_fields", [
      ...formik.values.custom_fields,
      { field: "", type: "" }, // Add an empty set of fields
    ]);
  };

  const handleDecreaseFields = () => {
    if (fieldsCount > 1) {
      setFieldsCount((prevCount) => prevCount - 1);
      formik.setFieldValue(
        "custom_fields",
        formik.values.custom_fields.slice(0, -1)
      ); // Remove the last set of fields
    }
  };
  return (
    <Box marginTop={"6em"} padding={4}>
      <Paper className="paper-card">
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant="h5">Custom Fields</Typography>
          </Grid>
        </Grid>
        <Grid marginTop={2} spacing={2}>
          {/* post_type Type */}
          <Grid container xl={12} lg={12} md={6} sm={12} xs={12}>
            <FormControl fullWidth>
              <InputLabel id="post_type" sx={{ color: "#000" }}>
                Select Post Type
              </InputLabel>
              <Select
                fullWidth
                readOnly={id ? true : false}
                value={formik.values.post_type}
                onChange={(e) =>
                  formik.setFieldValue("post_type", e.target.value)
                }
                labelId="post_type-label"
                id="post_type"
                name="post_type"
                label="post_type Type"
                autoComplete="off"
                placeholder="Select post_type"
              >
                {!isLoading &&
                  posttypeList?.data?.map((item) => (
                    <MenuItem value={item._id} key={item._id}>
                      {item.post_type}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            {/* </TextField> */}
          </Grid>
          <Grid container spacing={1} alignItems="center">
            {[...Array(fieldsCount)].map((_, index) => (
              <React.Fragment key={index}>
                <Grid item xl={5} lg={5} md={5} sm={5} xs={5}>
                  <TextField
                    margin="normal"
                    fullWidth
                    required
                    id={`field-${index}`}
                    label="Field"
                    name={`field-${index}`}
                    placeholder="Field"
                    autoComplete={`field-${index}`}
                    value={formik.values?.custom_fields[index]?.field}
                    onChange={(e) =>
                      handleChange(index, "field", e.target.value)
                    }
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.custom_fields && // Check if custom_fields array has been touched
                      formik.touched.custom_fields[index] && // Check if this specific field has been touched
                      formik.errors.custom_fields && // Check if there are errors in custom_fields array
                      formik.errors.custom_fields[index]?.field // Check if there's an error for this specific field
                    }
                    helperText={
                      formik.touched.custom_fields && // Check if custom_fields array has been touched
                      formik.touched.custom_fields[index] && // Check if this specific field has been touched
                      formik.errors.custom_fields && // Check if there are errors in custom_fields array
                      formik.errors.custom_fields[index]?.field // Show error message for this specific field
                    }
                  />
                </Grid>
                <Grid item xl={5} lg={5} md={5} sm={5} xs={5}>
                  <TextField
                    margin="normal"
                    fullWidth
                    required
                    id={`type-${index}`}
                    label="Type"
                    name={`type-${index}`}
                    autoComplete={`type-${index}`}
                    value={formik.values?.custom_fields[index]?.type}
                    onChange={(e) =>
                      handleChange(index, "type", e.target.value)
                    }
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.custom_fields && // Check if custom_fields array has been touched
                      formik.touched.custom_fields[index] && // Check if this specific field has been touched
                      formik.errors.custom_fields && // Check if there are errors in custom_fields array
                      formik.errors.custom_fields[index]?.type // Check if there's an error for this specific field
                    }
                    helperText={
                      formik.touched.custom_fields && // Check if custom_fields array has been touched
                      formik.touched.custom_fields[index] && // Check if this specific field has been touched
                      formik.errors.custom_fields && // Check if there are errors in custom_fields array
                      formik.errors.custom_fields[index]?.type // Show error message for this specific field
                    }
                  />
                </Grid>
                <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                  <IconButton
                    color="primary"
                    onClick={() => handleIncreaseFields(index)}
                  >
                    <AddCircleOutline />
                  </IconButton>
                  {formik.values.custom_fields.length > 1 && (
                    <IconButton
                      color="secondary"
                      onClick={() => handleDecreaseFields(index)}
                    >
                      <RemoveCircleOutline />
                    </IconButton>
                  )}
                </Grid>
              </React.Fragment>
            ))}
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} gap={2}>
              <Button
                type="button"
                onClick={formik.handleSubmit}
                variant="contained"
              >
                {id ? "Update" : "Submit"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>

      <ToastContainer />
      {/* <Banner /> */}
    </Box>
  );
};

export default CustomField;
