import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import React, { useCallback, useState, useMemo } from "react";
import { Delete, Edit } from "@mui/icons-material/";
import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import {
  api,
  useDeleteApplicantMutation,
  useGetApplicationQuery,
  useGetDistrictQuery,
} from "../../services/api";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import swal from "sweetalert";
import { toast, ToastContainer } from "react-toastify";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Tooltip from "@mui/material/Tooltip";
import DownloadIcon from "@mui/icons-material/Download";
import jsPDF from "jspdf";
import "jspdf-autotable";
import generateCertificate from "../../Helpers/DownloadCertificate";
import LoadingButton from "@mui/lab/LoadingButton";

const StyledGridOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
}));

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <Box sx={{ mt: 1 }}>No Rows</Box>
    </StyledGridOverlay>
  );
}

const ApplicationIndex = () => {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const navigate = useNavigate();
  const location = useLocation();
  const role = localStorage.getItem("role").toLowerCase();
  const [district, setDistrict] = useState("");
  const { data: applicationList, isLoading } = useGetApplicationQuery({
    page: paginationModel.page + 1,
    limit: paginationModel.pageSize,
    data: `${district ? district : null}`,
  });
  const [applicantDelete] = useDeleteApplicantMutation();
  const { data: districtList } = useGetDistrictQuery();
  const [applicationCertificate] =
    api.endpoints.generateApplicationCertificate.useLazyQuery();
  const [loading, setLoading] = useState(false);

  const handleDelete = (id) => {
    swal({
      title: "Are you sure?",
      text: "Do you want delete the Application!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        applicantDelete(id);
        swal("Application is deleted Successfully.!", {
          icon: "success",
        });
      } else {
        swal("Application is safe!");
      }
    });
  };

  const handleEdit = (_id) => {
    localStorage.setItem("application_id", _id);
    navigate(`/applicant`);
  };

  const handleMentor = (id) => {
    navigate(`/select-mentor/${id}`);
  };

  const handleCertificate = async (id) => {
    try {
      setLoading(true);
      const { data } = await applicationCertificate(id);
      if (data?.success) {
        generateCertificate(data?.url);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const handleClickOpen = (id) => {
    navigate(`/progress-report/${id}`);
  };

  const getActionsWrapper = ({ params = null, role = null, loading }) => {
    const actions = [];
    switch (role) {
      case "user":
        if (!params?.row?.finalSubmit) {
          actions.push(
            <GridActionsCellItem
             color="success"
              icon={<Edit />}
              onClick={() => handleEdit(params.row._id)}
              label="Edit"
            />,
            <Button
              onClick={() => navigate(`/applicant/preview/${params?.id}`)}
              title="View Application"
              variant="contained"
              size="small"
            >
              View Application
            </Button>,
            <Button
              onClick={() => handleReco(params?.id)}
              title="Copy to Recognition"
              variant="contained"
              size="small"
            >
              Copy to Recognition
            </Button>,
            
          );
        } else if (
          params?.row?.finalSubmit &&
          params?.row?.status !== "selected"
        ) {
          actions.push(
            <Button
              onClick={() => navigate(`/applicant/preview/${params?.id}`)}
              title="View Application"
              variant="contained"
              size="small"
            >
              View Application
            </Button>,
            <Button
              onClick={() => handleReco(params?.id)}
              title="Copy to Recognition"
              variant="contained"
              size="small"
            >
              Copy to Recognition
            </Button>,
          );
        }
        if (
          params?.row?.finalSubmit &&
          params?.row?.status === "selected" &&
          params?.row?.mantor_id === null
        ) {
          actions.push(
            <Button
              onClick={() => navigate(`/applicant/preview/${params?.id}`)}
              title="View Application"
              variant="contained"
              size="small"
            >
              View Application
            </Button>,

            <Button
              onClick={() => handleMentor(params.row._id)}
              title="Select Mentor"
              variant="contained"
              size="small"
            >
              Select Mentor
            </Button>
          );
        }
         else if (
          params?.row?.status === "selected" &&
          params?.row?.copy_to_recognition === false &&
          params?.row?.mantor_id
        ) {
          actions.push(
            <Button
              onClick={() => navigate(`/applicant/preview/${params?.id}`)}
              title="View Application"
              variant="contained"
              size="small"
            >
              View Application
            </Button>,
            <Button
              onClick={() => handleReco(params?.id)}
              title="Copy to Recognition"
              variant="contained"
              size="small"
            >
              Copy to Recognition
            </Button>,
            <Button
              onClick={() => handleClickOpen(params.row._id)}
              title="Change Status"
              variant="contained"
              size="small"
            >
              Generate PR
            </Button>
          );
        }
        if (
          params?.row?.finalSubmit &&
          params?.row?.dicstatus === "approved" &&
          params?.row?.directoratestatus === "approved"
        ) {
          actions.push(
            <LoadingButton
              variant="contained"
              onClick={() => handleCertificate(params?.row?._id)}
              disabled={loading}
              endIcon={<VisibilityIcon />}
              loading={loading}
              size="small"
            >
              Application Certificate
            </LoadingButton>
          );
        }
        break;

      case "incubation":
      case "mentor":
      case "dic":
      case "hod":
      case "admin":
        // case "user":
        if (params?.row?.finalSubmit) {
          actions.push(
            <Button
              onClick={() => navigate(`/applicant/preview/${params?.id}`)}
              title="View Application"
              variant="contained"
              size="small"
            >
              View Application
            </Button>,
            //   <Button
            //   onClick={() => handleReco(params?.id)}
            //   title="Copy to Recognition"
            //   variant="contained"
            //   size="small"
            // >
            //   Copy to Recognition
            // </Button>,
            <Button
              onClick={() => navigate(`/progress-report/${params?.id}`)}
              title="View PR"
              variant="contained"
              size="small"
            >
              View PR
            </Button>
          );
        }
        break;

      case "superadmin":
      case "dic":
      case "admin":
      case "hod":
        // if (params?.row?.finalSubmit) {
        actions.push(
          // <GridActionsCellItem
          //   icon={<Edit />}
          //   onClick={() => handleEdit(params.row._id)}
          //   title="Edit Application"
          //   label="Edit"
          // />,
          <GridActionsCellItem
          color="error"
            icon={<Delete />}
            onClick={() => handleDelete(params.row._id)}
            label="Delete"
            title="Delete Application"
          />,
          <Button
            onClick={() => navigate(`/applicant/preview/${params?.id}`)}
            title="View Application"
            variant="contained"
            size="small"
          >
            View Application
          </Button>,
          <Button
            onClick={() => navigate(`/progress-report/${params?.id}`)}
            title="View PR"
            variant="contained"
            size="small"
          >
            View PR
          </Button>,
        //    <LoadingButton
        //    variant="contained"
        //    onClick={() => handleCertificate(params?.row?._id)}
        //    disabled={params?.row?._id?loading:false}
        //    endIcon={<VisibilityIcon />}
        //    loading={loading}
        //    size="small"
        //  >
        //    Application Certificate
        //  </LoadingButton>
        );
        // }
        break;

      default:
        break;
    }

    // Common actions regardless of role
    // if (params?.row?.finalSubmit && params?.row?.copy_to_recognition) {
    //   actions.push(
    //     <Button
    //       onClick={() => navigate(`/applicant/preview/${params?.id}`)}
    //       title="View Application"
    //       variant="contained"
    //       size="small"
    //     >
    //       View Application
    //     </Button>
    //     // <Button
    //     //   title="Copy to Recognition"
    //     //   variant="contained"
    //     //   size="small"
    //     //   // onClick={() => handleMentor(params.row._id)}
    //     // >
    //     //   Copy to Recognition
    //     // </Button>
    //   );
    // }
    return actions;
  };
  const handleReco = (id) => {
    navigate("/recognition-form", { state: id });
  };
  const columns = useMemo(
    () => [
      {
        field: "founder_name",
        headerName: "Founder Name",
        flex: 0.7,
      },
      {
        field: "category",
        headerName: "Category",
        flex: 0.6,
      },
      {
        field: "email",
        headerName: "Email",
         flex: 0.8,
      },
      {
        field: "contact",
        headerName: "Mobile Number",
         flex: 0.7,
      },
      {
        field: "actions",
        headerName: "Actions",
        type: "actions",
        flex: 2.6,
        getActions: (params) => getActionsWrapper({ params, role, loading }),
      },
    ],
    [role, loading]
  );

  const handlePageSizeChange = useCallback((newPageSize) => {
    setPaginationModel((prev) => ({ ...prev, pageSize: newPageSize.pageSize }));
  }, []);

  const handlePaginationModelChange = useCallback((data) => {
    setPaginationModel(data);
  }, []);

  const handlePdf = async () => {
    const headers = [
      { title: "Aadhaar", id: "aadhaar" },
      { title: "Application Stage", id: "application_stage" },
      { title: "Business Address", id: "business_address" },
      { title: "Category", id: "category" },
      { title: "Co founders", id: "co_founders" },
      { title: "Contact", id: "contact" },
      { title: "Email", id: "email" },
      { title: "Revenue", id: "revenue" },
      { title: "Uniqueness", id: "uniqueness" },
      { title: "Women Led", id: "women_led" },
    ];

    const doc = new jsPDF({
      orientation: "landscape",
      // unit: "in",
      // format: [8, 6]
    });

    try {
      const applicationData = applicationList?.data?.AllApplication || [];
      const tableData = applicationData.map((item) => {
        return headers.reduce((acc, header) => {
          acc[header.id] = item[header.id] || "";
          return acc;
        }, {});
      });

      doc.autoTable({
        head: [headers.map((header) => header.title)],
        body: tableData.map((item) => headers.map((header) => item[header.id])),
        margin: { top: 10 },
        styles: { fontSize: 10 },
      });

      doc.save("application_list.pdf");
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  return (
    <Box marginTop={"6em"} padding={4}>
      <ToastContainer />
      <Paper className="paper-card">
        <Grid container>
          <Grid item lg={9} md={9} sm={9} xs={9}>
            <Typography variant="h5">Application List</Typography>
          </Grid>
          {applicationList?.data?.AllApplication.length > 0 ? (
            <Grid className="button-right" item lg={3} md={3} sm={3} xs={3}>
              <Tooltip title="Download PDF" arrow>
                <IconButton sx={{ mt: 1 }} onClick={handlePdf} color="primary">
                  <DownloadIcon sx={{ width: 30, height: 30 }} />
                </IconButton>
              </Tooltip>
            </Grid>
          ) : null}
          {role === "dic" ? (
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12} mt={3}>
              {/* <InputLabel id="language_change" sx={{ color: "#000" }}>
                District :
              </InputLabel> */}
              <FormControl fullWidth>
                <InputLabel id="district">District</InputLabel>
                <Select
                  margin="dense"
                  fullWidth
                  id="district"
                  label="District"
                  name="district"
                  value={district}
                  onChange={(e) => setDistrict(e.target.value)}
                  // onBlur={formik.handleBlur}
                  // error={
                  //   formik.touched.district && Boolean(formik.errors.district)
                  // }
                  // helperText={formik.touched.district && formik.errors.district}
                >
                  {districtList?.data?.map((item) => (
                    <MenuItem value={item._id} key={item._id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          ) : null}
        </Grid>

        <Box marginTop={2} width="100%">
          {applicationList?.data?.AllApplication.length > 0 ? (
            <DataGrid
              columns={columns}
              // autoHeight
              // rowHeight={52} // Adjusted row height
              getRowHeight={() => 40} // Set the row height here
              rowCount={applicationList?.data?.totalItems || 0}
              getRowId={(row) => row._id}
              pageSizeOptions={[10, 25, 50, 100]}
              loading={isLoading}
              pagination
              paginationModel={paginationModel}
              paginationMode="server"
              onPageSizeChange={handlePageSizeChange}
              onPaginationModelChange={handlePaginationModelChange}
              slots={{
                noRowsOverlay: CustomNoRowsOverlay,
                toolbar: GridToolbar,
              }}
              sx={{
                width: "100%",
                marginBottom: "16px",
                "& .MuiDataGrid-cell": {
                  py: 1, // Adjust padding of cells
                },
              }}
              rows={applicationList?.data?.AllApplication || []}
            />
          ) : (
            <>No Record Found</>
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default ApplicationIndex;
