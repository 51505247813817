import Mask from "../../Assests/slider-1.jpg"
import Mask1 from "../../Assests/slider-2.jpg"
import Mask2 from "../../Assests/slider-3.jpg"
import Mask3 from "../../Assests/slider-4.jpg"
import Mask4 from "../../Assests/slide4@2x.png"
import Mask5 from "../../Assests/slide5@2x.png"
import Mask6 from "../../Assests/slide7@2x.png"

export const imagesData = [
    {
      name: "Click here to fill up your application",
      image: Mask3,
    },
    {
      name: "Click here to fill up your application",
      image: Mask,
    },
    {
      name: "Second Hero",
      image: Mask1
    },
    {
      name: "Third Hero",
      image: Mask2
    },
    {
      name: "Click here to fill up your application",
      image: Mask,
    },
    {
      name: "Second Hero",
      image: Mask1
    },
    {
      name: "Third Hero",
      image: Mask2
    },
    {
      name: "Click here to fill up your application",
      image: Mask,
    },
    {
      name: "Second Hero",
      image: Mask1
    },
    {
      name: "Third Hero",
      image: Mask2
    },
    {
      name: "Click here to fill up your application",
      image: Mask,
    },
    {
      name: "Second Hero",
      image: Mask1
    },
    {
      name: "Third Hero",
      image: Mask2
    },
    {
      name: "Click here to fill up your application",
      image: Mask,
    },
    {
      name: "Second Hero",
      image: Mask1
    },
    {
      name: "Third Hero",
      image: Mask2
    },
    {
      name: "Click here to fill up your application",
      image: Mask,
    },
    {
      name: "Second Hero",
      image: Mask1
    },
    {
      name: "Third Hero",
      image: Mask2
    },
  ]