import React, { useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  FormControl,
  MenuItem,
  TextField,
  Button,
  Select,
  InputLabel,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import SendIcon from "@mui/icons-material/Send";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  api,
  useCreateApplicantMutation,
  useCreateRecognitionMutation,
  useGetSectorQuery,
  useGetSubSectorQuery,
} from "../../services/api";
import { ToastContainer, toast } from "react-toastify";

const EmploymentInformationDuplicate = ({ steps, active, setActive }) => {
  const _id = localStorage.getItem("recognition_id");
  const [loading, setLoading] = React.useState(false);
  const { data: sectorList, isLoading, isError } = useGetSectorQuery();
  const { data: sub_sectorList } = useGetSubSectorQuery();
  const [recognitionCreate] = useCreateRecognitionMutation();
  const [getSingleRecognition] = api.endpoints.singleRecognition.useLazyQuery();
  const wordCount = (str) => {
    return str.trim().split(/\s+/).length;
  };
  const validationSchema = Yup.object({
    number_of_employees: Yup.number().required('number of employees is required'),
    number_of_himachali_employees: Yup.number().required('number of himachali employees is required'),
    number_of_female_employees: Yup.number().required('number of female employees is required'),
  });

  const formik = useFormik({
    initialValues: {
      number_of_employees: "",
      number_of_himachali_employees: "",
      number_of_female_employees: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        // ... (your existing code)
        let data = {
          step: "employment",
          payload: {
            number_of_employees: values.number_of_employees,
            number_of_himachali_employees: values.number_of_himachali_employees,
            number_of_female_employees: values.number_of_female_employees,
            application_id: _id,
          },
        };

        const response = await recognitionCreate(data);
        if (response?.data?.success) {
          toast.success("Employment Information Created Sucessfully", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setTimeout(() => {
            setActive((prevActive) => prevActive + 1);
          }, 3000);
        } else if (response?.error) {
          toast.error(response?.error?.data?.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } catch (error) {
        console.error("Error submitting data:", error);
      } finally {
        setLoading(false);
      }
    },
  });
  const handleNext = () => {
    formik.handleSubmit();
    // Remove setLoading(false) from here
  };

  const handleBack = () => {
    setActive((prevActive) => prevActive - 1);
  };

  React.useEffect(() => {
    const UpdateRecognition = async () => {
      if (_id) {
        try {
          const { data } =  await getSingleRecognition(_id);
          const {
            number_of_employees,
            number_of_himachali_employees,
            number_of_female_employees,
          } = data?.data;
          formik.setValues({
            number_of_employees,
            number_of_himachali_employees,
            number_of_female_employees,
          });
        } catch (error) {
          console.error("Error fetching permission data:", error);
        }
      }
    };
    UpdateRecognition();
  }, [_id]);

  return (
    <>
      <ToastContainer />
      <Typography
        variant="h6"
        align="left"
        sx={{ fontWeight: 600, color: "#2074af" }}
      >
        Employment Information
      </Typography>
      <Box
        component="form"
        onSubmit={formik.handleSubmit}
        noValidate
        sx={{ mt: 1 }}
      >
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          mt={2}
        >
          {/* Company Name */}
          <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: "18px" }}
            >
              Number of Employees :
            </Typography>
            <FormControl fullWidth>
              <TextField
                margin="dense"
                type="number"
                inputProps={{ min: 0 }}
                required
                fullWidth
                id="number_of_employees"
                label="Number of Employees  "
                placeholder="Number of Employees  "
                name="number_of_employees"
                autoComplete="off"
                value={formik.values.number_of_employees}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.number_of_employees &&
                  Boolean(formik.errors.number_of_employees)
                }
                helperText={
                  formik.touched.number_of_employees &&
                  formik.errors.number_of_employees
                }
              />
            </FormControl>
          </Grid>
          <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: "18px" }}
            >
              Number of Himachali employees :
            </Typography>
            <FormControl fullWidth>
              <TextField
                margin="dense"
                type="number"
                inputProps={{ min: 0 }}
                required
                fullWidth
                id="number_of_himachali_employees"
                label="Number of Himachali Employees"
                placeholder="Number of Himachali Employees"
                name="number_of_himachali_employees"
                autoComplete="off"
                value={formik.values.number_of_himachali_employees}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.number_of_himachali_employees &&
                  Boolean(formik.errors.number_of_himachali_employees)
                }
                helperText={
                  formik.touched.number_of_himachali_employees &&
                  formik.errors.number_of_himachali_employees
                }
              />
            </FormControl>
          </Grid>
          <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: "18px" }}
            >
              Number of Female Employees :
            </Typography>
            <FormControl fullWidth>
              <TextField
                margin="dense"
                type="number"
                inputProps={{ min: 0 }}
                required
                fullWidth
                id="number_of_female_employees"
                label="Number of Female Employees"
                placeholder="Number of Female Employees"
                name="number_of_female_employees"
                autoComplete="off"
                value={formik.values.number_of_female_employees}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.number_of_female_employees &&
                  Boolean(formik.errors.number_of_female_employees)
                }
                helperText={
                  formik.touched.number_of_female_employees &&
                  formik.errors.number_of_female_employees
                }
              />
            </FormControl>
          </Grid>

          {/* <Grid item xl={12} lg={12} md={12} sm={12} xs={12} gap={2} className="button-right">
          <Button
            type="button"
            variant="contained"
            onClick={handleBack}
            disabled={active === 0}
            sx={{ mt: 3, mb: 2, borderRadius: 10 }}
          >
            Previous
          </Button>
          <LoadingButton
            variant="contained"
            onClick={handleNext}
            disabled={active === steps.length - 1}
            sx={{ mt: 3, mb: 2, borderRadius: 10 }}
            endIcon={<SendIcon />}
            loading={loading}
            loadingPosition="center"
          >
            <span>Next</span>
          </LoadingButton>

          </Grid> */}
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className="button-right"
          >
            <Button
              type="button"
              variant="contained"
              onClick={handleBack}
              disabled={active === 0}
            >
              Previous
            </Button>
            <Box ml={2} display="inline-block">
              <LoadingButton
                variant="contained"
                onClick={handleNext}
                disabled={active === steps.length - 1}
                endIcon={<SendIcon />}
                loading={loading}
                loadingPosition="end"
                type="submit"
              >
                <span>Next</span>
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default EmploymentInformationDuplicate;
