import * as React from "react";
import {
  Box,
  Typography,
  Grid,
  FormControl,
  MenuItem,
  Button,
  Paper,
  Select,
  Checkbox,
  InputLabel,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  useRolesDetailsQuery,
  useRoutesDetailsQuery,
  useCreatePermissionMutation,
  useUpdatePermissionMutation,
  api,
} from "../../services/api";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import CustomizedProgressBars from "../CustomProgress/ProgressBar";
// ... (previous imports)

// ... (previous imports and constants)

const schema = yup.object().shape({
  role: yup.string().required("role is required"),
  menu: yup.string(),
  permissions: yup.array().of(
    yup.object().shape({
      _id: yup.string(),
      read: yup.boolean(),
      write: yup.boolean(),
      update: yup.boolean(),
      delete: yup.boolean(),
      show_menu: yup.boolean(),
    })
  ),
});
export default function UpsertPermissions() {
  const { data: rolesList } = useRolesDetailsQuery();
  const { data: menuList } = useRoutesDetailsQuery();
  const [createPermission] = useCreatePermissionMutation();
  const { id } = useParams();
  const [getSingleRec, { data, isLoading, isError }] =
    api.endpoints.singlePermission.useLazyQuery();
  const [UpdatePermissionData] = useUpdatePermissionMutation();
  const navigate = useNavigate();
  const [roleId, setRoleId] = React.useState("");

  const formik = useFormik({
    initialValues: {
      role: "",
      menu: "",
      permissions:
        menuList?.data.map(({ _id }) => ({
          _id,
          read: false,
          write: false,
          update: false,
          delete: false,
          show_menu: false,
        })) ?? [],
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      try {
        const permissionsData = values.permissions.map(
          ({ _id, read, write, update, delete: del, show_menu }) => ({
            menuId: _id,
            read: read.toString(),
            write: write.toString(),
            update: update.toString(),
            delete: del.toString(),
            show_menu: show_menu.toString(),
          })
        );

        const dataToSend = {
          permissions: permissionsData,
          roleId: values.role,
        };

        if (id) {
          const { data } = await UpdatePermissionData({
            id: id,
            payload: dataToSend,
          });
          if (data?.success === true && data) {
            const time = 2000;
            toast.success("Permission Updated successfully", {
              position: "top-center",
              autoClose: true,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setTimeout(() => {
              navigate("/permission");
            }, time + 1000);
          }
          // formik.setFieldValue("fullName", "");
          // toggleDrawer("right", false)(values);
        } else {
          const { data } = await createPermission(dataToSend);
          if (data?.success) {
            const time = 2000;
            toast.success("Permission Created successfully", {
              position: "top-center",
              autoClose: time,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setTimeout(() => {
              navigate("/permission");
            }, time + 1000);
          } else {
          }
        }
        // Now you can send `dataToSend` to the backend

        // ... (your API call logic)
      } catch (error) {
        console.error("Error submitting data:", error);
      }
    },
  });
  const setRole = async () => {
    if (roleId) {
      await formik.setFieldValue("role", roleId);
    }
  };
  React.useMemo(() => {
    setRole();
  }, [roleId]);

  React.useEffect(() => {
    const fetchDataAndUpdatePermissions = async () => {
      if (id) {
        try {
          const {
            data: { data },
          } = await getSingleRec(id);
          if (data?.permissions) {
            setRoleId(data.roleId?._id);
            const updatedPermissions = menuList?.data.map(({ _id }) => {
              const existingPermission = data?.permissions?.find(
                (val) => val.menuId?._id === _id
              );

              return {
                _id,
                read: existingPermission?.read || false,
                write: existingPermission?.write || false,
                update: existingPermission?.update || false,
                delete: existingPermission?.delete || false,
                show_menu: existingPermission?.show_menu || false,
              };
            });

            formik.setValues((prevValues) => ({
              ...prevValues,
              // role:data.roleId?._id,
              permissions: updatedPermissions,
            }));
          } else {
            // Initialize the permissions array if no existing data found
            formik.setValues((prevValues) => ({
              ...prevValues,
              permissions:
                menuList?.data.map(({ _id }) => ({
                  _id,
                  read: false,
                  write: false,
                  update: false,
                  delete: false,
                  show_menu: false,
                })) ?? [],
            }));
          }
        } catch (error) {
          console.error("Error fetching permission data:", error);
        }
      }
    };

    fetchDataAndUpdatePermissions();
  }, [id, menuList?.data]);

  const handlePermission = async (_id, permissionType) => {
    await formik.setValues((prevValues) => {
      const updatedPermissions = prevValues.permissions.map((permission) => {
        if (permission._id === _id) {
          return {
            ...prevValues,
            ...permission,
            [permissionType]: !permission[permissionType],
          };
        }
        return permission;
      });

      return {
        ...prevValues,
        permissions: updatedPermissions,
      };
    });
  };

  return (
    <React.Fragment>
      <Box role="presentation">
        <ToastContainer />
        <Box marginTop={"6em"} padding={4}>
          <Paper className="paper-card">
            <Grid container>
              <Grid item lg={6} md={6} sm={9} xs={9}>
                <Typography variant="h5">Permissions</Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={3} xs={3}>
                <FormControl fullWidth>
                  <InputLabel>Roles</InputLabel>
                  <Select
                    id="material-select"
                    disabled={id ? true : false}
                    fullWidth
                    value={formik.values.role}
                    onChange={formik.handleChange}
                    name="role"
                    onBlur={formik.handleBlur}
                  >
                    {rolesList?.data.map((val) => (
                      <MenuItem key={val._id} value={val._id}>
                        {val.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {formik.touched.role && formik.errors.role && (
                    <Typography
                      sx={{ color: "red" }}
                      component="span"
                      fontWeight={350}
                      fontSize={"small"}
                    >
                      {formik.errors.role}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Box component={Paper} marginBottom={2}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ width: "20%" }}>Menu</TableCell>
                          <TableCell style={{ width: "20%" }}>Path</TableCell>
                          <TableCell align="center">Read</TableCell>
                          <TableCell align="center">Write</TableCell>
                          <TableCell align="center">Update</TableCell>
                          <TableCell align="center">Delete</TableCell>
                          <TableCell align="center">Show Menu</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {menuList?.data.map(({ name, _id, path }) => (
                          <TableRow key={_id}>
                            <TableCell
                              style={{ width: "20%" }}
                              component="th"
                              scope="row"
                            >
                              {name}
                            </TableCell>
                            <TableCell
                              style={{ width: "20%" }}
                              component="th"
                              scope="row"
                            >
                              {path}
                            </TableCell>
                            <TableCell align="center">
                              <Checkbox
                                checked={
                                  formik.values.permissions?.find(
                                    (p) => p._id === _id
                                  )?.read || false
                                }
                                onChange={() => handlePermission(_id, "read")}
                                name="read"
                              />
                            </TableCell>
                            <TableCell align="center">
                              <Checkbox
                                checked={
                                  formik.values.permissions?.find(
                                    (p) => p._id === _id
                                  )?.write || false
                                }
                                onChange={() => handlePermission(_id, "write")}
                                name="write"
                              />
                            </TableCell>
                            <TableCell align="center">
                              <Checkbox
                                checked={
                                  formik.values.permissions?.find(
                                    (p) => p._id === _id
                                  )?.update || false
                                }
                                onChange={() => handlePermission(_id, "update")}
                                name="update"
                              />
                            </TableCell>
                            <TableCell align="center">
                              <Checkbox
                                checked={
                                  formik.values.permissions?.find(
                                    (p) => p._id === _id
                                  )?.delete || false
                                }
                                onChange={() => handlePermission(_id, "delete")}
                                name="delete"
                              />
                            </TableCell>
                            <TableCell align="center">
                              <Checkbox
                                checked={
                                  formik.values.permissions?.find(
                                    (p) => p._id === _id
                                  )?.show_menu || false
                                }
                                onChange={() =>
                                  handlePermission(_id, "show_menu")
                                }
                                name="show_menu"
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Grid>
            </Grid>
            <Grid className="button-right" item lg={3} md={3} sm={3} xs={3}>
              <Button
                onClick={(e) => {
                  //   e.stopPropagation();
                  formik.handleSubmit();
                }}
                variant="contained"
              >
                {id ? "Update" : "Save"}
              </Button>
            </Grid>
          </Paper>
        </Box>
      </Box>
    </React.Fragment>
  );
}
