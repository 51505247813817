const FooterRoute = [
  {
    id: 1,
    key: "/incubation-centre",
    value: "Startup",
  },
  {
    id: 2,
    key: "/",
    value: "Tenders Himachal Pradesh",
  },
  {
    id: 3,
    key: "/",
    value: "Make In India",
  },
  {
    id: 4,
    key: "/",
    value: "Skill Development and Enterpreneuship",
  },
  {
    id: 5,
    key: "/",
    value: "Goverment E-Marketplace (GEM)",
  },
  {
    id: 6,
    key: "/",
    value: "Incredible India",
  },
  {
    id: 7,
    key: "/",
    value: "India Investment Grid",
  },
  {
    id: 8,
    key: "/",
    value: "AMRUT",
  },
];
export default FooterRoute;
