import React, { createContext, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const LanguageContext = createContext();

const LanguageProvider = ({ children }) => {
  const [language, setLanguageState] = useState('en');
  const { i18n } = useTranslation();
  useEffect(()=>{
    setLanguageState(i18n.language);
  },[])
 
  const changeLanguage = (newLanguage) => {
    i18n.changeLanguage(newLanguage);
    setLanguageState(newLanguage);
  };

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};

export { LanguageProvider, useLanguage };
