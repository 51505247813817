import * as React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { api } from "../../services/api";

export default function TrackGrievance({ id }) {
  const [trackGrievance] =
    api.endpoints.trackGrievance.useLazyQuery();
  const [status, setStatus] = React.useState();
  React.useEffect(() => {
    const UpdateApplication = async () => {
      if (id) {
        try {
          const { data } = await trackGrievance(id);
          if (data?.success) {
            setStatus(data?.application?.status);
          }
        } catch (error) {
          console.error("Error fetching applicant data:", error);
        }
      }
    };
    UpdateApplication();
  }, [id]);

  return (
    <Timeline
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
      }}
    >
      <TimelineItem>
        <TimelineSeparator>
          <TimelineConnector
            sx={{
              backgroundColor:
                status === "closed"
                  ? "green"
                  : status === "pending"
                  ? "red"
                   : status === undefined?"grey":"grey",
            }}
          />
          <TimelineDot
            color={
                status === "closed"
                ? "success"
                : status === "pending"
                ? "error"
                :"grey"
            }
          />
        </TimelineSeparator>
        <TimelineContent>HOD/Directorate Status</TimelineContent>
      </TimelineItem>
    </Timeline>
  );
}
