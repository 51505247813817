import Mask1 from "../../Assests/rcfc.png";
import Mask2 from "../../Assests/nift.png";
import Mask3 from "../../Assests/juit.png";
import Mask4 from "../../Assests/himcost.png";
import Mask5 from "../../Assests/chitkara.png";
import Mask6 from "../../Assests/cpuh.png";
import Mask7 from "../../Assests/csir.png";
import Mask8 from "../../Assests/iitmandi.png";
export const data = [
    {
      id: 1,
      name: "Click here to fill up your application",
      img: Mask1,
      title: "imag1",
    },
    {
      id: 2,
      name: "Second Hero",
      img: Mask2,
      title: "imag2",
    },
    {
      id: 3,
      name: "Third Hero",
      img: Mask3,
      title: "imag3",
    },
    {
      id: 4,
      name: "Second Hero",
      img: Mask4,
      title: "imag2",
    },
    {
      id: 5,
      name: "Third Hero",
      img: Mask5,
      title: "imag3",
    },
    {
      id: 6,
      name: "Second Hero",
      img: Mask6,
      title: "imag2",
    },
    {
      id: 7,
      name: "Click here to fill up your application",
      img: Mask7,
      title: "imag1",
    },
    {
      id: 8 ,
      name: "Click here to fill up your application",
      img: Mask8,
      title: "imag8",
    },
    
  ].reverse();

