import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  Paper,
  TableBody,
  TableRow,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import SendIcon from "@mui/icons-material/Send";
import LoadingButton from "@mui/lab/LoadingButton";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { api, useCreateApplicantMutation, useCreateRecognitionMutation } from "../../services/api";
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";

const DocumentUploadedDuplicate = ({ steps, active, setActive }) => {
  const _id = localStorage.getItem("recognition_id");
  const [loading, setLoading] = useState(false);
  const [appId, setAppId] = useState(false);
  const [recognitionCreate] = useCreateRecognitionMutation();
  const [getSingleRecognition] = api.endpoints.singleRecognition.useLazyQuery();
  const navigate = useNavigate()
  // 66b09755571acf4a764ad5b9
  // 66b09755571acf4a764ad5b9
  // const validationSchema = Yup.object({
  //   rows: Yup.array().of(
  //     Yup.object().shape({
  //       document: Yup.string().required(),
  //       name: Yup.string().nullable(),
  //       file: Yup.mixed().nullable(),
  //       preview: Yup.string().nullable(),
  //     })
  //   ),
  // });
  const validationSchema = Yup.object().shape({
    rows: Yup?.array().of(
      Yup?.object().shape({
        document: Yup.string().required(),
        name: Yup.string().when("key", {
          is: (val) =>
            ( val === "doc_pan") || ( val === "doc_aadhaar")||( val === "gst_registration")||( val === "proof_office_address")||( val === "articles_incorporation"),
          then: () => Yup.string().required("Name is required"),
          otherwise: () => Yup.string().nullable(),
        }),
        file: Yup.mixed().when("key", {
          is: (val) =>
            ( val === "doc_pan") || ( val === "doc_aadhaar")||( val === "gst_registration")||( val === "proof_office_address")||( val === "articles_incorporation"),
          then: () => Yup.mixed().required("File is required"),
          otherwise: () => Yup.mixed().nullable(),
        }),
        preview: Yup.string().nullable(),
      })
    ),
  });

  const formik = useFormik({
    initialValues: {
    //   { name: "pic", maxCount: 1 },
    // { name: "doc_pan", maxCount: 1 },
    // { name: "doc_aadhaar", maxCount: 1 },
    // { name: "gst_registration", maxCount: 1 },
    // { name: "proof_office_address", maxCount: 1 },
    // { name: "consent_to_operate", maxCount: 1 },
    // { name: "power_release_certificate", maxCount: 1 },
    // { name: "electricity_board_permission", maxCount: 1 },
    // { name: "clear_title_land", maxCount: 1 },
    // { name: "project_specific", maxCount: 1 },
    // { name: "dpiit_recognised_certificate", maxCount: 1 },
    // { name: "first_sale_bill", maxCount: 1 },
    // { name: "any_other_document", maxCount: 1 }
      rows: [
        { document: "PIC", key: "pic", name: "", file: null, preview: null },
        {
          document: "PAN",
          key: "doc_pan",
          name: "",
          file: null,
          preview: null,
        },
        {
          document: "Aadhaar",
          key: "doc_aadhaar",
          name: "",
          file: null,
          preview: null,
        },
        {
          document: "GST Registration",
          key: "gst_registration",
          name: "",
          file: null,
          preview: null,
        },
        {
          document: "Proof of Office Address",
          key: "proof_office_address",
          name: "",
          file: null,
          preview: null,
        },
        {
          document: "Articles of Incorporation",
          key: "articles_incorporation",
          name: "",
          file: null,
          preview: null,
        },
        
        {
          document: "DPIIT Recognised Certificate",
          key: "dpiit_recognised_certificate",
          name: "",
          file: null,
          preview: null,
        },

        {
          document: "Clear title of land (date of registration) or date of rental permission/ lease deed",
          key: "clear_title_land",
          name: "",
          file: null,
          preview: null,
        },
        {
          document: "Consent to operate of HP State Pollution Control Board, if applicable",
          key: "consent_to_operate",
          name: "",
          file: null,
          preview: null,
        },
        {
          document: "Power release certificate by the HP State Electricity Board/ permission to run the unit on DG set",
          key: "power_release_certificate",
          name: "",
          file: null,
          preview: null,
        },
        {
          document: "Project specific mandatory requirements as applicable such as drug license, factory license, environment clearance etc",
          key: "project_specific",
          name: "",
          file: null,
          preview: null,
        },

        {
          document: "First Sale Bill",
          key: "first_sale_bill",
          name: "",
          file: null,
          preview: null,
        },

        {
          document: "Any other Document",
          key: "any_other_document",
          name: "",
          file: null,
          preview: null,
        },
      ],
      
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const formData = new FormData();
        values.rows.forEach((row, index) => {
          if (row.file) {
            formData.append(row.key, row.file);
          }
        });
        formData.append("application_id", _id);
        formData.append("applicant_id", appId );
        let data = {
          step: "document",
          payload: formData,
        };
        const response = await recognitionCreate(data);
        if (response?.data?.success) {
          toast.success("Document Uploaded Successfully", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          localStorage.removeItem('recognition_id')
          setTimeout(() => {
            setActive((prevActive) => prevActive + 1);
            navigate("/recognition")
          }, 3000);
        } else if (response?.error) {
          toast.error(response?.error?.data?.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } catch (error) {
        console.error("Error submitting data:", error);
      } finally {
        setLoading(false);
      }
    },
  });

  const handleNext = () => {
    formik.handleSubmit();
  };

  const handleBack = () => {
    setActive((prevActive) => prevActive - 1);
  };

  // useEffect(() => {
  //   const UpdateRecognition = async () => {
  //     if (_id) {
  //       try {
  //         const data = {
  //           companyName: "Company Name",
  //           projectFormation: "Project Formation",
  //           address: "Address",
  //           incubation_centre: "Incubation Centre",
  //         };
  //         formik.setValues((prevValues) => ({
  //           ...prevValues,
  //           ...data,
  //         }));
  //       } catch (error) {
  //         console.error("Error fetching permission data:", error);
  //       }
  //     }
  //   };
  //   UpdateRecognition();
  // }, [_id]);

  const fileInputRef = useRef([]);

  const handleIconClick = (index) => {
    fileInputRef.current[index].click();
  };

  const handleFileUpload = (index, event) => {
    const file = event.target.files[0];
    if (file) {
      const newRows = [...formik.values.rows];
      newRows[index].file = file;
      newRows[index].name = file.name;
      newRows[index].preview = URL.createObjectURL(file);
      formik.setFieldValue("rows", newRows);
    }
  };
  useEffect(() => {
    const UpdateRecognition = async () => {
      if (_id) {
        try {
          const { data } = await getSingleRecognition(_id);
          if (data?.data) {
            setAppId(data?.data?.applicant_id)
            const updatedRows = formik.values.rows.map((row) => ({
              ...row,
              name: data.application[row.key]?.name || "",
              preview: data.application[row.key] || null,
            }));
            formik.setValues({ rows: updatedRows });
          }
        } catch (error) {
          console.error("Error fetching applicant data:", error);
        }
      }
    };
    UpdateRecognition();
  }, [_id, getSingleRecognition]);

  const hasPreview = formik.values.rows.some((row) => row.preview);
  const hasName = formik.values.rows.some((row) => row.name);

  return (
    <>
      <ToastContainer />
      <Typography
        variant="h6"
        align="start"
        sx={{ fontWeight: 600, color: "#2074af" }}
      >
        Document Uploaded
      </Typography>
      <Box
        component="form"
        onSubmit={formik.handleSubmit}
        noValidate
        sx={{ mt: 1 }}
      >
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          mt={2}
        >
          <Grid item md={12} lg={12} xl={12} xs={12}>
            <Typography
              variant="h6"
              align="start"
              sx={{ fontWeight: 500, fontSize: "18px" }}
            >
              Documents:
            </Typography>
            <TableContainer component={Paper} fullWidth>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Document</TableCell>
                    <TableCell>File</TableCell>
                    {hasName ? <TableCell>Name</TableCell> : null}
                    {hasPreview ? <TableCell>Preview</TableCell> : null}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {formik.values.rows?.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{row.document}</TableCell>
                      <TableCell>
                        <CloudUploadIcon
                          style={{ cursor: "pointer",color: "#00bfff" }}
                          onClick={() => handleIconClick(index)}
                        />
                         {formik.errors?.rows?.[index]?.file &&
                          formik.touched?.rows?.[index]?.file && (
                            <Typography color="error" variant="caption" mt={1}>
                              {formik.errors.rows[index].file}
                            </Typography>
                          )}
                        <input
                          type="file"
                          accept=".pdf"
                          style={{ display: "none" }}
                          ref={(el) => (fileInputRef.current[index] = el)}
                          onChange={(event) => handleFileUpload(index, event)}
                        />
                      </TableCell>
                      <TableCell>{row.name}</TableCell>

                      <TableCell>
                        {row.preview && (
                          <Link
                            to={row.preview}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <VisibilityIcon />
                          </Link>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className="button-right"
          >
            <Button
              type="button"
              variant="contained"
              onClick={handleBack}
              disabled={active === 0}
            >
              Previous
            </Button>
            <Box ml={2} display="inline-block">
              <LoadingButton
                variant="contained"
                onClick={handleNext}
                disabled={active === steps.length}
                endIcon={<SendIcon />}
                loading={loading}
                loadingPosition="end"
              >
                <span>Final Submit</span>
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default DocumentUploadedDuplicate;
