import React, { useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer } from "react-toastify";
import { api, useGetPlatformQuery } from "../../services/api";
import CloseIcon from "@mui/icons-material/Close";

const validationSchema = Yup.object().shape({
  startup_policy: Yup.string().required("Startup Policy is required"),
});

const ChangeStatusDialog = ({ open, handleClose }) => {
  const { data: platformList } = useGetPlatformQuery();

  const formik = useFormik({
    initialValues: {
      startup_policy: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // Handle form submission
    },
  });
 const handleValue=()=>{
  localStorage.setItem("policy", formik.values.startup_policy);
  handleClose()
 }

  return (
    <>
      <ToastContainer />
      <Dialog
      fullWidth
      maxWidth="sm"
        open={open}
        // onClose={handleClose}
        // sx={{ width: "80%", maxHeight: "80%", alignItems:'center', justifyContent:'center' }}
      >
        <DialogTitle>
          Select Schema/Policy
          {/* <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "relative",
              left: 24,
              bottom: 18,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton> */}
        </DialogTitle>
        <DialogContent>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel className="policy-label" id="startup_policy">
                Startup Scheme/ Policy
              </InputLabel>
              <Select
                margin="dense"
                required
                fullWidth
                id="startup_policy"
                label="Startup Scheme/ Policy"
                name="startup_policy"
                autoFocus
                value={formik.values.startup_policy}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.startup_policy &&
                  Boolean(formik.errors.startup_policy)
                }
                autoComplete="off"
              >
                {platformList?.data?.map((item) => (
                  <MenuItem value={item._id} key={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              formik.setFieldValue("startup_policy", "");
              handleClose();
            }}
            sx={{
              borderRadius: "5px",
              backgroundColor: "#CCCCCC",
              marginRight: "8px",
              color: "#000",
            }}
          >
            Cancel
          </Button>
          <Button variant="contained" size="medium"
            onClick={handleValue}
            disabled={!formik.isValid}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ChangeStatusDialog;
