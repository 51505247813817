import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import CustomizedSteppers from "./stipper";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Check from "@mui/icons-material/Check";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import PersonIcon from "@mui/icons-material/Person";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import EmojiObjectsIcon from "@mui/icons-material/EmojiObjects";
import DescriptionSharpIcon from "@mui/icons-material/DescriptionSharp";
import BusinessIcon from "@mui/icons-material/Business";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import InfoIcon from "@mui/icons-material/Info";
import NewApplicant from "../NewApplicant";
import StartupDetails from "../StartupDetails";
import FounderDetails from "../FounderDetails";
import BusinessDetails from "../BusinessDetails";
import FinancialInformation from "../FinancialInformation";
import EmploymentInformation from "../EmployementInformation";
import BusinessPlan from "../BusinessPlan";
import DocumentUploaded from "../DocumentUploaded";
import CustomizedProgressBars from "../CustomProgress/ProgressBar";
const steps = [
  "New Applicant",
  "Startup Details",
  "Founder Details",
  "Business Details",
  "Financial Information",
  "Employment Information",
  "Business Plan",
  "Document Uploaded",
];

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#c9c9c9",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#784af4",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#c9c9c9",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient(136deg, #AD235E 0%, #E91E63 50%, #FF5722 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient(136deg, #AD235E 0%, #E91E63 50%, #FF5722 100%)",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <PersonIcon />,
    2: <RocketLaunchIcon />,
    3: <EmojiObjectsIcon />,
    4: <BusinessIcon />,
    5: <DescriptionSharpIcon />,
    6: <InfoIcon />,
    7: <BusinessIcon />,
    8: <UploadFileIcon />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const Applicantv2 = () => {
  const [active, setActive] = React.useState(0);
  const renderStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <NewApplicant
            {...{ steps: steps, active: active, setActive: setActive }}
          />
        );
      case 1:
        return (
          <StartupDetails
            {...{ steps: steps, active: active, setActive: setActive }}
          />
        );
      case 2:
        return (
          <FounderDetails
            {...{ steps: steps, active: active, setActive: setActive }}
          />
        );
      case 3:
        return (
          <BusinessDetails
            {...{ steps: steps, active: active, setActive: setActive }}
          />
        );
      case 4:
        return (
          <FinancialInformation
            {...{ steps: steps, active: active, setActive: setActive }}
          />
        );
      case 5:
        return (
          <EmploymentInformation
            {...{ steps: steps, active: active, setActive: setActive }}
          />
        );
      case 6:
        return (
          <BusinessPlan
            {...{ steps: steps, active: active, setActive: setActive }}
          />
        );
      case 7:
        return (
          <DocumentUploaded
            {...{ steps: steps, active: active, setActive: setActive }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box marginTop={"1em"} padding={4}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "20%",
          flexDirection: "column",
          gap: "10px",
          marginTop: "5%",
          marginBottom: "2rem",
        }}
      >
        <Grid item md={6} lg={6} sm={6}>
          <Typography variant="h5" align="center" fontWeight="600" sx={{ 
          color: (theme) => theme.palette.text.primary,}}>
            Apply For Start Up
          </Typography>
        </Grid>
        <Grid item md={6} lg={6} sm={6}>
          <CustomizedProgressBars val={50} />
        </Grid>
      </Grid>
      <Grid>
        <Stack
          sx={{
            width: "100%",
            backgroundColor: (theme) => theme.palette.default,
            color: (theme) => theme.palette.text.primary,
            borderRadius: '4px',
          }}
        >
          <Stepper
            alternativeLabel
            activeStep={active}
            connector={<ColorlibConnector />}
          >
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel
                  className="Step-List"
                  StepIconComponent={ColorlibStepIcon}
                  onClick={(e) => {
                    e.stopPropagation();
                    setActive(index);
                  }}
                >
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Stack>

        <CustomizedSteppers
          mt={2}
          {...{ element: renderStepContent(active) }}
        />
      </Grid>
    </Box>
  );
};

export default Applicantv2;
