import Register from "../../Screens/Register";

export const translations = {
  en: {
    "Helpdesk Number": "Helpdesk Number",
    "Skip to main content": "Skip to main content",
    home: "Home",
    "About Us": "About Us",
    "Startup Hub": "Startup Hub",
    Ecosystems: "Ecosystems",
    Notifications: "Notifications",
    Account: "Account",
    "Connnect With Mentor": "Connect With Mentor",
    "Sign Up": "Sign Up",
    Affiliated: "Get Affiliated with Us",
    success_stories: "Success Stories",
    Screen_reader_access: "Screen Reader Access",
    grievances: "Grievances",
    upcoming_events: "Upcoming Events",
    announcements: "Announcements",
    hp_map: "Himachal Map",
    start_up_title: "Welcome to Startup Himachal Pardesh",
    Login: "Login",
    Register: "Register",
    Incubators: "Incubators",
    "Quick Links": "Quick Links",
    "Contact Us": "Contact Us",
    Helpline: "Helpline",
    Address: "Address",
    Startup: "Startup",
    "Tenders Himachal Pradesh": "Tenders Himachal Pradesh",
    "Make In India": "Make In India",
    "Skill Development and Enterpreneuship":
    "Skill Development and Enterpreneuship",
    "Goverment E-Marketplace (GEM)": "Goverment E-Marketplace (GEM)",
    "Incredible India": "Incredible India",
    "India Investment Grid": "India Investment Grid",
    "AMRUT": "AMRUT",
    upcoming_text:`The 8th Ayurveda Day embraces the theme "Ayurveda for One Health," resonating with India's G20 Presidency theme, 'Vasudhaiva Kutumbakam' (the world is one family). The central theme is encapsulated in the tagline "Ayurveda for everyone, every day," emphasizing the interconnection of human health with the environment, animals, and plants. It adopts a four-pronged "Jan Sandesh, Jan Bhagidhari, and Jan Andolan for Jan Arogya" approach, underscoring the Ministry of Ayush's commitment to holistic health through Ayurveda.`,
    footer_text:`Innovation is the process of introducing new or making changes with updated technology, large and small radical and icremental, to products, processes, and services that result in the introduction of some new and innovative products.`,
    address1:`Plot No. 39, Industrial area || Shimla, Himachal Pradesh`,
    Proceedings :"Proceedings",
   "Incubation Centre":"Incubation Centre",
    "Grievance Form":"Grievance Form",
    "himayalan_startup_trek" :"Himayalan Startup Trek 2024",
    "date":" 16 July, 2024",
    "success_date":" 16 July, 2024",
    "Skill india: education to employement bridge":"Skill india: education to employement bridge",
    "Ensuring resposible and thrustworthy ai for all":"Ensuring resposible and thrustworthy ai for all",
    "PM Vishwakarma scheme: empowering artisans":"PM Vishwakarma scheme: empowering artisans",
    "Opportunities in the indian telecommunication sector":"Opportunities in the indian telecommunication sector",
    //  "PM Vishwakarma scheme: empowering artisans":"पीएम विश्वकर्मा योजना: कारीगरों को सशक्त बनाना",
    "india_telecom":"India's Telecom sector has witnessed stupendous growth in the last few years. Today",
    "India's vibrant handicraft sector weaves a rich tapestry of economic, cutural, and social":"India's vibrant handicraft sector weaves a rich tapestry of economic, cutural, and social",
    "An AI system is a machine-based system that, for explicit or implicit objectives, infers":"An AI system is a machine-based system that, for explicit or implicit objectives, infers",
    "Skills and knowledge serve as pivotal drivers of economic growth and societal":"Skills and knowledge serve as pivotal drivers of economic growth and societal",
    "State Registration Startups" :"State Registration Startups",
    "Sustenance allowance disbursed":"Sustenance allowance disbursed",
    "Startups Funded Under HIMSUP Yojana":"Startups Funded Under HIMSUP Yojana",
    "Empaneled_Accleration_Center": "Empaneled Accleration Center",
    "Women Led Startups":'Women Led Startups',
    "DPIIT Recognized Startups":'DPIIT Recognized Startups',   
    "Active Members":"Active Members",
    "Direct Application":"Direct Application",  
    "Grievance Queries":"Grievance Queries"
  },
  hi: {
    "Helpdesk Number": "हेल्पडेस्क नंबर",
    "Skip to main content": "मुख्य सामग्री पर जाएं",
    home: "होम",
    "About Us": "हमारे बारे में",
    "Startup Hub": "स्टार्टअप हब",
    Ecosystems: "एकोसिस्टम्स",
    Notifications: "सूचनाएं",
    Account: "खाता",
    Login: " प्रवेश करें",
    "Connnect With Mentor": "मेंटर से जुड़ना चाहता/चाहती हूँ।",
    Register: "रजिस्टर करें",
    Affiliated: "हमारे साथ सहयोगी बनें",
    success_stories: "स्टार्टअप इकोसिस्टम का अन्वेषण करें",
    Screen_reader_access: "स्क्रीन रीडर एक्सेस",
    grievances: "शिकायतें",
    upcoming_events: "आगामी कार्यक्रम",
    announcements: "घोषणाएँ",
    hp_map: "हिमाचल प्रदेश का नक्शा",
    start_up_title: "स्टार्टअप हिमाचल प्रदेश में आपका स्वागत है।",
    Incubators: "इंक्यूबेटर",
    "Quick Links": "त्वरित संपर्क",
    "Contact Us": "संपर्क करें ",
    Helpline: "सहायता",
    Address: "पता",
    Startup: "स्टार्टअप",
    "Tenders Himachal Pradesh": "हिमाचल प्रदेश टेंडर्स",
    "Make In India": "मेक इन इंडिया",
    "Skill Development and Enterpreneuship":"कौशल विकास और उद्यमिता",
    "Goverment E-Marketplace (GEM)": "सरकारी ई-बाजार (जीईएम)",
    "Incredible India": "अतुलनीय इंडिया",
    "India Investment Grid": "इंडिया इन्वेस्टमेंट ग्रिड",
    "AMRUT": "एएमआरयूटी",
    upcoming_text:`आयुर्वेद दिवस का आठवां आयोजन "एक स्वास्थ्य के लिए आयुर्वेद" विषय को अपनाता है, जो भारत के जी-20 अध्यक्षता विषय "वसुधैव कुटुंबकम" (दुनिया एक परिवार है) के साथ समर्थन करता है। मुख्य विषय को शब्दों में बयां किया गया है "हर किसी के लिए आयुर्वेद, हर दिन," मानव स्वास्थ्य को पर्यावरण, जानवरों, और पौधों के साथ जोड़ने की महत्वाकांक्षा को जोर देता है। यह "जन संदेश, जन भागीदारी, और जन आंदोलन जन आरोग्य के लिए" के चार-मुखी पहल अपनाता है, जो आयुष मंत्रालय के पूर्णता स्वास्थ्य के लिए समर्पण को आयुर्वेद के माध्यम से बल प्रदान करता है।`,
    footer_text:`आविष्कार वह प्रक्रिया है जिसमें नई या अद्यतित प्रौद्योगिकी के साथ बड़े और छोटे रूपांतरणिक, राधांतरणात्मक और वृद्धिशील परिवर्तन को उत्पादों, प्रक्रियाओं, और सेवाओं में लाने की प्रक्रिया होती है जिससे कुछ नए और अभिनव उत्पादों का परिचय होता है।`,
    address1:`प्लॉट नंबर 39, औद्योगिक क्षेत्र || शिमला, हिमाचल प्रदेश`,
    Proceedings:"प्रोसीडिंग सेंटर",
    "Incubation Centre":"इनक्यूबेशन सेंटर",
    "Grievance Form":"शिकायत फॉर्म",
    "himayalan_startup_trek" :" हिमालयन स्टार्टअप ट्रेक 2024",
    "date":"16 जुलाई, 2024",
    "success_date":"16 जुलाई, 2024",
   "Skill india: education to employement bridge":"स्किल इंडिया: शिक्षा से रोजगार पुल",
   "Ensuring resposible and thrustworthy ai for all":"सभी के लिए जिम्मेदार और भरोसेमंद एआई सुनिश्चित करना",
   "PM Vishwakarma scheme: empowering artisans":"पीएम विश्वकर्मा योजना: कारीगरों को सशक्त बनाना",
   "Opportunities in the indian telecommunication sector":"भारतीय दूरसंचार क्षेत्र में अवसर",
  //  "PM Vishwakarma scheme: empowering artisans":"पीएम विश्वकर्मा योजना: कारीगरों को सशक्त बनाना",
   "india_telecom":"भारत के दूरसंचार क्षेत्र ने पिछले कुछ वर्षों में शानदार वृद्धि देखी है। आज",
   "India's vibrant handicraft sector weaves a rich tapestry of economic, cutural, and social":"भारत का जीवंत हस्तशिल्प क्षेत्र आर्थिक, सांस्कृतिक और सामाजिक समृद्धि की एक समृद्ध गाथा बुनता है",
   "An AI system is a machine-based system that, for explicit or implicit objectives, infers":"एआई प्रणाली एक मशीन-आधारित प्रणाली है जो स्पष्ट या अप्रत्यक्ष उद्देश्यों के लिए निष्कर्ष निकालती है",
   "Skills and knowledge serve as pivotal drivers of economic growth and societal":"कौशल और ज्ञान आर्थिक विकास और सामाजिक प्रगति के महत्वपूर्ण प्रेरक हैं",
  //  Skill india: education to employement bridge
  "State Registration Startups" :"राज्य पंजीकरण स्टार्टअप्स",
  "Sustenance allowance disbursed":"निर्वाह भत्ता वितरित",
  "Startups Funded Under HIMSUP Yojana":"हिमसप योजना के तहत वित्त पोषित स्टार्टअप्स",
  "Empaneled_Accleration_Center":"सूचीबद्ध त्वरण केंद्र",
  "Women Led Startups":'महिला नेतृत्व वाले स्टार्टअप्स',
  "DPIIT Recognized Startups":'डीपीआईआईटी द्वारा मान्यता प्राप्त स्टार्टअप्स',   
  "Active Members":"सक्रिय सदस्य",
  "Direct Application":"प्रत्यक्ष आवेदन",
"Grievance Queries":"शिकायत प्रश्न "
  },
  // bn: {
    //   "Helpdesk Number": "হেল্পডেস্ক নাম্বার",
    //   "Skip to main content": "মূল বিষয়ে যান",
  // },
};
