import * as React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import { api } from "../../services/api";

export default function TrackPR({ id }) {
  //director === hod
  const [trackPR] =
    api.endpoints.trackProgressReportStatus.useLazyQuery();
  const [nodalStatus, setNodalStatus] = React.useState("");
  const [techStatus, setTechStatus] = React.useState("");
  const [mentorStatus, setMentorStatus] = React.useState("");
  const [dicStatus, setDicStatus] = React.useState("");
  const [hodStatus, setHodStatus] = React.useState("");
  React.useEffect(() => {
    const UpdateApplication = async () => {
      if (id) {
        try {
          const { data } = await trackPR(id);
          if (data?.success) {
            setNodalStatus(data?.application?.nodal_status);
            setMentorStatus(data?.application?.mentor_status);
            setDicStatus(data?.application?.dic_status);
            setHodStatus(data?.application?.directorate_status);
          }
        } catch (error) {
          console.error("Error fetching applicant data:", error);
        }
      }
    };
    UpdateApplication();
  }, [id]);

  return (
    <Timeline
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
          
        },
      }}
    >
    
      {/* <TimelineItem>
        <TimelineSeparator>
          <TimelineDot
            color={
              techStatus === "selected"
                ? "success"
                : techStatus === "rejected"
                ? "error"
                : "grey"
            }
          />
          <TimelineConnector
            sx={{
              backgroundColor:
                techStatus === "selected"
                  ? "green"
                  : techStatus === "rejected"
                  ? "red"
                  : "grey",
            }}
          />
        </TimelineSeparator>
        <TimelineContent>Technical Screeen Status</TimelineContent>
      </TimelineItem> */}
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot
            color={
              mentorStatus === "accepted"
                ? "success"
                : mentorStatus === "reverted"
                ? "error"
                : "grey"
            }
          />
          <TimelineConnector
            sx={{
              backgroundColor:
                mentorStatus === "accepted"
                  ? "green"
                  : mentorStatus === "reverted"
                  ? "red"
                  : "grey",
            }}
          />
        </TimelineSeparator>
        <TimelineContent>Mentor Status</TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot
            color={
              nodalStatus === "accepted"
                ? "success"
                : nodalStatus === "reverted"
                ? "error"
                : "grey"
            }
          />
          <TimelineConnector
            sx={{
              backgroundColor:
                nodalStatus === "accepted"
                  ? "green"
                  : nodalStatus === "reverted"
                  ? "red"
                  : "grey",
            }}
          />
        </TimelineSeparator>
        <TimelineContent>Nodal Status</TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot
            color={
              dicStatus === "accepted"
                ? "success"
                : dicStatus === "rejected"
                ? "error"
                : "grey"
            }
          />
          <TimelineConnector
            sx={{
              backgroundColor:
                dicStatus === "accepted"
                  ? "green"
                  : dicStatus === "rejected"
                  ? "red"
                  : "grey",
            }}
          />
        </TimelineSeparator>
        <TimelineContent>DIC/GM Status</TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot
            color={
              hodStatus === "approved"
                ? "success"
                : hodStatus === "rejected"
                ? "error"
                : "grey"
            }
          />
        </TimelineSeparator>
        <TimelineContent>HOD/Directorate Status</TimelineContent>
      </TimelineItem>
    </Timeline>
  );
}
