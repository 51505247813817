import React, { useContext, useEffect, useRef, useState } from "react";
import "./style.scss";
import {
  Grid,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Switch,
  Typography,
  useTheme,
  Card,
  CardContent,
  Tab,
  Tabs,
  List,
  ListItem,
} from "@mui/material";
import Container from "../Container";
import { useTranslation } from "react-i18next";
import usimg from "../../Assests/Icons/us.png";
import indiaimg from "../../Assests/Icons/india.png";
import { useLanguage } from "../Language/CustomContext";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { setActiveLang } from "../../Redux/GlobalSlice";
import { useDispatch } from "react-redux";
import { ThemeContext } from "../../services/ThemeContext";
import { useNavigate } from "react-router-dom";
import MobileScreenShareIcon from '@mui/icons-material/MobileScreenShare';


const lngs = {
  en: { nativeName: "English", img: usimg },
  hi: { nativeName: "हिंदी", img: indiaimg },
};


const Topbarv2 = ({ number, handleSkipToContent, data }) => {
  const { t } = useTranslation();
  const { language, changeLanguage } = useLanguage();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [recording, setRecording] = useState(false);
  const mediaRecorderRef = useRef(null);
  const chunksRef = useRef([]);
  const handleChange = (event) => {
    dispatch(setActiveLang(event.target.value));
    changeLanguage(event.target.value);
  };
  const { toggleColorMode, increaseFontSize, decreaseFontSize, resetFontSize } =
    useContext(ThemeContext);
  const theme = useTheme();
  const objectCss = {
    commonStyle: {
      fontWeight: 200,
      color: "#fff",
      textDecoration: "underline",
    },
  };

  const startRecording = async (e) => {
    navigate("/screen-reader")
    // e.preventDefault();
    // if (!navigator.mediaDevices || !navigator.mediaDevices.getDisplayMedia) {
    //   alert("Screen recording is not supported in your browser.");
    //   return;
    // }
    // try {
    //   const stream = await navigator.mediaDevices?.getDisplayMedia({
    //     video: true,
    //   });
    //   mediaRecorderRef.current = new MediaRecorder(stream);
    //   chunksRef.current = [];

    //   mediaRecorderRef.current.ondataavailable = (event) => {
    //     if (event.data.size > 0) {
    //       chunksRef.current.push(event.data);
    //     }
    //   };

    //   mediaRecorderRef.current.onstop = () => {
    //     setRecording(false);
    //     const blob = new Blob(chunksRef.current, { type: "video/webm" });
    //     saveBlob(blob, "recording.webm");
    //     chunksRef.current = [];
    //   };

    //   mediaRecorderRef.current.start();
    //   setRecording(true);
    // } catch (err) {
    //   console.error("Error: " + err);
    // }
  };

  const saveBlob = (blob, filename) => {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  return (
    <>
      <Container
        maxWidth="xl"
        sx={{
          backgroundColor: "#AD235E",
          width: "100%",
          minHeight: 40,
          padding: 2,
        }}
      >
        <Grid container alignItems="center" spacing={2}>
          {/* Grievances and Helpdesk */}
          <Grid
            item
            xs={12}
            sm={6}
            lg={4}
            md={4}
            display="flex"
            alignItems="center"
          >
            <Typography
              variant="span"
              color="#fff"
              sx={objectCss?.commonStyle}
              onClick={() => navigate("/grievance-form")}
              style={{ cursor: "pointer" }}
            >
              {t("grievances")}
            </Typography>

            <Divider
              orientation="vertical"
              variant="middle"
              color="#fff"
              flexItem
              sx={{ marginLeft: "10px" }}
            />

            <Typography
              variant="span"
              color="#fff"
              marginLeft="10px"
              sx={{ fontWeight: 200, color: "#fff" }}
            >
              {t("Helpdesk Number").toUpperCase()}: {number}
            </Typography>
          </Grid>

          {/* Recording, Skip to Content, Dark Mode, Font Size Controls, Language */}
          <Grid
            item
            xs={12}
            sm={6}
            lg={5}
            md={5}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
          >
            {/* Screen Recording */}
            <Grid
              item
              display="flex"
              alignItems="center"
              sx={{ gap: 1, cursor: "pointer" }}
              onClick={(e) => startRecording(e)}
            >
              <Grid
                item
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: 5,
                  width: 24,
                  height: 24,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {/* <FiberManualRecordIcon
                  color={!recording ? "primary" : "error"}
                /> */}
                <MobileScreenShareIcon color={ "primary" }/>
              </Grid>
              <Typography
                component="a"
                href="#"
                color="#fff"
                sx={objectCss?.commonStyle}
              >
                {t("Screen_reader_access")}
              </Typography>
            </Grid>

            {/* Skip to Content */}
            <Typography
              component="a"
              href="#main-content"
              color="#fff"
              sx={objectCss?.commonStyle}
              marginLeft={2}
              onClick={(e) => {
                e.preventDefault();
                handleSkipToContent();
              }}
            >
              {t("Skip to main content")}
            </Typography>

            {/* Dark Mode Toggle */}
            <FormControlLabel
              control={
                <Switch
                  size="small"
                  color="warning"
                  onClick={toggleColorMode}
                />
              }
              label="Dark mode"
              sx={{ fontWeight: 200, color: "#fff", marginLeft: 2 }}
            />
          </Grid>

          {/* Font Size Controls */}
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            lg={3}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            sx={{ paddingTop: 2, paddingLeft: 2,  gap:2, }}
          >
              <Grid
                item
                justifyContent="center"
                sx={{ gap: 1, textAlign:'center' }}
                className="sizechanger"
              >
                <Typography
                  className="sm-a round-icon cursor"
                  onClick={decreaseFontSize}
                >
                  -A
                </Typography>
                <Typography className="lg-a round-icon" onClick={resetFontSize}>
                  A
                </Typography>
                <Typography
                  className="ex-lg-a round-icon"
                  onClick={increaseFontSize}
                >
                  +A
                </Typography>
              </Grid>

            {/* Language Selection */}

            <FormControl>
              <InputLabel sx={{ color: "#fff" }}>Select Language</InputLabel>
              <Select
                margin="dense"
                value={language || ""}
                onChange={handleChange}
                label="Language"
                autoComplete="off"
                placeholder="Select Language"
                sx={{ color: "#fff" }}
              >
                {Object.keys(lngs)?.length === 0 && (
                  <MenuItem value="" disabled>
                    <em>No languages available</em>
                  </MenuItem>
                )}
                {Object.keys(lngs)?.map((lng) => (
                  <MenuItem key={lng} value={lng}>
                    <ListItemIcon>
                      <img
                        src={lngs[lng]?.img}
                        alt="flag"
                        style={{
                          width: "25px",
                          height: "25px",
                          borderRadius: 20,
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText primary={lngs[lng]?.nativeName} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Topbarv2;

