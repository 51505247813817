import  NewspaperIcon from '@mui/icons-material/Newspaper';

export const content=[
    {id:1, icon:<NewspaperIcon sx={{ color: 'orange' }} />, title:'Lorem Ipsum is simply dummy text of the printing'},
    {id:2, icon:<NewspaperIcon sx={{ color: 'orange' }} />, title:'Lorem Ipsum is simply dummy text of the printing'},
    {id:3, icon:<NewspaperIcon sx={{ color: 'orange' }}/>, title:'Lorem Ipsum is simply dummy text of the printing'},
    {id:4, icon:<NewspaperIcon sx={{ color: 'orange' }}/>, title:'Lorem Ipsum is simply dummy text of the printing'},
    {id:5, icon:<NewspaperIcon sx={{ color: 'orange' }}/>, title:'Lorem Ipsum is simply dummy text of the printing'},
    {id:6, icon:<NewspaperIcon sx={{ color: 'orange' }}/>, title:'Lorem Ipsum is simply dummy text of the printing'},
    {id:7, icon:<NewspaperIcon sx={{ color: 'orange' }}/>, title:'Lorem Ipsum is simply dummy text of the printing'},
    {id:8, icon:<NewspaperIcon sx={{ color: 'orange' }}/>, title:'Lorem Ipsum is simply dummy text of the printing'},
    {id:9, icon:<NewspaperIcon sx={{ color: 'orange' }}/>, title:'Lorem Ipsum is simply dummy text of the printing'},
    {id:10, icon:<NewspaperIcon sx={{ color: 'orange' }}/>, title:'Lorem Ipsum is simply dummy text of the printing'},
    {id:11, icon:<NewspaperIcon sx={{ color: 'orange' }}/>, title:'Lorem Ipsum is simply dummy text of the printing'},
    {id:12, icon:<NewspaperIcon sx={{ color: 'orange' }}/>, title:'Lorem Ipsum is simply dummy text of the printing'},
    {id:13, icon:<NewspaperIcon sx={{ color: 'orange' }}/>, title:'Lorem Ipsum is simply dummy text of the printing'},
    {id:14, icon:<NewspaperIcon sx={{ color: 'orange' }}/>, title:'Lorem Ipsum is simply dummy text of the printing'},
    {id:15, icon:<NewspaperIcon sx={{ color: 'orange' }}/>, title:'Lorem Ipsum is simply dummy text of the printing'},
    {id:16, icon:<NewspaperIcon sx={{ color: 'orange' }}/>, title:'Lorem Ipsum is simply dummy text of the printing'},
    {id:17, icon:<NewspaperIcon sx={{ color: 'orange' }}/>, title:'Lorem Ipsum is simply dummy text of the printing'},
    {id:18, icon:<NewspaperIcon sx={{ color: 'orange' }}/>, title:'Lorem Ipsum is simply dummy text of the printing'},
    {id:19, icon:<NewspaperIcon sx={{ color: 'orange' }}/>, title:'Lorem Ipsum is simply dummy text of the printing'},
]
export const content1=[
    {id:1, icon:<NewspaperIcon sx={{ color: 'orange' }}/>, title:'Lorem Ipsum is simply dummy text of the printing'},
    {id:2, icon:<NewspaperIcon sx={{ color: 'orange' }}/>, title:'Lorem Ipsum is simply dummy text of the printing'},
    {id:3, icon:<NewspaperIcon sx={{ color: 'orange' }}/>, title:'Lorem Ipsum is simply dummy text of the printing'},
    {id:4, icon:<NewspaperIcon sx={{ color: 'orange' }}/>, title:'Lorem Ipsum is simply dummy text of the printing'},
    {id:5, icon:<NewspaperIcon sx={{ color: 'orange' }}/>, title:'Lorem Ipsum is simply dummy text of the printing'},
    {id:6, icon:<NewspaperIcon sx={{ color: 'orange' }}/>, title:'Lorem Ipsum is simply dummy text of the printing'},
    {id:7, icon:<NewspaperIcon sx={{ color: 'orange' }}/>, title:'Lorem Ipsum is simply dummy text of the printing'},
    {id:8, icon:<NewspaperIcon sx={{ color: 'orange' }}/>, title:'Lorem Ipsum is simply dummy text of the printing'},
    {id:9, icon:<NewspaperIcon sx={{ color: 'orange' }}/>, title:'Lorem Ipsum is simply dummy text of the printing'},
    {id:10, icon:<NewspaperIcon sx={{ color: 'orange' }}/>, title:'Lorem Ipsum is simply dummy text of the printing'},
]