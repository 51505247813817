import React from 'react';
import { Container, Typography, Button, Grid } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const FallBack = () => {
  return (
    <Container>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{ height: "100vh" }}
      >
        <Grid item>
          <ErrorOutlineIcon sx={{ fontSize: { xs: 80, md: 120 }, color: "error" }} />
        </Grid>
        <Grid item>
          <Typography variant="h4" sx={{ textAlign: "center", color: "error.main" }}>
            Oops! Something went wrong.
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1" sx={{ textAlign: "center" }}>
            Please try again later or contact support.
          </Typography>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={() => window.location.reload()}>
            Refresh
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default FallBack;
