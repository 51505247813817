import { useState } from 'react';

const useDrawer = () => {
  const [state, setState] = useState({
    right: false,
    width:"50%"
    // Add other drawer states if needed
  });

  const toggleDrawer = (anchor, open) => {
    return (event) => {
      if (event &&event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }
      setState((prevState) => ({ ...prevState, [anchor]: open }));
    };
  };

  return { state, toggleDrawer,setState };
};

export default useDrawer;
