import * as React from "react";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Typography,
  Grid,
  FormControl,
  TextField,
  Button,
  InputLabel,
  Select,
  MenuItem,
  FormLabel,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  api,
  useCreateProtectedGrievancesMutation,
  useGetDistrictQuery,
  useRevertGrievancesMutation,
  useUpdateTehsilMutation,
} from "../../services/api";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Link } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { setGrievanceId } from "../../Redux/GlobalSlice";
import { ThemeContext } from "../../services/ThemeContext";
import TrackGrievance from "./TrackGrievance";

export default function UpsertGrievance({ state, toggleDrawer, showCase }) {
  const [createGrievance] = useCreateProtectedGrievancesMutation();
  const [revertGrievance] = useRevertGrievancesMutation();
  const [updateTehsil] = useUpdateTehsilMutation(); // Get Incubation list
  const _id = useSelector((state) => {
    return state?.data?.data?.grievanceId;
  });
  const fileInputRef2 = React.useRef(null);
  const { data: districtList, isLoading, isError } = useGetDistrictQuery();
  const [grievanceRev] = api.endpoints.singleGrievances.useLazyQuery();
  const dispatch = useDispatch();
  const {fontSize } = React.useContext(ThemeContext);

  const schema = Yup.object().shape({
    subCategory:
      showCase !== "view"
        ? Yup.string().required("Category is required")
        : Yup.string().nullable(),
    subjectDescription:
      showCase !== "view"
        ? Yup.string().required("Subject is required")
        : Yup.string().nullable(),
    documents:
      showCase !== "view"
        ? Yup.string().required("Document is required")
        : Yup.string().nullable(),
    message:
      showCase === "view"
        ? Yup.string().required("Message is required")
        : Yup.string().nullable(),
  });
  const formik = useFormik({
    initialValues: {
      subCategory: "",
      subjectDescription: "",
      documents: "",
      message: "",
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      try {
        const formData = new FormData();
        if (showCase === "add") {
          formData.append("document", values.documents);
          formData.append("subject", values.subjectDescription);
          formData.append("category", values.subCategory);

          const { data } = await createGrievance(formData);
          console.log("🚀 ~ onSubmit: ~ data:", data)
          if (data?.success) {
            toast.success("Grievance is Created successfully", {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            formik.setFieldValue("subCategory", "");
            formik.setFieldValue("documents", "");
            formik.setFieldValue("subjectDescription", "");
            toggleDrawer("right", false)(values);
          }
        } else if (showCase === "view") {
          formData.append("document", values.documents);
          formData.append("subject", values.subjectDescription);
          formData.append("category", values.subCategory);
          formData.append("message", values.message);
          formData.append("grievance_id", _id);
          formData.append("type", "out");
          const { data } = await revertGrievance(formData);
          if (data?.success) {
            toast.success("Grievance is reveted successfully", {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            formik.setFieldValue("subCategory", "");
            formik.setFieldValue("documents", "");
            formik.setFieldValue("subjectDescription", "");
            formik.setFieldValue("message", "");
            // formik.setFieldValue("grievance_id", "");
            toggleDrawer("right", false)(values);
          }
        }
      } catch (error) {
        console.log("error=>", error);
      }
    },
  });
  const handleFileChange = (event, type) => {
    formik.setFieldValue(type, event.target.files[0]);
  };

  React.useEffect(() => {
    
    const UpdateApplication = async () => {
      if (showCase === "view" && _id) {
        try {
          const { data } = await grievanceRev(_id);
          if (data?.success) {
            const { category, document, subject } = data?.data;
            formik.setValues((prev) => ({
              ...prev,
              documents: document,
              subCategory: category,
              subjectDescription: subject,
            }));
          }
        } catch (error) {
          console.error("Error fetching applicant data:", error);
        }
      }
    };
    UpdateApplication();
  }, [showCase === "view", state["right"], _id]);

  return (
    <React.Fragment>
      <ToastContainer />
      <Drawer
        anchor={"right"}
        open={state["right"]}
        onClose={(event) => {
          formik.resetForm();
          toggleDrawer("right", false)(event);
        }}
      >
        {/* Content inside the Drawer goes here */}
        <Box role="presentation">

          <Box width={"95%"} marginLeft={"auto"} marginRight={"auto"} mb={2} >
            <Grid sx={{display:"flex",gap:10}}>

            <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 600, color: "#2074af", marginTop: 2 }}
            >
              {`${showCase === "view" ? "View" : "Add"} Grievance`}
            </Typography>
              <TrackGrievance id={_id}/>
            </Grid>
            
            <Box sx={{ mt: 1 }}>
              {/* =================================================Proctect part */}
              <Grid item xs={12} md={6} >
                <Typography
                  variant="h6"
                  align="left"
                  sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
                >
                  Sub category:
                </Typography>
                <br />
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    name="subCategory"
                    label="Sub-category"
                    disabled={showCase === "view"?true:false}
                    select
                    value={formik.values.subCategory}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.subCategory &&
                      Boolean(formik.errors.subCategory)
                    }
                    helperText={
                      formik.touched.subCategory && formik.errors.subCategory
                    }
                  >
                    <MenuItem value="General">General</MenuItem>
                    <MenuItem value="Technical">Technical</MenuItem>
                    <MenuItem value="Regulatory Compliances">
                      Regulatory Compliances
                    </MenuItem>
                    <MenuItem value="Incubation Support">
                      Incubation Support
                    </MenuItem>
                    <MenuItem value="Funding Support">Funding Support</MenuItem>
                    <MenuItem value="Public Procurement">
                      Public Procurement
                    </MenuItem>
                    <MenuItem value="Mentorship Support">
                      Mentorship Support
                    </MenuItem>
                    <MenuItem value="Any other query or grievance">
                      Any other query or grievance
                    </MenuItem>
                  </TextField>
                </FormControl>
              </Grid>
              <br />
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  align="left"
                  sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
                >
                  Subject:
                </Typography>
                <br />
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    name="subjectDescription"
                    disabled={showCase === "view"?true:false}
                    label="Type here (Strictly 200 words)"
                    multiline
                    rows={4}
                    value={formik.values.subjectDescription}
                    onChange={formik.handleChange}
                    inputProps={{ maxLength: 200 }}
                    error={
                      formik.touched.subjectDescription &&
                      Boolean(formik.errors.subjectDescription)
                    }
                    helperText={
                      formik.touched.subjectDescription &&
                      formik.errors.subjectDescription
                    }
                  />
                </FormControl>
              </Grid>

              <Grid item xl={6} lg={6} md={6} sm={12} xs={12} mt={2}>
                <FormControl className="file-upload-style" sx={{
                    backgroundColor: (theme) =>
                      theme.palette.background.default,
                    color: (theme) => theme.palette.text.primary,
                  }}
                  disabled={showCase === "view"?true:false}
                  >
                  {showCase !== "view" ? (
                    <CloudUploadIcon
                      className="icon-center"
                      style={{ cursor: "pointer" }}
                      onClick={() => fileInputRef2.current.click()}
                    />
                  ) : (
                    <></>
                  )}
                  {formik?.values?.documents && (
                    <Link
                      to={
                        !_id
                          ? URL.createObjectURL(formik.values.documents)
                          : formik.values.documents
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <VisibilityIcon />
                    </Link>
                  )}
                  {showCase !== "view" ? (
                    <>
                      <FormLabel>
                        {" "}
                        Upload any document related to Grievance (Screen shot,
                        PDF)
                      </FormLabel>
                      <input
                        type="file"
                        accept=".pdf, .jpg"
                        style={{ display: "none" }}
                        ref={fileInputRef2}
                        disabled={showCase === "view"?true:false}
                        onChange={(event) =>
                          handleFileChange(event, "documents")
                        }
                      />
                    </>
                  ) : (
                    <></>
                  )}
                  {formik?.errors?.documents && formik?.touched?.documents && (
                    <Typography color="error">
                      {formik?.errors?.documents}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
              <br />
              {showCase === "view" ? (
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    align="left"
                    sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
                  >
                    Message(Revert):
                  </Typography>
                  <br />
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      name="message"
                      label="Message"
                      multiline
                      rows={3}
                      value={formik.values.message}
                      onChange={formik.handleChange}
                      inputProps={{ maxLength: 200 }}
                      error={
                        formik.touched.message && Boolean(formik.errors.message)
                      }
                      helperText={
                        formik.touched.message && formik.errors.message
                      }
                    />
                  </FormControl>
                </Grid>
              ) : (
                <></>
              )}
              {/* Add your submit button here */}

              <Grid item xs={12} sx={{ textAlign: "center", marginTop: "95%" }}>
                <Button
                  // type="submit"
                  onClick={formik.handleSubmit}
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 2,
                    mr: 2,
                    borderRadius: 10,
                  }}
                >
                  {showCase === "view" ? "Submit" : "Save"}
                </Button>
                <Button
                  variant="outlined"
                  sx={{
                    mt: 3,
                    mb: 2,
                    borderRadius: 10,
                  }}
                  onClick={(event) => {
                    toggleDrawer("right", false)(event);
                    formik.resetForm();
                    dispatch(setGrievanceId(""));
                    // formik.setFieldValue("subCategory", "");
                    // formik.setFieldValue("documents", "");
                    // formik.setFieldValue("subjectDescription", "");
                  }}
                >
                  Cancel
                </Button>
              </Grid>
            </Box>
            {/* </Paper> */}
          </Box>
        </Box>
        <IconButton
          onClick={(event) => {
            toggleDrawer("right", false)(event);
            formik.resetForm();
            dispatch(setGrievanceId(""));
          }}
          style={{ position: "absolute", top: "8px", right: "8px" }}
        >
          <CloseIcon />
        </IconButton>
      </Drawer>
    </React.Fragment>
  );
}
