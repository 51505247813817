import * as React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import { api } from './../../services/api';

export default function RecognitionStatus({ id,setDic_Status=()=>{} }) {
  const [trackRecognition] =
    api.endpoints.trackRecognitionStatus.useLazyQuery();
  const [dicStatus, setDicStatus] = React.useState('');
  const [hodStatus, setHodStatus] = React.useState('');
  React.useEffect(() => {
    const UpdateApplication = async () => {
      if (id) {
        try {
          const { data } = await trackRecognition(id);
          if (data?.success) {
            setDicStatus(data?.application?.dicstatus);
            setDic_Status(data?.application?.dicstatus)
            setHodStatus(data?.application?.directoratestatus);
          }
        } catch (error) {
          console.error("Error fetching applicant data:", error);
        }
      }
    };
    UpdateApplication();
  }, [id]);

  return (
    <Timeline
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
      }}
    >
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot color={dicStatus==='accepted'?"success":dicStatus==='reverted'?"error":"grey"} />
          <TimelineConnector  sx={{
              backgroundColor:
              dicStatus === "accepted"
                  ? "green"
                  : dicStatus === "reverted"
                  ? "red"
                  : "grey",
            }}/>
        </TimelineSeparator>
        <TimelineContent>GM/DIC Status</TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot  color={hodStatus==='approved'?"success":hodStatus==='rejected'?"error":"grey"} />
          {/* <TimelineConnector /> */}
        </TimelineSeparator>
        <TimelineContent>HOD/Directorate Status</TimelineContent>
      </TimelineItem>
      {/* <TimelineItem>
        <TimelineSeparator>
          <TimelineDot  color={status==='approved'?"success":"grey"} />
        </TimelineSeparator>
        <TimelineContent>HOD/Directorate Status</TimelineContent>
      </TimelineItem> */}
    </Timeline>
  );
}
