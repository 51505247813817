import React, { useContext } from "react";
import {
  Box,
  Typography,
  Grid,
  FormControl,
  MenuItem,
  TextField,
  Button,
  Select,
  InputLabel,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import SendIcon from "@mui/icons-material/Send";
import LoadingButton from "@mui/lab/LoadingButton";
// Commenting out the API imports
import {
  api,
  useCreateApplicantMutation,
  useCreateRecognitionMutation,
  useGetSectorQuery,
  useGetSubSectorQuery,
} from "../../services/api";
import { ToastContainer, toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { ThemeContext } from "../../services/ThemeContext";

const StartupDetailsDuplicate = ({ steps, active, setActive }) => {
  const _id = localStorage.getItem("recognition_id");
  const [loading, setLoading] = React.useState(false);
  const {state}=useLocation()
  const {
    data: sectorList,
    isLoading: sectorLoading,
    isError: sectorError,
  } = useGetSectorQuery();
  const { data: subSectorList, isLoading: subSectorLoading } =
    useGetSubSectorQuery();
  const [recognitionCreate] = useCreateRecognitionMutation();
  const [getSingleRecognition] = api.endpoints.singleRecognition.useLazyQuery();
  const [copyToRecognition] = api.endpoints.copyToRecognition.useLazyQuery();
  const {fontSize } = useContext(ThemeContext);
  const wordCount = (str) => {
    return str.trim().split(/\s+/).length;
  };

  const validationSchema = Yup.object({
    name_of_the_startup: Yup.string().required(
      "Name of the Startup is required"
    ),
    sector: Yup.string().required("Sector is required"),
    sub_sector: Yup.string().required("Sub Sector is required"),
    idea: Yup.string()
      .test(
        "wordCount",
        "Must be 100 words or less",
        (value) => wordCount(value) <= 100
      )
      .required("Idea is required"),
    social_impact: Yup.string()
      .test(
        "wordCount",
        "Must be 100 words or less",
        (value) => wordCount(value) <= 100
      )
      .required("Social Impact is required"),
    uniqueness: Yup.string()
      .test(
        "wordCount",
        "Must be 100 words or less",
        (value) => wordCount(value) <= 100
      )
      .required("Uniqueness is required"),
  });

  const formik = useFormik({
    initialValues: {
      name_of_the_startup: "",
      sector: "",
      sub_sector: "",
      idea: "",
      social_impact: "",
      uniqueness: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const data = {
          step: "startup",
          payload: {
            startup_name: values.name_of_the_startup,
            application_id: _id,
            sector: values.sector,
            sub_sector: values.sub_sector,
            idea: values.idea,
            social_public: values.social_impact,
            uniqueness: values.uniqueness,
          },
        };

        // Commenting out the API call
        const response = await recognitionCreate(data);
        if (response?.data?.success) {
          toast.success(_id ? "Startup Details Updated Successfully" : "Startup Details Created Successfully", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setTimeout(() => {
            setActive((prevActive) => prevActive + 1);
          }, 3000);
        } else if (response?.error) {
          toast.error(response?.error?.data?.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } catch (error) {
        console.error("Error submitting data:", error);
      } finally {
        setLoading(false);
      }
    },
  });

  const handleNext = (e) => {
    setLoading(true);
    if (formik.isValid) {
      formik.handleSubmit();
    } else {
      setLoading(false);
    }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const handleBack = () => {
    setActive((prevActive) => prevActive - 1);
  };



  React.useEffect(() => {
    const UpdateRecognitionFromApplication = async () => {
      if (state) {
        try {
          // Commenting out the API call
          const { data } = await copyToRecognition(state);
          const {
            startup_name,
            sector,
            sub_sector,
            idea,
            social_public,
            uniqueness,
          } = data?.data;
          await formik.setValues((prev) => ({
            ...prev,
            name_of_the_startup: startup_name,
            sector,
            sub_sector,
            idea,
            social_impact: social_public,
            uniqueness,
          }));
        } catch (error) {
          console.error("Error fetching permission data:", error);
        }
      }
    };
    // Commenting out the useEffect hook call
    UpdateRecognitionFromApplication();
  }, [state]);
  React.useEffect(() => {
    const UpdateRecognition = async () => {
      if (_id) {
        try {
          // Commenting out the API call
          const { data } = await getSingleRecognition(_id);
          // const data = { application: {
          //   startup_name: "Sample Startup",
          //   sector: "Sample Sector",
          //   sub_sector: "Sample Sub Sector",
          //   idea: "Sample Idea",
          //   social_public: "Sample Social Impact",
          //   uniqueness: "Sample Uniqueness",
          // }}; // Dummy data for testing
          const {
            startup_name,
            sector,
            sub_sector,
            idea,
            social_public,
            uniqueness,
          } = data?.data;
          await formik.setValues((prev) => ({
            ...prev,
            name_of_the_startup: startup_name,
            sector,
            sub_sector,
            idea,
            social_impact: social_public,
            uniqueness,
          }));
        } catch (error) {
          console.error("Error fetching permission data:", error);
        }
      }
    };
    // Commenting out the useEffect hook call
    UpdateRecognition();
  }, [_id]);

  return (
    <>
      <ToastContainer />
      <Typography
        variant="h6"
        align="left"
        sx={{ fontWeight: 600, color: "#2074af" }}
      >
        Startup Details
      </Typography>
      <Box
        component="form"
        onSubmit={formik.handleSubmit}
        noValidate
        sx={{ mt: 1 }}
      >
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          mt={2}
        >
          {/* Name of the Startup */}
          <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: `${fontSize}px`}}
            >
              Name of the Startup:
            </Typography>
            <FormControl fullWidth>
              <TextField
                margin="dense"
                required
                fullWidth
                id="name_of_the_startup"
                label="Name of the Startup"
                name="name_of_the_startup"
                autoComplete="Company Name"
                value={formik.values.name_of_the_startup}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.name_of_the_startup &&
                  Boolean(formik.errors.name_of_the_startup)
                }
                helperText={
                  formik.touched.name_of_the_startup &&
                  formik.errors.name_of_the_startup
                }
              />
            </FormControl>
          </Grid>

          {/* Sector */}
          <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: `${fontSize}px`}}
            >
              Sector:
            </Typography>
            <br />
            <FormControl fullWidth>
              <InputLabel id="sector">Select Sector</InputLabel>
              <Select
                margin="dense"
                required
                fullWidth
                id="sector"
                label="Select Sector"
                name="sector"
                autoFocus
                value={formik.values.sector}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.sector && Boolean(formik.errors.sector)}
                helperText={formik.touched.sector && formik.errors.sector}
              >
                {sectorList?.data?.map((item) => (
                  <MenuItem value={item._id} key={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Sub Sector */}
          <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: `${fontSize}px`}}
            >
              Sub Sector:
            </Typography>
            <br />
            <FormControl fullWidth>
              <InputLabel id="sub_sector">Select Sub Sector</InputLabel>
              <Select
                margin="dense"
                required
                fullWidth
                id="sub_sector"
                label="Select Sub Sector"
                name="sub_sector"
                value={formik.values.sub_sector}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.sub_sector && Boolean(formik.errors.sub_sector)
                }
                helperText={
                  formik.touched.sub_sector && formik.errors.sub_sector
                }
              >
                {subSectorList?.data?.map((item) => (
                  <MenuItem value={item._id} key={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Idea */}
          <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: `${fontSize}px`}}
            >
              Idea:
            </Typography>
            <FormControl fullWidth>
              <TextField
                margin="dense"
                required
                fullWidth
                id="idea"
                label="Write the idea in 100 words"
                name="idea"
                autoComplete="Idea"
                multiline
                minRows={3}
                value={formik.values.idea}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.idea && Boolean(formik.errors.idea)}
                helperText={formik.touched.idea && formik.errors.idea}
              />
            </FormControl>
          </Grid>

          {/* Social Impact */}
          <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: `${fontSize}px`}}
            >
              Social Impact:
            </Typography>
            <FormControl fullWidth>
              <TextField
                margin="dense"
                required
                fullWidth
                id="social_impact"
                label="Write the social impact in 100 words"
                name="social_impact"
                autoComplete="Social Impact"
                multiline
                minRows={3}
                value={formik.values.social_impact}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.social_impact &&
                  Boolean(formik.errors.social_impact)
                }
                helperText={
                  formik.touched.social_impact && formik.errors.social_impact
                }
              />
            </FormControl>
          </Grid>

          {/* Uniqueness */}
          <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: `${fontSize}px`}}
            >
              Uniqueness:
            </Typography>
            <FormControl fullWidth>
              <TextField
                margin="dense"
                required
                fullWidth
                id="uniqueness"
                label="Write the uniqueness in 100 words"
                name="uniqueness"
                autoComplete="Uniqueness"
                multiline
                minRows={3}
                value={formik.values.uniqueness}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.uniqueness &&
                  Boolean(formik.errors.uniqueness)
                }
                helperText={
                  formik.touched.uniqueness && formik.errors.uniqueness
                }
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className="button-right"
          >
            <Button
              type="button"
              variant="contained"
              onClick={handleBack}
              disabled={active === 0}
            >
              Previous
            </Button>
            <Box ml={2} display="inline-block">
              <LoadingButton
                variant="contained"
                onClick={handleNext}
                disabled={active === steps.length - 1}
                endIcon={<SendIcon />}
                loading={loading}
                loadingPosition="end"
                type="submit"
              >
                <span>Next</span>
              </LoadingButton>
            </Box>
          </Grid>
      </Box>
    </>
  );
};

export default StartupDetailsDuplicate;
