import React, { useEffect, useRef, useState } from "react";
import {
  TextField,
  Button,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  MenuItem,
  Box,
  Container,
  Paper,
  Grid,
  FormLabel,
  FormHelperText,
  Link,
  Typography,
  InputAdornment,
  IconButton,
  InputLabel,
  Select,
  useTheme,
} from "@mui/material";
// import {
//   Container,
//   FormControl,
//   FormControlLabel,
//   FormLabel,

//   InputAdornment,
//   InputLabel,
//   MenuItem,
//   Paper,
//   Radio,
//   RadioGroup,
//   Select,
// } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import black_bg from "../../Assests/auth-bg.jpg";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import {
  api,
  useCreateGrievancesMutation,
  useCreateUserMutation,
  useGetDistrictQuery,
  useGetDistrictStateWiseQuery,
  useGetStateQuery,
  useGetTehsilDistrictWiseQuery,
  useGetTehsilQuery,
  usePostOTPMutation,
  useVerifyOTPMutation,
} from "../../services/api";
import { toast , ToastContainer } from "react-toastify";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from "react-simple-captcha";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import moment from "moment";
import CustomizedProgressBars from "../CustomProgress/ProgressBar";
import { ThemeContext } from "../../services/ThemeContext";

const validationSchema = Yup.object().shape({
  registered: Yup.string(),
  name: Yup.string().when("registered", {
    is: () => "no",
    then: () =>
      Yup.string()
        .required("Name is required")
        .min(2, "Name should have at least 2 characters")
        .max(50, "Name should not exceed 50 characters"),
  }),
  email: Yup.string()
    .email("Invalid email format")
    .when("registered", {
      is: () => "no",
      then: () => Yup.string().required("Email is required"),
    }),
  mobile: Yup.string()
    .matches(/^[0-9]{10}$/, "Mobile number must be 10 digits")
    .when("registered", {
      is: () => "no",
      then: () => Yup.string().required("Mobile number is required"),
    }),
  dob: Yup.date().required("Date of Birth is required"),
  state: Yup.string().required("State is required"),
  district: Yup.string().required("District is required"),
  block: Yup.string().required("Block/Tehsil is required"),
  village: Yup.string().required("Village/City/Town is required"),
  pin: Yup.string()
    .matches(/^[0-9]{6}$/, "PIN code must be 6 digits")
    .required("PIN code is required"),
  gender: Yup.string().required("Gender is required"),
  category: Yup.string().required("Category is required"),
  // verification: Yup.string().required("Verification is required"),
  password: Yup.string().when("registered", {
    is: () => "no",
    then: () =>
      Yup.string()
        .required("Password is required")
        .min(8, "Password must be at least 8 characters long"),
  }),
  // Cpassword: Yup.string().when("registered", {
  //   is: () => "no",
  //   then: () =>
  //     Yup.string()
  //       .oneOf([Yup.ref("password"), null], "Passwords must match")
  //       .required("Confirm Password is required"),
  // }),
  // captcha: Yup.string().required("Captcha is required"),
  // userId: Yup.string().when("registered", {
  //   is: () => "yes",
  //   then: () => Yup.string().required("User ID is required"),
  // }),
  // loginPassword: Yup.string().when("registered", {
  //   is: () => "yes",
  //   then: () => Yup.string().required("Login Password is required"),
  // }),
  // loginCaptcha: Yup.string().when("registered", {
  //   is: () => "yes",
  //   then: () => Yup.string().required("Login Captcha is required"),
  // }),
  // subCategory: Yup.string().required("SubCategory is required"),
});

const GrievanceForm = () => {
  const fileInputRef2 = useRef(null);
  const navigate = useNavigate();
  const [craeteGriev] = useCreateGrievancesMutation();
  const [createUser] = useCreateUserMutation();
  const [showOTPField, setShowOTPField] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [postVerifyOtp] = useVerifyOTPMutation();
  const [postMob] = usePostOTPMutation();
  const [captcha, setCaptcha] = useState("");
  const { data: stateData } = useGetStateQuery();
  const [districtData] = api.endpoints.getDistrictStateWise.useLazyQuery();
  const [tehsilData] = api.endpoints.getTehsilDistrictWise.useLazyQuery(); // Get Incubation list
  const theme=useTheme()
  const {fontSize } = React.useContext(ThemeContext);

  const formik = useFormik({
    initialValues: {
      registered: "no",
      name: "",
      email: "",
      mobile: "",
      dob: "",
      state: "",
      district: "",
      block: "",
      tehsil: "",
      village: "",
      pin: "",
      gender: "",
      category: "",

      district_list: [],
      tehsil_list: [],
      block_list: [],

      // verification: "",
      // password: "",
      // confirmPassword: "",
      // captcha: "",
      // userId: "",
      // loginPassword: "",
      // loginCaptcha: "",
      // subCategory: "",

      //================???
      // number: "",
      // email: "",
      password: "",
      Cpassword: "",
      otp: "",
    },

    validationSchema: validationSchema, // Attach the validation schema here
    onSubmit: async (values) => {
      if (!validateCaptcha(captcha) == true) {
        toast.error("Captcha did not match", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        loadCaptchaEnginge(6);
        return;
      }
      const getData = {
        email: values.email,
        name: values.name,
        gender: values.gender,
        register: "applicant",
        mobile: values.mobile,
        password: values.password,
        // roles: userRoleID,
        dob: moment(values.dob).format("YYYY-MM-DD"),
        category: values.category,
        state: values.state,
        district: values.district,
        block: values.block,
        tehsil: values.tehsil,
        pin: values.pin,
        village: values.village,
        // otp: values.,
        // startup_policy: localStorage.getItem("policy"),
        // have_dpiit: values.dpiit,
        // dpiit_number:values.dpiit_number,
        // dpiit_start_date: values.dpiit_start_date,
        refer_id: localStorage.getItem("policy"),
      };

      const {
        data: userData,
        error: isCreateError,
        isLoading: isUserLoading,
      } = await craeteGriev(getData);
      if (userData && userData?.success === true) {
        const time = 2000;
        toast.success("Grievance User Created successfully", {
          position: "top-center",
          autoClose: time,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setTimeout(() => {
          navigate("/sign-in");
        }, time + 1000);
      }
      if (isCreateError?.data && !isUserLoading) {
        toast.error(isCreateError.data.message ?? "Something went wrong", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    },
  });
  const handleFileChange = (event, type) => {
    formik.setFieldValue(type, event.target.files[0]);
  };
  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  useEffect(() => {
    loadCaptchaEnginge(6, "grey");
  }, []);
  const handleGetOTP = async () => {
    if (formik.values.mobile && !formik.values.otp) {
      // Send mobile mobile to backend to request OTP
      const response = await postMob({ mobile: formik.values.mobile });
      if (response?.data?.success) {
        // OTP sent successfully, show OTP input field
        setShowOTPField(true);
        // Optionally, you can display a message to the user indicating that the OTP has been sent
        toast.success(response?.data?.data, {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        // Handle error if OTP sending failed
        toast.error("Failed to send OTP. Please try again.", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } else if (formik.values.otp && formik.values.mobile) {
      // Verify OTP using the provided OTP and mobile mobile
      const response = await postVerifyOtp({
        otp: formik.values.otp,
        mobile: formik.values.mobile,
      });
      formik.setFieldValue("otp_respose", response?.data?.success);
      toast.success(response?.data?.data, {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      // Handle the response accordingly
    } else {
      // This block handles the scenario where neither the mobile mobile nor the OTP is provided
      console.error("Mobile number and OTP are required");
    }
  };
  const handleStateChange = async (id) => {
    formik.setFieldValue("state", id);
    const res = await districtData(id);
    if (res?.data?.success) {
      formik.setFieldValue("district_list", res?.data?.data);
    }
  };

  const handleDistrictChange = async (id) => {
    formik.setFieldValue("district", id);

    const response = await tehsilData(id);
    if (response?.data?.success) {
      formik.setFieldValue("tehsil_list", response?.data?.data.tehsil);
      formik.setFieldValue("block_list", response?.data?.data?.block);
    }
  };

  return (
    <Box className="screen-app-container">
      <ToastContainer/>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "2rem",
          backgroundImage: `url(${theme.palette.mode==="dark"?null:black_bg})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          width: "100%",
          backgroundColor: (theme) =>
            theme.palette.background.default,
          color: (theme) => theme.palette.text.primary
        }}
      >
        <Container component="main" 
        // className="register-page"
        >
          <Paper
            sx={{
              padding: "3em 2em",
              marginTop: "1em",
              marginBottom: "1em",
            }}
            elevation={5}
          >
            {/* <Typography variant="h1">Grievance Form</Typography> */}
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              sx={{
                height: "20%",
                flexDirection: "column",
                gap: "10px",
                marginBottom: "2rem",
              }}
            >
              <Grid item md={6} lg={6} sm={6}>
                <Typography variant="h5" align="center" fontWeight="600">
                  Grievance Form
                </Typography>
              </Grid>
              <Grid item md={6} lg={6} sm={6}>
                <CustomizedProgressBars val={50} />
              </Grid>
            </Grid>
            <form onSubmit={formik.handleSubmit}>
              <Typography sx={{ fontSize: `${fontSize}px`}}>
                Are you registered or Recognised Startup?
              </Typography>
              <Typography variant="p">
                (Note: If yes, no need to register again, login using previous
                credentials. Capture the registration, if no then only show and
                create the below fields)
              </Typography>

              {/* <FormControl component="fieldset">
                <RadioGroup
                  aria-label="registered"
                  name="registered"
                  value={formik.values.registered}
                  onChange={() => {
                    formik.setFieldValue("registered", "yes" || "no");
                  }}
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="YES"
                  />
                  <FormControlLabel value="no" control={<Radio />} label="NO" />
                </RadioGroup>
              </FormControl> */}
              <FormControl fullWidth>
                <FormLabel>Registered</FormLabel>
                <RadioGroup
                  row
                  name="registered"
                  value={formik?.values?.registered}
                  onChange={formik.handleChange}
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
              {formik.values.registered === "no" && (
                <>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        name="name"
                        label="Name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.name && Boolean(formik.errors.name)
                        }
                        helperText={formik.touched.name && formik.errors.name}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        name="email"
                        label="Email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.email && Boolean(formik.errors.email)
                        }
                        helperText={formik.touched.email && formik.errors.email}
                      />
                    </Grid>
                    {/* <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        name="mobile"
                        label="Mobile No."
                        value={formik.values.mobile}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.mobile && Boolean(formik.errors.mobile)
                        }
                        helperText={
                          formik.touched.mobile && formik.errors.mobile
                        }
                      />
                    </Grid> */}
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        name="dob"
                        label="DOB"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        value={formik.values.dob}
                        onChange={formik.handleChange}
                        error={formik.touched.dob && Boolean(formik.errors.dob)}
                        helperText={formik.touched.dob && formik.errors.dob}
                      />
                    </Grid>
                    {/* <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        name="state"
                        label="State"
                        value={formik.values.state}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.state && Boolean(formik.errors.state)
                        }
                        helperText={formik.touched.state && formik.errors.state}
                      />
                    </Grid> */}
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth>
                        <InputLabel id="state">State</InputLabel>
                        <Select
                          margin="dense"
                          required
                          fullWidth
                          id="state"
                          label="State"
                          name="State"
                          autoFocus
                          value={formik.values.state}
                          onChange={(e) => {
                            e.preventDefault();
                            handleStateChange(e.target.value);
                          }}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.state && Boolean(formik.errors.state)
                          }
                          autoComplete="off"
                        >
                          {stateData?.data?.map((item) => (
                            <MenuItem value={item._id} key={item._id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    {/* <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        name="district"
                        label="District"
                        value={formik.values.district}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.district &&
                          Boolean(formik.errors.district)
                        }
                        helperText={
                          formik.touched.district && formik.errors.district
                        }
                      />
                    </Grid> */}
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth>
                        <InputLabel id="state">Select District</InputLabel>
                        <Select
                          margin="dense"
                          required
                          fullWidth
                          id="district"
                          label="Select District"
                          name="district"
                          autoFocus
                          value={formik.values.district}
                          onChange={(e) => {
                            e.preventDefault();
                            handleDistrictChange(e.target.value);
                          }}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.district &&
                            Boolean(formik.errors.district)
                          }
                          autoComplete="off"
                        >
                          {formik.values.district_list?.map((item) => (
                            <MenuItem value={item._id} key={item._id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    {/* <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        name="block"
                        label="Block /Tehsil"
                        value={formik.values.block}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.block && Boolean(formik.errors.block)
                        }
                        helperText={formik.touched.block && formik.errors.block}
                      />
                    </Grid> */}
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth>
                        <InputLabel id="Tehsil">Tehsil</InputLabel>
                        <Select
                          margin="dense"
                          required
                          fullWidth
                          id="Tehsil"
                          label="Tehsil"
                          name="Tehsil"
                          autoFocus
                          value={formik.values.tehsil}
                          onChange={(e) =>
                            formik.setFieldValue("tehsil", e.target.value)
                          }
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.tehsil &&
                            Boolean(formik.errors.tehsil)
                          }
                          autoComplete="off"
                        >
                          {formik.values.tehsil_list?.map((item) => (
                            <MenuItem value={item._id} key={item._id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth>
                        <InputLabel id="Block">Block </InputLabel>
                        <Select
                          margin="dense"
                          required
                          fullWidth
                          id="Block"
                          label="Block"
                          name="Block"
                          autoFocus
                          value={formik.values.block}
                          onChange={(e) =>
                            formik.setFieldValue("block", e.target.value)
                          }
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.block && Boolean(formik.errors.block)
                          }
                          autoComplete="off"
                        >
                          {formik.values.block_list?.map((item) => (
                            <MenuItem value={item._id} key={item._id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        name="village"
                        label="Village/ City/ Town"
                        value={formik.values.village}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.village &&
                          Boolean(formik.errors.village)
                        }
                        helperText={
                          formik.touched.village && formik.errors.village
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        name="pin"
                        label="PIN"
                        value={formik.values.pin}
                        onChange={formik.handleChange}
                        error={formik.touched.pin && Boolean(formik.errors.pin)}
                        helperText={formik.touched.pin && formik.errors.pin}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        name="gender"
                        label="Gender"
                        select
                        value={formik.values.gender}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.gender && Boolean(formik.errors.gender)
                        }
                        helperText={
                          formik.touched.gender && formik.errors.gender
                        }
                      >
                        <MenuItem value="male">Male</MenuItem>
                        <MenuItem value="female">Female</MenuItem>
                        <MenuItem value="transgender">Transgender</MenuItem>
                        <MenuItem value="others">Others</MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        name="category"
                        label="Category"
                        select
                        value={formik.values.category}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.category &&
                          Boolean(formik.errors.category)
                        }
                        helperText={
                          formik.touched.category && formik.errors.category
                        }
                      >
                        <MenuItem value="general">General</MenuItem>
                        <MenuItem value="sc">SC</MenuItem>
                        <MenuItem value="st">ST</MenuItem>
                        <MenuItem value="obc">OBC</MenuItem>
                        <MenuItem value="others">Others</MenuItem>
                      </TextField>
                    </Grid>
                    {/* <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        name="verification"
                        label="Verification (OTP on email and Mobile)"
                        value={formik.values.verification}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.verification &&
                          Boolean(formik.errors.verification)
                        }
                        helperText={
                          formik.touched.verification &&
                          formik.errors.verification
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.password &&
                          Boolean(formik.errors.password)
                        }
                        helperText={
                          formik.touched.password && formik.errors.password
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        name="confirmPassword"
                        label="Confirm Password"
                        type="password"
                        value={formik.values.confirmPassword}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.confirmPassword &&
                          Boolean(formik.errors.confirmPassword)
                        }
                        helperText={
                          formik.touched.confirmPassword &&
                          formik.errors.confirmPassword
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        name="captcha"
                        label="Captcha"
                        value={formik.values.captcha}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.captcha &&
                          Boolean(formik.errors.captcha)
                        }
                        helperText={
                          formik.touched.captcha && formik.errors.captcha
                        }
                      />
                    </Grid> */}
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                      <TextField
                        required
                        fullWidth
                        id="mobile"
                        label="Mobile mobile"
                        name="mobile"
                        autoComplete="mobile"
                        value={formik.values.mobile}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.mobile && Boolean(formik.errors.mobile)
                        }
                        helperText={
                          formik.touched.mobile && formik.errors.mobile
                        }
                      />
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                      {showOTPField && (
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          name="otp"
                          label="OTP"
                          type="text"
                          id="otp"
                          autoComplete="otp"
                          value={formik.values.otp}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.otp && Boolean(formik.errors.otp)
                          }
                          helperText={formik.touched.otp && formik.errors.otp}
                        />
                      )}
                    </Grid>
                    <Grid item xl={6} lg={6} md={2} sm={12} xs={12} mt={2}>
                      {(formik.values.otp || formik.values.mobile) && (
                        <Button
                          type="button"
                          fullWidth
                          variant="contained"
                          sx={{ mt: 1, mb: 1 }}
                          onClick={handleGetOTP}
                        >
                          {showOTPField ? "Submit OTP" : "Get OTP"}
                        </Button>
                      )}
                    </Grid>

                    {/* Password */}
                    {formik?.values?.otp_respose ? (
                      <>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                          <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type={showPassword ? "text" : "password"}
                            id="password"
                            autoComplete="password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                              formik.touched.password &&
                              Boolean(formik.errors.password)
                            }
                            helperText={
                              formik.touched.password && formik.errors.password
                            }
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={handleTogglePasswordVisibility}
                                    edge="end"
                                  >
                                    {showPassword ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                          <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="Cpassword"
                            label="Confirm Password"
                            type="password"
                            id="confirm-password"
                            autoComplete="confirm-password"
                            value={formik.values.Cpassword}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                              formik.touched.Cpassword &&
                              Boolean(formik.errors.Cpassword)
                            }
                            helperText={
                              formik.touched.Cpassword &&
                              formik.errors.Cpassword
                            }
                            // onChange={(e) => handleChange(e)}
                          />
                        </Grid>
                      </>
                    ) : (
                      <></>
                    )}
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                      <Box className="captcha-continer" >
                        <LoadCanvasTemplate
                          reloadText="Get a new code"
                          reloadColor="white"
                        />
                        <Box className="input-block">
                          <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="captcha"
                            type="text"
                            id="captcha"
                            placeholder="Enter Verification code"
                            onChange={(e) => setCaptcha(e.target.value)}
                          />
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </>
              )}
              {formik.values.registered === "yes" && navigate("/sign-in")}
              <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                <Button
                  type="submit"
                  onClick={formik.handleSubmit}
                  variant="contained"
                  color="primary"
                >
                  Submit
                </Button>
              </Box>
            </form>
          </Paper>
        </Container>
      </Box>
    </Box>
  );
};

export default GrievanceForm;
