import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Tab,
  Tabs,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Container,
  useTheme,
} from "@mui/material";
import { content, content1 } from "./constant";
import CustomizedProgressBars from "../CustomProgress/ProgressBar";
import { useTranslation } from "react-i18next";
import "leaflet/dist/leaflet.css";
import black_bg from "../../Assests/map-announcement-bg.jpg";
import image from "../../Assests/hp-map.png";
import statsimage from "../../Assests/stats.png";
import UpdateIcon from "@mui/icons-material/Update";
import NewspaperIcon from "@mui/icons-material/Newspaper";

const HpMap = ({ mainContentRef }) => {
  const [tabValue, setTabValue] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const theme = useTheme();

  const handleListItemClick = (index) => {
    setSelectedItem(index);
  };
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        background: `url(${
          theme.palette.mode === "dark" ? null : black_bg
        }) center center no-repeat`, // Center the background image
        backgroundSize: "cover",
        height: "100%",
        width: "100%",
        padding: "50px 0", // Equal padding
        justifyContent: "space-around",
        backgroundColor: (theme) => theme.palette.background.default,
        color: (theme) => theme.palette.text.primary,
      }}
      ref={mainContentRef}
    >
      <Container
        maxWidth="xl"
        sx={{
          backgroundColor: (theme) => theme.palette.background.default,
          color: (theme) => theme.palette.text.primary,
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
            <Grid item xs={12} sx={{ marginBottom: 3 }}>
              <Typography
                component="h1"
                sx={{ fontWeight: 700, fontSize: "x-large" }}
              >
                {t("announcements")}
              </Typography>
              <CustomizedProgressBars val={50} />
            </Grid>
            <Card
              className="announcements-tabs-style"
              sx={{
                backgroundColor: "#fff",
                borderRadius: 2,
                width: "100%",
              }}
              variant="outlined"
              id="main-content"
              // className={(item.subject).replaceAll(" ", "-")}
            >
              <Tabs
                className="announcements-tabs"
                value={tabValue}
                onChange={() => setTabValue(!tabValue)}
                // textColor="inherit"
                indicatorColor="#fff"
                width="100%"
                // centered
                // sx={{ marginTop: 2 }}
              >
                <Tab
                  label="News"
                  icon={<NewspaperIcon />}
                  sx={{
                    backgroundColor: !tabValue ? "#1761a0" : "black",
                    color: "#fff",
                    borderTopLeftRadius: 2,
                    borderTopRightRadius: 2,
                    borderbottomRightRadius: 0,
                    borderbottomLeftRadius: 0,
                  }}
                />
                <Tab
                  label="Updates"
                  icon={<UpdateIcon />}
                  sx={{
                    backgroundColor: tabValue ? "#1761a0" : "black",
                    color: "#fff",
                    borderTopLeftRadius: 2,
                    borderTopRightRadius: 2,
                    borderbottomRightRadius: 0,
                    borderbottomLeftRadius: 0,
                  }}
                />
              </Tabs>

              {/* Content based on the selected tab */}
              <CardContent
                className="announcements-tab-data"
                sx={{
                  backgroundColor: (theme) => theme.palette.background.default,
                  color: (theme) => theme.palette.text.primary,
                }}
              >
                {!tabValue && (
                  <List>
                    {content.map((val, index) => {
                      const { icon, title } = val;
                      return (
                        <ListItem
                          key={index}
                          selected={selectedItem === index}
                          onClick={() => handleListItemClick(index)}
                        >
                          <ListItemIcon>{icon}</ListItemIcon>
                          <ListItemText primary={title} />
                        </ListItem>
                      );
                    })}
                  </List>
                )}
                {tabValue && (
                  <List>
                    {content1.map((val, index) => {
                      const { icon, title } = val;
                      return (
                        <ListItem
                          key={index}
                          selected={selectedItem === index}
                          onClick={() => handleListItemClick(index)}
                          
                        >
                          <ListItemIcon>{icon}</ListItemIcon>
                          <ListItemText primary={title} sx={{ color: (theme) => theme.palette.text.primary}} />
                        </ListItem>
                      );
                    })}
                  </List>
                )}
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginBottom: 3 }}>
              <Typography
                component="h1"
                sx={{ fontWeight: 700, fontSize: "x-large" }}
              >
                {t("hp_map")}
              </Typography>
              <CustomizedProgressBars val={50} />
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                <img
                  src={image}
                  style={{
                    fontSize: "larger",
                    width: "100%",
                    height: "100%",
                    borderRadius: 5,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                <img
                  src={statsimage}
                  style={{
                    fontSize: "larger",
                    width: "100%",
                    borderRadius: 5,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default HpMap;
