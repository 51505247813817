import image1 from "../../Assests/Workshop on Idea to Business ...png";
import image2 from "../../Assests/SmartIDEAthon 2022 Challenge.png";
import image3 from "../../Assests/hp_trek.png";
import image4 from "../../Assests/event-banner-5@2x.png";


export const upcomingImages=[
    {
        id:1, img:image1
    },
    {
        id:2, img:image2
    },
    {
        id:3, img:image3
    },
    {
        id:4, img:image2
    },
    {
        id:5, img:image3
    },
    {
        id:6, img:image4
    },
    {
        id:7, img:image4
    },
    {
        id:8, img:image1
    },
]