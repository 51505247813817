import { configureStore } from '@reduxjs/toolkit'
// Or from '@reduxjs/toolkit/query/react'
import { api } from "./services/api"
import { setupListeners } from '@reduxjs/toolkit/query';
import rootReducer from './Redux/rootReducer';

export const store = configureStore({
    reducer: {
        [api.reducerPath]: api.reducer,
        data:rootReducer
    },
    middleware: (getDefaultMiddleware) => 
        getDefaultMiddleware().concat(api.middleware)
    
});

setupListeners(store.dispatch);