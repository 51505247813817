import React, { useRef } from "react";
import { Outlet } from "react-router-dom";
import Footerv2 from "../../Screens/Footer/indexv2";
import Navbar from "../Navbar/indexv2";

const Root = ({mainContentRef}) => {
  const handleSkipToContent = (event) => {
    mainContentRef?.current?.scrollIntoView({ behavior: 'smooth' });
    mainContentRef?.current?.focus();
  };
  return (
    <div>
      <Navbar {...{handleSkipToContent, mainContentRef}}/>
      <Outlet />
      <Footerv2 {...{home:true}}/>
    </div>
  );
};

export default Root;
