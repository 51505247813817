import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export default function PrintDoc(){
    window.print();
}

export const exportToPdf = async (element) => {
    console.log("🚀 ~ exportToPdf ~ element:", element)
    const canvas = await html2canvas(element);
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF();
    const imgProps = pdf.getImageProperties(imgData);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
  
    pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
    pdf.save('form.pdf');
  };