import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  FormControl,
  MenuItem,
  TextField,
  Button,
  Paper,
  InputLabel,
  Select,
  Table,
  TableHead,
  TableCell,
  TableContainer,
  TableRow,
  TableBody,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  Divider,
  IconButton,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import LoadingButton from "@mui/lab/LoadingButton";
import { useFormik } from "formik";
import SendIcon from "@mui/icons-material/Send";
import {
  api,
  useChangeRecognitionStatusByDicMutation,
  useChangeRecognitionStatusByDirectorMutation,
  useChangeStatusByNodalMutation,
  useChangeStatusByTechnicalMutation,
  useCreateApplicantMutation,
  useGetBETQuery,
  useGetBlockQuery,
  useGetDistrictQuery,
  useGetLegislativeQuery,
  useGetPCQuery,
  useGetPriorityQuery,
  useGetRecognitionQuery,
  useGetSectorQuery,
  useGetStateQuery,
  useGetSubSectorQuery,
  useGetTehsilQuery,
  useIncubationDetailsQuery,
  useSingleApplicantQuery,
} from "../../services/api";
import dayjs from "dayjs";
import { ToastContainer, toast } from "react-toastify";
import * as yup from "yup";
import CustomizedProgressBars from "../CustomProgress/ProgressBar";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ChangeStatusDialog from "../ApplicantionStatus/DialogComponent";
import { ThemeContext } from "../../services/ThemeContext";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import DownloadIcon from "@mui/icons-material/Download";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { generatePdf } from "./generatePdf";
import RecognitionStatus from "./TrackRecognitionStatus";

const validationSchema = yup.object().shape({
  fullName: yup.string().required("Full Name is required"),
  // dob: yup.date().required('Date of Birth is required').max(new Date(), 'Date of Birth cannot be in the future'),
  gender: yup
    .string()
    .required("Gender is required")
    .oneOf(["male", "female", "transgender", "other"], "Invalid Gender"),
  entrepreneurCategory: yup
    .string()
    .required("Entrepreneur Category is required"),
  companyName: yup.string().required("Company Name is required"),
  projectFormation: yup.date().required("Project Formation Date is required"),
  address: yup.string().required("Address is required"),
  incubationCenter: yup.string().required("Incubation Center is required"),
  typeOfProject: yup.string().required("Type of Project is required"),
  hasProjectStarted: yup.string().required("Has Project Started is required"),
  briefDescriptionOfProject: yup
    .string()
    .required("Brief Description of Project is required"),
  projectCost: yup.string().required("Project Cost is required"),
  expenditure: yup.string().required("expenditure is required"),
  expectationFromHostInstitute: yup
    .string()
    .required("Expectation from Host Institute is required"),
  expectationRequiredToBeIncurred: yup
    .string()
    .required("Expectation Required to be Incurred is required"),
  uniquenessAboutYourProject: yup
    .string()
    .required("Uniqueness About Your Project is required"),
  inspirationBehindIdea: yup
    .string()
    .required("Inspiration Behind Idea is required"),
});

const commonColor = {
  styles: {
    borderStyle: "solid",
    borderColor: "#000000",
    borderBottomWidth: "medium",
    opacity: 1,
  },
};
const RecognitionPreview = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [dicStatus, setDicStatus] = useState("");
  const { data: incubationList } = useIncubationDetailsQuery();
  const { id } = useParams();
  const [rows, setRows] = useState([
    {
      document: "Proposal For Presentation",
      type: "Pdf/Word/Ppt",
      name: "",
      file: null,
    },
  ]);
  const { data: recognitionData } = useGetRecognitionQuery();
  const [getAllApplicant] = api.endpoints.getRecognition.useLazyQuery();
  const fileInputRef = useRef([]);
  const navigate = useNavigate();
  const { data: subsectorList } = useGetSubSectorQuery();
  const { data: sectorList } = useGetSectorQuery();
  const { data: priorityList } = useGetPriorityQuery();
  const { data: lacList } = useGetLegislativeQuery();
  const { data: pcList } = useGetPCQuery();
  const { data: stateList } = useGetStateQuery();
  const { data: districtList } = useGetDistrictQuery();
  const { data: tehsilList } = useGetTehsilQuery();
  const { data: blockList } = useGetBlockQuery();
  const { data: betList } = useGetBETQuery();
  const [applicationApi] = api.endpoints.singleApplicant.useLazyQuery();
  const [actionByDic] = useChangeRecognitionStatusByDicMutation();
  const [actionByDirector] = useChangeRecognitionStatusByDirectorMutation();
  const role = localStorage.getItem("role").toLowerCase();
  const { fontSize } = useContext(ThemeContext);
  const [lacData] = api.endpoints.getLegislativeDistrictWise.useLazyQuery();
  const [contiTencyData] = api.endpoints.getParlimentContiWise.useLazyQuery();

  // const [createApplicant] = useCreateApplicantMutation();

  const _id = localStorage.getItem("personal_details");
  // const {
  //   data: getApplicant,
  //   isLoading,
  //   isError,
  // } = useSingleApplicantQuery(id);
  const [getSingleRecognition, { data, isLoading, isError }] =
    api.endpoints.singleRecognition.useLazyQuery();

  const formik = useFormik({
    initialValues: {
      women_led_startup: "no",
      category: "",
      priority_sector: "no",
      priority_sector_drop: "",
      name_of_the_startup: "",
      sector: "",
      sub_sector: "",
      idea: "",
      social_impact: "",
      uniqueness: "",
      incubation_center: "",
      incubationList: "",
      name_of_the_founder: "",
      name_of_the_cofounder: "",
      contact: "",
      pan: "",
      aadhaar: "",
      address_of_the_founder: "",
      lac: "",
      // pc: "",
      parliment: "",
      constituency: "",
      lacList: [],
      constiList: [],
      state: "",
      district: "",
      tehsil: "",
      block: "",
      pin: "",
      //=====================Business Details
      bet: "",
      lac_bp: "",
      district_bp: "",
      business_parliment: "",
      business_constituency: "",
      business_lacList: [],
      business_constiList: [],
      state_bp: "",
      tehsil_bp: "",
      block_bp: "",
      pin_bp: "",
      gst_no: "",
      incorporation_number: "",
      udyam_no: "",
      business_address: "",
      initial_investment: "",
      revenue: "",
      funding_received: "",
      funding_details: "",
      number_of_employees: "",
      number_of_himachali_employees: "",
      number_of_female_employees: "",
      executive_summary: "",
      market_analysis: "",
      products_description: "",
      marketing_sales_strategy: "",
      financial_projections: "",
      rows: [
        { document: "PIC", key: "pic", name: "", file: null, preview: null },
        {
          document: "PAN",
          key: "doc_pan",
          name: "",
          file: null,
          preview: null,
        },
        {
          document: "Aadhaar",
          key: "doc_aadhaar",
          name: "",
          file: null,
          preview: null,
        },
        {
          document: "GST Registration",
          key: "gst_registration",
          name: "",
          file: null,
          preview: null,
        },
        {
          document: "Proof of Office Address",
          key: "proof_office_address",
          name: "",
          file: null,
          preview: null,
        },
        {
          document: "Articles of Incorporation",
          key: "articles_incorporation",
          name: "",
          file: null,
          preview: null,
        },
        {
          document: "Pitch Deck",
          key: "pitch_deck",
          name: "",
          file: null,
          preview: null,
        },
        {
          document: "DPIIT Recognised Certificate",
          key: "dpiit_recognised_certificate",
          name: "",
          file: null,
          preview: null,
        },
        {
          document: "Any other Document",
          key: "any_other_document",
          name: "",
          file: null,
          preview: null,
        },
      ],
      dic_status: "",
      dic_remarks: "",
      dic_success: "",

      hod_status: "",
      hod_remarks: "",
      techical_success: false,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {},
  });
  async function generatePDF() {
    try {
      const { data } = await getSingleRecognition(id);
    if (data?.success) {
      const {
        aadhaar,
        any_other_document,
        // business_district,
        business_parliament,
        founder_parliament,
        founder_constituency,
        business_constituency,

        application_stage,
        articles_incorporation,
        business_address,
        business_block,
        business_district,
        business_entity_type,
        business_lac,
        business_pin,
        business_state,
        business_teshil,
        category,
        co_founders,
        contact,
        dic,
        doc_aadhaar,
        doc_pan,
        dpiit_recognised_certificate,
        email,
        executive_summary,
        finalSubmit,
        financial_projections,
        founder_address,
        founder_block,
        founder_district,
        founder_lac,
        founder_name,
        founder_parliament_constituency,
        founder_pin,
        founder_state,
        founder_teshil,
        funding_details,
        funding_received,
        gst_no,
        gst_registration,
        idea,
        incorporation_number,
        incubation_centre,
        initial_investment,
        market_analysis,
        marketing_sales_strategy,
        number_of_employees,
        number_of_female_employees,
        number_of_himachali_employees,
        pan,
        pic,
        pitch_deck,
        priority_sector,
        priority_sector_radio,
        products_services_description,
        proof_office_address,
        revenue,
        sector,
        social_public,
        startup_name,
        status,
        sub_sector,
        udyam_no,
        uniqueness,
        user_id,
        women_led,
      } = data?.data
        const doc = new jsPDF();
        doc.setFontSize(14);
      doc.text("Recognition Details", 10, 10);
      doc.setFontSize(10);
      doc.text(`Name of the Founder:  ${founder_name || 'N/A'}`, 10, 30);
      doc.text(`Name of the Co-Founder:  ${co_founders || 'N/A'}`, 10, 40);
      doc.text(`Startup Name:  ${startup_name || 'N/A'}`, 10, 50);
      doc.text(`Contact:  ${contact || 'N/A'}`, 10, 60);
      doc.text(`PAN:  ${pan || 'N/A'}`, 10, 70);
      doc.text(`AADHAAR:  ${aadhaar || 'N/A'}`, 10, 80);
      doc.text(`Address of the Founder:  ${founder_address || 'N/A'}`, 10, 90);
      doc.text(
        `DPIIT Recognised Certificate:  ${dpiit_recognised_certificate || 'N/A'}`,
        10,
        100
      );
      doc.text(
        `Products Services Description:  ${products_services_description || 'N/A'}`,
        10,
        110
      );
      doc.text(`Women Led StartUp:  ${women_led || 'N/A'}`, 10, 120);
      doc.text(`Incorporation number:  ${incorporation_number || 'N/A'}`, 10, 130);
      doc.text(`Initial investment:  ${initial_investment || 'N/A'}`, 10, 140);
      doc.text(`Founder Pin:  ${founder_pin || 'N/A'}`, 10, 150);
      doc.text(`Financial Projections:  ${financial_projections || 'N/A'}`, 10, 160);
      doc.text(`Executive Summary:  ${executive_summary || 'N/A'}`, 10, 170);
      doc.text(
        `Marketing Sales Strategy:  ${marketing_sales_strategy || 'N/A'}`,
        10,
        180
      );
      doc.text(`Gst Number:  ${gst_no || 'N/A'}`, 10, 190);
      doc.text(`Email:  ${email || 'N/A'}`, 10, 200);
      doc.text(`Revenue:  ${revenue || 'N/A'}`, 10, 210);
      doc.text(`Funding Details:  ${funding_details || 'N/A'}`, 10, 220);
      doc.text(`Funding Received:  ${funding_received || 'N/A'}`, 10, 230);
      doc.text(`Number Of Employees:  ${number_of_employees || 'N/A'}`, 10, 240);
      doc.text(`Idea:  ${idea || 'N/A'}`, 10, 250);
      doc.text(`Udyam Number:  ${udyam_no || 'N/A'}`, 10, 260);
      doc.text(`Social Public:  ${social_public || 'N/A'}`, 10, 270);
      doc.text(`Uniqueness:  ${uniqueness || 'N/A'}`, 10, 280);
      
        // Save the PDF
        doc.save("Recognition.pdf");
      }
    } catch (error) {
      console.log("🚀 ~ generatePDF ~ error:", error);
    }
  }
  React.useEffect(() => {
    const UpdateApplication = async () => {
      if (id) {
        try {
          const { data } = await getSingleRecognition(id);
          if (data?.success) {
            const updatedRows = formik.values.rows.map((row) => ({
              ...row,
              preview: data?.data[row.key] || null,
            }));
            const {
              aadhaar,
              any_other_document,
              // business_district,
              business_parliament,
              founder_parliament,
              founder_constituency,
              business_constituency,

              application_stage,
              articles_incorporation,
              business_address,
              business_block,
              business_district,
              business_entity_type,
              business_lac,
              business_pin,
              business_state,
              business_teshil,
              category,
              co_founders,
              contact,
              dic,
              doc_aadhaar,
              doc_pan,
              dpiit_recognised_certificate,
              email,
              executive_summary,
              finalSubmit,
              financial_projections,
              founder_address,
              founder_block,
              founder_district,
              founder_lac,
              founder_name,
              founder_parliament_constituency,
              founder_pin,
              founder_state,
              founder_teshil,
              funding_details,
              funding_received,
              gst_no,
              gst_registration,
              idea,
              incorporation_number,
              incubation_centre,
              initial_investment,
              market_analysis,
              marketing_sales_strategy,
              number_of_employees,
              number_of_female_employees,
              number_of_himachali_employees,
              pan,
              pic,
              pitch_deck,
              priority_sector,
              priority_sector_radio,
              products_services_description,
              proof_office_address,
              revenue,
              sector,
              social_public,
              startup_name,
              status,
              sub_sector,
              udyam_no,
              uniqueness,
              user_id,
              women_led,
            } = data?.data;
            await formik.setValues((prev) => ({
              ...prev,
              women_led_startup: women_led,
              category,
              priority_sector: priority_sector_radio,
              priority_sector_drop: priority_sector,
              name_of_the_startup: startup_name,
              sector: sector,
              sub_sector,
              idea,
              social_impact: social_public,
              uniqueness,
              incubation_center: incubation_centre,
              incubationList: incubationList,
              name_of_the_founder: founder_name,
              name_of_the_cofounder: co_founders,
              contact,
              aadhaar,
              pan,
              aadhaar,
              address_of_the_founder: founder_address,
              lac: founder_lac,
              // pc: founder_parliament_constituency,
              parliment: founder_parliament,
              constituency: founder_constituency,

              state: founder_state,
              district: founder_district,
              tehsil: founder_teshil,
              block: founder_block,
              pin: founder_pin,
              bet: business_entity_type,
              lac_bp: business_lac,
              // pc_bp: business_parliament_constituency,
              business_parliment: business_parliament,
              business_constituency: business_constituency,
              state_bp: business_state,
              district_bp: business_district,
              tehsil__bp: business_teshil,
              block_bp: business_block,
              pin_bp: business_pin,
              gst_no,
              incorporation_number,
              udyam_no,
              business_address,
              initial_investment,
              revenue,
              funding_received,
              funding_details,
              number_of_employees,
              number_of_himachali_employees,
              number_of_female_employees,
              executive_summary,
              market_analysis,
              products_description: products_services_description,
              marketing_sales_strategy,
              financial_projections,
              rows: updatedRows,
            }));
            if (business_district) {
              await handleDistrictChange1(business_district);
            }
            if (business_parliament) {
              await handleParChange1(business_parliament);
            }
            if (founder_district) {
              await handleDistrictChange(founder_district);
            }
            if (founder_parliament) {
              await handleParChange(founder_parliament);
            }
            setRows([
              {
                document: "Proposal For Presentation",
                type: "Pdf/Word/Ppt",
                file: document,
              },
            ]);
          }
        } catch (error) {
          console.error("Error fetching permission data:", error);
        }
      }
    };
    UpdateApplication();
  }, [id]);
  const handleDistrictChange = async (id) => {
    formik.setFieldValue("district", id);
    const respose = await lacData(id);
    if (respose?.data?.success) {
      formik.setFieldValue("lacList", respose?.data?.data);
    }
  };
  const handleParChange = async (id) => {
    formik.setFieldValue("parliment", id);
    try {
      const response = await contiTencyData(id);
      if (response?.data?.success) {
        formik.setFieldValue("constiList", response?.data?.data);
      }
    } catch (error) {
      console.error("Error fetching constituencies:", error);
    }
  };
  const handleDistrictChange1 = async (id) => {
    formik.setFieldValue("business_district", id);
    const respose = await lacData(id);
    if (respose?.data?.success) {
      formik.setFieldValue("business_lacList", respose?.data?.data);
    }
  };
  const handleParChange1 = async (id) => {
    formik.setFieldValue("business_parliment", id);
    try {
      const response = await contiTencyData(id);
      if (response?.data?.success) {
        formik.setFieldValue("business_constiList", response?.data?.data);
      }
    } catch (error) {
      console.error("Error fetching constituencies:", error);
    }
  };


  const handlePreview = (file) => {
    const anchor = document.createElement("a");
    anchor.href = "http://10.1.5.7:5001/" + file;
    anchor.download = ""; // This will set the file name for download
    anchor.click();
  };
  const handleDicSection = async () => {
    try {
      let data = {
        id,
        payload: {
          status: formik.values.dic_status,
          remarks: formik.values.dic_remarks,
        },
      };
      const response = await actionByDic(data);
      if (response?.data?.success) {
        formik.setFieldValue("dic_success", response.data.status);
        toast.success(response.data.message, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setTimeout(() => {
          navigate("/recognition");
        }, 1000);
      } else if (response?.error) {
        toast.error(response?.error?.data?.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      console.error("Error submitting data:", error);
    } finally {
    }
  };
  const handleDirectorSection = async () => {
    try {
      let data = {
        id,
        payload: {
          status: formik.values.hod_status, //HOD === Director
          remarks: formik.values.hod_remarks,
        },
      };
      const response = await actionByDirector(data);
      if (response?.data?.success) {
        // formik.setFieldValue("dic_success", response.data.status);
        toast.success(response.data.message, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setTimeout(() => {
          navigate("/recognition");
        }, 1000);
      } else if (response?.error) {
        toast.error(response?.error?.data?.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      console.error("Error submitting data:", error);
    } finally {
    }
  };
  const hasPreview = formik.values.rows.some((row) => row.preview);
  const hasName = formik.values.rows.some((row) => row.name);

  return (
    <Box marginTop={"6em"} padding={4}>
      <ToastContainer />
      <Paper className="paper-card">
        <Grid item md={6} lg={6} xl={6} xs={12} sx={{ marginTop: 2 }}>
          <Typography
            variant="h6"
            align="start"
            sx={{ fontWeight: 600, color: "#2074af", marginTop: 2 }}
          >
            Recognition Tracking Status
          </Typography>
          <RecognitionStatus id={id} setDic_Status={setDicStatus} />
        </Grid>
        <Divider orientation={"horizontal"} sx={commonColor?.styles} />
        <Box textAlign="right">
          <Tooltip title="Download PDF" arrow>
            <IconButton sx={{ mt: 1 }} onClick={generatePDF} color="primary">
              <DownloadIcon sx={{ width: 30, height: 30 }} />
            </IconButton>
          </Tooltip>
        </Box>
        {/* <Box textAlign="right">
          <Tooltip title="Download Certificate as a PDF" arrow>
            <IconButton sx={{ mt: 1 }} onClick={generatePdf} color="primary">
              <DownloadIcon sx={{ width: 30, height: 30 }} />
            </IconButton>
          </Tooltip>
        </Box> */}
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Typography variant="h5" align="center" fontWeight="600">
            Preview Recognition
          </Typography>
          <Grid item xs={6}>
            {/* Your other components */}
            <CustomizedProgressBars val={50} />
          </Grid>
        </Grid>

        <Typography
          variant="h6"
          align="start"
          sx={{ fontWeight: 600, color: "#2074af", marginTop: 2 }}
        >
          New Applicant
        </Typography>
        <Grid container spacing={3} sx={{ marginTop: 2 }}>
          <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="start"
              sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
            >
              Women led Startup
            </Typography>
            <br />
            <FormControl fullWidth>
              <FormLabel>Women led Startup</FormLabel>
              <RadioGroup
                row
                name="women_led_startup"
                value={formik.values.women_led_startup}
                onChange={formik.handleChange}
              >
                <FormControlLabel
                  disabled
                  value="yes"
                  control={<Radio />}
                  label="Yes"
                />
                <FormControlLabel
                  disabled
                  value="no"
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
              {formik.touched.women_led_startup &&
              formik.errors.women_led_startup ? (
                <Typography color="error">
                  {formik.errors.women_led_startup}
                </Typography>
              ) : null}
            </FormControl>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <Typography
              variant="h6"
              align="start"
              sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
            >
              Select Category
            </Typography>
            <br />
            <FormControl fullWidth>
              <InputLabel id="category">Select Category</InputLabel>
              <Select
                disabled
                margin="dense"
                required
                fullWidth
                id="category"
                label="Select Category"
                name="category"
                value={formik.values.category}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.category && Boolean(formik.errors.category)
                }
              >
                {["General", "SC", "ST", "OBC", "Others"].map((item, index) => (
                  <MenuItem value={item} key={index}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
              {formik.touched.category && formik.errors.category ? (
                <Typography color="error" sx={{ fontSize: "12px" }}>
                  {formik.errors.category}
                </Typography>
              ) : null}
            </FormControl>
          </Grid>
          <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="start"
              sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
            >
              Priority Sector
            </Typography>
            <br />
            <FormControl fullWidth>
              <FormLabel>Priority Sector</FormLabel>
              <RadioGroup
                row
                name="priority_sector"
                value={formik.values.priority_sector}
                onChange={formik.handleChange}
              >
                <FormControlLabel
                  disabled
                  value="yes"
                  control={<Radio />}
                  label="Yes"
                />
                <FormControlLabel
                  disabled
                  value="no"
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
              {formik.touched.priority_sector &&
              formik.errors.priority_sector ? (
                <Typography color="error">
                  {formik.errors.priority_sector}
                </Typography>
              ) : null}
            </FormControl>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <Typography
              variant="h6"
              align="start"
              sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
            >
              Select Priority Sector
            </Typography>
            <br />
            <FormControl fullWidth>
              <InputLabel id="priority_sector_drop">
                Select Priority Sector
              </InputLabel>
              <Select
                disabled={true}
                margin="dense"
                required
                fullWidth
                id="priority_sector_drop"
                label="Select Priority Sector"
                name="priority_sector_drop"
                value={formik.values.priority_sector_drop}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.priority_sector_drop &&
                  Boolean(formik.errors.priority_sector_drop)
                }
              >
                {priorityList?.data?.map((item) => (
                  <MenuItem value={item._id} key={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
              {formik.touched.priority_sector_drop &&
              formik.errors.priority_sector_drop ? (
                <Typography color="error" sx={{ fontSize: "12px" }}>
                  {formik.errors.priority_sector_drop}
                </Typography>
              ) : null}
            </FormControl>
          </Grid>
        </Grid>
        <Grid item md={6} lg={6} xl={6} xs={12} sx={{ marginTop: 2 }}>
          <Divider orientation={"horizontal"} sx={commonColor?.styles} />

          <Typography
            variant="h6"
            align="start"
            sx={{ fontWeight: 600, color: "#2074af", marginTop: 2 }}
          >
            Startup Details
          </Typography>
        </Grid>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          mt={2}
        >
          {/* Name of the Startup */}
          <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="start"
              sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
            >
              Name of the Startup:
            </Typography>
            <FormControl fullWidth>
              <TextField
                disabled={true}
                margin="dense"
                required
                fullWidth
                id="name_of_the_startup"
                label="Name of the Startup"
                name="name_of_the_startup"
                autoComplete="Company Name"
                value={formik.values.name_of_the_startup}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.name_of_the_startup &&
                  Boolean(formik.errors.name_of_the_startup)
                }
                helperText={
                  formik.touched.name_of_the_startup &&
                  formik.errors.name_of_the_startup
                }
              />
            </FormControl>
          </Grid>

          {/* Sector */}
          <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="start"
              sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
            >
              Sector:
            </Typography>
            <br />
            <FormControl fullWidth>
              <InputLabel id="sector">Select Sector</InputLabel>
              <Select
                disabled={true}
                margin="dense"
                required
                fullWidth
                id="sector"
                label="Select Sector"
                name="sector"
                autoFocus
                value={formik.values.sector}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.sector && Boolean(formik.errors.sector)}
                helperText={formik.touched.sector && formik.errors.sector}
              >
                {sectorList?.data?.map((item) => (
                  <MenuItem value={item._id} key={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Sub Sector */}
          <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="start"
              sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
            >
              Sub Sector:
            </Typography>
            <br />
            <FormControl fullWidth>
              <InputLabel id="sub_sector">Select Sub Sector</InputLabel>
              <Select
                disabled={true}
                margin="dense"
                required
                fullWidth
                id="sub_sector"
                label="Select Sub Sector"
                name="sub_sector"
                value={formik.values.sub_sector}
                // onChange={(event)=>handleChange(event)}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.sub_sector && Boolean(formik.errors.sub_sector)
                }
                helperText={
                  formik.touched.sub_sector && formik.errors.sub_sector
                }
              >
                {subsectorList?.data?.map((item) => (
                  <MenuItem value={item._id} key={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="start"
              sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
            >
              Incubation center:
            </Typography>
            <br />
            <FormControl fullWidth>
              <InputLabel id="incubation_center">
                Select Incubation Center
              </InputLabel>
              <Select
                disabled={true}
                margin="dense"
                required
                fullWidth
                id="incubation_center"
                label="Select Incubation Center"
                name="incubation_center"
                value={formik?.values?.incubation_center}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.incubation_center &&
                  Boolean(formik.errors.incubation_center)
                }
                helperText={
                  formik.touched.incubation_center &&
                  formik.errors.incubation_center
                }
              >
                {formik?.values?.incubationList?.data?.map((item) => (
                  <MenuItem value={item._id} key={item._id}>
                    {item.host_institute_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Idea */}
          <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="righte"
              sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
            >
              Idea:
            </Typography>
            <FormControl fullWidth>
              <TextField
                disabled={true}
                margin="dense"
                required
                fullWidth
                id="idea"
                label="Idea"
                placeholder="Idea"
                name="idea"
                autoComplete="idea"
                type="textarea"
                rows={3}
                multiline
                value={formik.values.idea}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.idea && Boolean(formik.errors.idea)}
                helperText={formik.touched.idea && formik.errors.idea}
              />
            </FormControl>
          </Grid>

          {/* Social Impact */}
          <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="start"
              sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
            >
              Social Impact/ Public welfare:
            </Typography>
            <FormControl fullWidth>
              <TextField
                disabled={true}
                margin="dense"
                required
                fullWidth
                id="social_impact"
                label="Social Impact"
                placeholder="Social Impact"
                name="social_impact"
                autoComplete="off"
                type="textarea"
                rows={3}
                multiline
                value={formik.values.social_impact}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.social_impact &&
                  Boolean(formik.errors.social_impact)
                }
                helperText={
                  formik.touched.social_impact && formik.errors.social_impact
                }
              />
            </FormControl>
          </Grid>

          {/* Uniqueness */}
          <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="start"
              sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
            >
              Uniqueness:
            </Typography>
            <FormControl fullWidth>
              <TextField
                disabled={true}
                margin="dense"
                required
                fullWidth
                id="uniqueness"
                label="Uniqueness"
                placeholder="Uniqueness"
                name="uniqueness"
                autoComplete="off"
                type="textarea"
                rows={3}
                multiline
                value={formik.values.uniqueness}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.uniqueness && Boolean(formik.errors.uniqueness)
                }
                helperText={
                  formik.touched.uniqueness && formik.errors.uniqueness
                }
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid item md={6} lg={6} xl={6} xs={12} sx={{ marginTop: 2 }}>
          <Divider orientation={"horizontal"} sx={commonColor?.styles} />

          <Typography
            variant="h6"
            align="left"
            sx={{ fontWeight: 600, color: "#2074af", marginTop: 2 }}
          >
            Founder Details
          </Typography>
        </Grid>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          mt={2}
        >
          <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
            >
              Name of the Founder:
            </Typography>
            <FormControl fullWidth>
              <TextField
                disabled={true}
                margin="dense"
                required
                fullWidth
                id="name_of_the_founder"
                label="Name of the Startup"
                name="name_of_the_founder"
                autoComplete="Company Name"
                value={formik.values.name_of_the_founder}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.name_of_the_founder &&
                  Boolean(formik.errors.name_of_the_founder)
                }
                helperText={
                  formik.touched.name_of_the_founder &&
                  formik.errors.name_of_the_founder
                }
              />
            </FormControl>
          </Grid>
          <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
            >
              Name of the Co-Founder:
            </Typography>
            <FormControl fullWidth>
              <TextField
                disabled={true}
                margin="dense"
                required
                fullWidth
                id="name_of_the_cofounder"
                label="Name of the Co Founder"
                name="name_of_the_cofounder"
                autoComplete="off"
                value={formik.values.name_of_the_cofounder}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.name_of_the_cofounder &&
                  Boolean(formik.errors.name_of_the_cofounder)
                }
                helperText={
                  formik.touched.name_of_the_cofounder &&
                  formik.errors.name_of_the_cofounder
                }
              />
            </FormControl>
          </Grid>
          <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
            >
              Contact:
            </Typography>
            <TextField
              disabled={true}
              margin="dense"
              fullWidth
              required
              id={`contact`}
              label="Contact"
              name={`contact`}
              placeholder="Contact"
              autoComplete={"off"}
              value={formik.values?.contact}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.contact && Boolean(formik.errors.contact)}
              helperText={formik.touched.contact && formik.errors.contact}
            />
          </Grid>
          <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
            >
              PAN:
            </Typography>
            <TextField
              disabled={true}
              margin="dense"
              fullWidth
              required
              id={`pan`}
              label="PAN"
              name={`pan`}
              placeholder="PAN"
              autoComplete={"off"}
              value={formik.values?.pan}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.pan && Boolean(formik.errors.pan)}
              helperText={formik.touched.pan && formik.errors.pan}
            />
          </Grid>
          <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
            >
              AADHAAR:
            </Typography>
            <TextField
              disabled={true}
              margin="dense"
              fullWidth
              required
              id={`aadhaar`}
              label="Aadhaar"
              name={`aadhaar`}
              placeholder="Aadhaar"
              autoComplete={"off"}
              value={formik.values?.aadhaar}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.aadhaar && Boolean(formik.errors.aadhaar)}
              helperText={formik.touched.aadhaar && formik.errors.aadhaar}
            />
          </Grid>
          <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
            >
              Address of the Founder:
            </Typography>
            <FormControl fullWidth>
              <TextField
                disabled={true}
                margin="dense"
                required
                fullWidth
                id="address_of_the_founder"
                label="Address of the Founder"
                name="address_of_the_founder"
                autoComplete="off"
                type="textarea"
                // rows={2}
                multiline
                value={formik.values.address_of_the_founder}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.address_of_the_founder &&
                  Boolean(formik.errors.address_of_the_founder)
                }
                helperText={
                  formik.touched.address_of_the_founder &&
                  formik.errors.address_of_the_founder
                }
              />
            </FormControl>
          </Grid>
          <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
            >
              State/ UT:
            </Typography>
            <br />
            <FormControl fullWidth>
              <InputLabel id="pc">State/ UT</InputLabel>
              <Select
                disabled={true}
                margin="dense"
                required
                fullWidth
                id="state"
                label="Parliament Constituency"
                placeholder="Parliament Constituency"
                name="state"
                autoFocus
                value={formik.values.state}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.state && Boolean(formik.errors.state)}
                helperText={formik.touched.state && formik.errors.state}
                autoComplete="Register type"
                // onChange={(e) => handleChange(e)}
              >
                {stateList?.data?.map((item) => (
                  <MenuItem value={item._id} key={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: "18px" }}
            >
              District:
            </Typography>
            <br />
            <FormControl fullWidth>
              <InputLabel id="district">District</InputLabel>
              <Select
                disabled
                margin="dense"
                fullWidth
                id="district"
                label="District"
                name="district"
                value={formik.values.district}
                onChange={(e) => handleDistrictChange(e.target.value)}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.district && Boolean(formik.errors.district)
                }
                helperText={formik.touched.district && formik.errors.district}
              >
                {districtList?.data?.map((item) => (
                  <MenuItem value={item._id} key={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: "18px" }}
            >
              Legislative Assembly Constituency:
            </Typography>
            <br />
            <FormControl fullWidth>
              <InputLabel id="lac">
                Legislative Assembly Constituency
              </InputLabel>
              <Select
                disabled
                margin="dense"
                fullWidth
                id="lac"
                label="Select Priority Sector"
                placeholder="Select Priority Sector"
                name="lac"
                autoFocus
                value={formik.values.lac}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.lac && Boolean(formik.errors.lac)}
                helperText={formik.touched.lac && formik.errors.lac}
                autoComplete="Register type"
              >
                {formik?.values?.lacList?.map((item) => (
                  <MenuItem value={item._id} key={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: "18px" }}
            >
              Parliament Constituency:
            </Typography>
            <br />
            <FormControl fullWidth>
              <InputLabel id="parliment">Parliament </InputLabel>
              <Select
                disabled
                margin="dense"
                fullWidth
                id="parliment"
                label="Parliament"
                placeholder="Parliament"
                name="parliment"
                autoFocus
                value={formik.values.parliment}
                onChange={(e) => {
                  handleParChange(e.target.value);
                }}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.parliment && Boolean(formik.errors.parliment)
                }
                helperText={formik.touched.parliment && formik.errors.parliment}
                autoComplete="Register type"
                // onChange={(e) => handleChange(e)}
              >
                {pcList?.data?.map((item) => (
                  <MenuItem value={item._id} key={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {/* <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
            variant="h6"
            align="left"
            sx={{ fontWeight: 400, fontSize: "18px" }}
            >
            Constituency:
            </Typography>
            <br />
            <FormControl fullWidth>
            <InputLabel id="constituency">Constituency</InputLabel>
            <Select
            disabled
            margin="dense"
            fullWidth
            id="constituency"
            label="Constituency"
            name="constituency"
            value={formik.values.constituency}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.constituency &&
              Boolean(formik.errors.constituency)
              }
              helperText={
                formik.touched.constituency && formik.errors.constituency
                }
                >
                {formik.values.constiList?.map((item) => (
                  <MenuItem value={item._id} key={item._id}>
                  {item.name}
                  </MenuItem>
                  ))}
                  </Select>
                  </FormControl>
                  </Grid> */}

          <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
            >
              Tehsil:
            </Typography>
            <br />
            <FormControl fullWidth>
              <InputLabel id="pc">Tehsil</InputLabel>
              <Select
                disabled={true}
                margin="dense"
                fullWidth
                id="tehsil"
                label="tehsil"
                placeholder="Tehsil"
                name="tehsil"
                autoFocus
                value={formik.values.tehsil}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.tehsil && Boolean(formik.errors.tehsil)}
                helperText={formik.touched.tehsil && formik.errors.tehsil}
                autoComplete="Register type"
                // onChange={(e) => handleChange(e)}
              >
                {tehsilList?.data?.map((item) => (
                  <MenuItem value={item._id} key={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
            >
              Block:
            </Typography>
            <br />
            <FormControl fullWidth>
              <InputLabel id="pc">Block</InputLabel>
              <Select
                disabled={true}
                margin="dense"
                fullWidth
                id="block"
                label="Parliament Constituency"
                placeholder="Parliament Constituency"
                name="block"
                autoFocus
                value={formik.values.block}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.block && Boolean(formik.errors.block)}
                helperText={formik.touched.block && formik.errors.block}
                autoComplete="Register type"
                // onChange={(e) => handleChange(e)}
              >
                {blockList?.data?.map((item) => (
                  <MenuItem value={item._id} key={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
            >
              Tehsil:
            </Typography>
            <br />
            <FormControl fullWidth>
              <InputLabel id="pc">Tehsil</InputLabel>
              <Select
                disabled={true}
                margin="dense"
                required
                fullWidth
                id="tehsil"
                label="Parliament Constituency"
                placeholder="Parliament Constituency"
                name="tehsil"
                autoFocus
                value={formik.values.tehsil}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.tehsil && Boolean(formik.errors.tehsil)}
                helperText={formik.touched.tehsil && formik.errors.tehsil}
                autoComplete="Register type"
                // onChange={(e) => handleChange(e)}
              >
                {tehsilList?.data?.map((item) => (
                  <MenuItem value={item._id} key={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
            >
              Block:
            </Typography>
            <br />
            <FormControl fullWidth>
              <InputLabel id="pc">Block</InputLabel>
              <Select
                disabled={true}
                margin="dense"
                required
                fullWidth
                id="block"
                label="Parliament Constituency"
                placeholder="Parliament Constituency"
                name="block"
                autoFocus
                value={formik.values.block}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.block && Boolean(formik.errors.block)}
                helperText={formik.touched.block && formik.errors.block}
                autoComplete="Register type"
                // onChange={(e) => handleChange(e)}
              >
                {blockList?.data?.map((item) => (
                  <MenuItem value={item._id} key={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid> */}
          <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
            >
              PIN:
            </Typography>
            <TextField
              disabled={true}
              margin="dense"
              fullWidth
              required
              id={`pin`}
              label="PIN"
              name={`pin`}
              placeholder="PIN"
              autoComplete={"off"}
              value={formik.values?.pin}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.pin && Boolean(formik.errors.pin)}
              helperText={formik.touched.pin && formik.errors.pin}
            />
          </Grid>

          {/* Table for Documents */}
          {/* <Grid item md={12} lg={12} xl={12} xs={12}>
          <Typography variant="h6" align="left" sx={{ fontWeight: 500 }}>
            Documents:
          </Typography>
          <TableContainer component={Paper} fullWidth>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Document</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>File</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.document}</TableCell>
                    <TableCell>{row.type}</TableCell>
                    <TableCell>
                      {row.file && (
                        <IconButton onClick={() => handlePreview(row.file)}>
                          <VisibilityIcon />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid> */}
        </Grid>

        {/* Form Actions */}
        <Grid item md={6} lg={6} xl={6} xs={12} sx={{ marginTop: 2 }}>
          <Divider orientation={"horizontal"} sx={commonColor?.styles} />

          <Typography
            variant="h6"
            align="start"
            sx={{ fontWeight: 600, color: "#2074af", marginTop: 2 }}
          >
            Business Details
          </Typography>
        </Grid>

        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          mt={2}
        >
          <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="start"
              sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
            >
              Business Entity Type :
            </Typography>
            <br />
            <FormControl fullWidth>
              <InputLabel id="bet">Business Entity Type</InputLabel>
              <Select
                disabled={true}
                margin="dense"
                required
                fullWidth
                id="bet"
                label="Select Priority Sector"
                placeholder="Select Priority Sector"
                name="bet"
                autoFocus
                value={formik.values.bet}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.bet && Boolean(formik.errors.bet)}
                helperText={formik.touched.bet && formik.errors.bet}
                autoComplete="off"
                // onChange={(e) => handleChange(e)}
              >
                {betList?.data?.map((item) => (
                  <MenuItem value={item._id} key={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="start"
              sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
            >
              State/ UT:
            </Typography>
            <br />
            <FormControl fullWidth>
              <InputLabel id="state_bp">State/ UT</InputLabel>
              <Select
                disabled={true}
                margin="dense"
                required
                fullWidth
                id="state_bp"
                label="State"
                placeholder="State"
                name="state_bp"
                autoFocus
                value={formik.values.state_bp}
                onChange={formik.handleChange}
                // onBlur={formik.handleBlur}
                // error={formik.touched.state && Boolean(formik.errors.state)}
                // helperText={formik.touched.state && formik.errors.state}
                autoComplete="off"
                // onChange={(e) => handleChange(e)}
              >
                {stateList?.data?.map((item) => (
                  <MenuItem value={item._id} key={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="start"
              sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
            >
              District:
            </Typography>
            <br />
            <FormControl fullWidth>
              <InputLabel id="district_bp">District</InputLabel>
              <Select
                disabled={true}
                margin="dense"
                required
                fullWidth
                id="district_bp"
                label="District_bp"
                placeholder="District_bp"
                name="district_bp"
                autoFocus
                value={formik.values.district_bp}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                // error={
                //   formik.touched.district_bp && Boolean(formik.errors.district_bp)
                // }
                // helperText={formik.touched.district_bp && formik.errors.district_bp}
                autoComplete="off"
                // onChange={(e) => handleChange(e)}
              >
                {districtList?.data?.map((item) => (
                  <MenuItem value={item._id} key={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="start"
              sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
            >
              Legislative Assembly Constituency:
            </Typography>
            <br />
            <FormControl fullWidth>
              <InputLabel id="lac_bp">
                Legislative Assembly Constituency
              </InputLabel>
              <Select
                disabled={true}
                margin="dense"
                fullWidth
                id="lac_bp"
                label="Select Priority Sector"
                plac_bpeholder="Select Priority Sector"
                name="lac_bp"
                autoFocus
                value={formik.values.lac_bp}
                onChange={formik.handleChange}
                // onBlur={formik.handleBlur}
                // error={formik.touched.lac && Boolean(formik.errors.lac)}
                // helperText={formik.touched.lac && formik.errors.lac}
                autoComplete="off"
                // onChange={(e) => handleChange(e)}
              >
                {lacList?.data?.map((item) => (
                  <MenuItem value={item._id} key={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {/* Inspiration Behind Idea */}

          <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="start"
              sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
            >
              Tehsil:
            </Typography>
            <br />
            <FormControl fullWidth>
              <InputLabel id="tehsil_bp">Tehsil</InputLabel>
              <Select
                disabled={true}
                margin="dense"
                fullWidth
                id="tehsil_bp"
                label="tehsil_bp"
                placeholder="Tehsil_bp"
                name="tehsil_bp"
                autoFocus
                value={formik.values.tehsil_bp}
                onChange={formik.handleChange}
                // onBlur={formik.handleBlur}
                // error={formik.touched.tehsil && Boolean(formik.errors.tehsil)}
                // helperText={formik.touched.tehsil && formik.errors.tehsil}
                autoComplete="off"
                // onChange={(e) => handleChange(e)}
              >
                {tehsilList?.data?.map((item) => (
                  <MenuItem value={item._id} key={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="start"
              sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
            >
              Block:
            </Typography>
            <br />
            <FormControl fullWidth>
              <InputLabel id="block_bp">Block</InputLabel>
              <Select
                disabled={true}
                margin="dense"
                fullWidth
                id="block_bp"
                label="Parliament Constituency"
                placeholder="Parliament Constituency"
                name="block_bp"
                autoFocus
                value={formik.values.block_bp}
                onChange={formik.handleChange}
                // onBlur={formik.handleBlur}
                // error={formik.touched.block_bp && Boolean(formik.errors.block_bp)}
                // helperText={formik.touched.block && formik.errors.block}
                autoComplete="off"
                // onChange={(e) => handleChange(e)}
              >
                {blockList?.data?.map((item) => (
                  <MenuItem value={item._id} key={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: "18px" }}
            >
              Parliament Constituency:
            </Typography>
            <br />
            <FormControl fullWidth>
              <InputLabel id="parliment">Parliament </InputLabel>
              <Select
                disabled
                margin="dense"
                fullWidth
                id="business_parliment"
                label="Parliament"
                placeholder="Parliament"
                name="business_parliment"
                autoFocus
                value={formik.values.business_parliment}
                onChange={(e) => {
                  handleParChange(e.target.value);
                }}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.business_parliment &&
                  Boolean(formik.errors.business_parliment)
                }
                helperText={
                  formik.touched.business_parliment &&
                  formik.errors.business_parliment
                }
                autoComplete="Register type"
                // onChange={(e) => handleChange(e)}
              >
                {pcList?.data?.map((item) => (
                  <MenuItem value={item._id} key={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={6} lg={6} xl={6} xs={12} mt={1}>
            <Typography
              variant="h6"
              align="start"
              sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
            >
              Udyam Number :
            </Typography>
            <TextField
              disabled={true}
              margin="dense"
              fullWidth
              required
              id={`udyam_no`}
              label="Udyam Number"
              name={`udyam_no`}
              placeholder="Udyam Number"
              autoComplete={"off"}
              value={formik.values?.udyam_no}
              onChange={formik.handleChange}
              // onBlur={formik.handleBlur}
              // error={formik.touched.udyam_no && Boolean(formik.errors.udyam_no)}
              // helperText={formik.touched.udyam_no && formik.errors.udyam_no}
            />
          </Grid>

          {/* Inspiration Behind Idea */}
          <Grid item md={12} lg={12} xl={12} xs={12}>
            <Typography
              variant="h6"
              align="start"
              sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
            >
              Business Address (Registered Office):
            </Typography>
            <FormControl fullWidth>
              <TextField
                disabled={true}
                margin="dense"
                required
                fullWidth
                id="business_address"
                label="Business Address"
                name="business_address"
                autoComplete="Inspiration Behind Idea"
                type="textarea"
                rows={2}
                multiline
                value={formik.values.business_address}
                onChange={formik.handleChange}
                // onBlur={formik.handleBlur}
                // error={
                //   formik.touched.business_address &&
                //   Boolean(formik.errors.business_address)
                // }
                // helperText={
                //   formik.touched.business_address &&
                //   formik.errors.business_address
                // }
              />
            </FormControl>
          </Grid>
          {/* <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="start"
              sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
            >
              Legislative Assembly Constituency:
            </Typography>
            <br />
            <FormControl fullWidth>
              <InputLabel id="lac_bp">
                Legislative Assembly Constituency
              </InputLabel>
              <Select
                disabled={true}
                margin="dense"
                required
                fullWidth
                id="lac_bp"
                label="Select Priority Sector"
                plac_bpeholder="Select Priority Sector"
                name="lac_bp"
                autoFocus
                value={formik.values.lac_bp}
                onChange={formik.handleChange}
                // onBlur={formik.handleBlur}
                // error={formik.touched.lac && Boolean(formik.errors.lac)}
                // helperText={formik.touched.lac && formik.errors.lac}
                autoComplete="off"
                // onChange={(e) => handleChange(e)}
              >
                {lacList?.data?.map((item) => (
                  <MenuItem value={item._id} key={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="start"
              sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
            >
              Parliament Constituency:
            </Typography>
            <br />
            <FormControl fullWidth>
              <InputLabel id="pc_bp">Parliament Constituency</InputLabel>
              <Select
                disabled={true}
                margin="dense"
                required
                fullWidth
                id="pc_bp"
                label="Parliament Constituency"
                placeholder="Parliament Constituency"
                name="pc_bp"
                autoFocus
                value={formik.values.pc_bp}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                // error={formik.touched.pc && Boolean(formik.errors.pc)}
                // helperText={formik.touched.pc && formik.errors.pc}
                autoComplete="off"
                // onChange={(e) => handleChange(e)}
              >
                {pcList?.data?.map((item) => (
                  <MenuItem value={item._id} key={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid> */}

          <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="start"
              sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
            >
              PIN:
            </Typography>
            <TextField
              disabled={true}
              margin="dense"
              fullWidth
              required
              id={`pin_bp`}
              label="PIN"
              name={`pin`}
              placeholder="PIN_bp"
              autoComplete={"off"}
              value={formik.values?.pin_bp}
              onChange={formik.handleChange}
              // onBlur={formik.handleBlur}
              // error={formik.touched.pin && Boolean(formik.errors.pin)}
              // helperText={formik.touched.pin && formik.errors.pin}
            />
          </Grid>
          <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="start"
              sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
            >
              GST Number:
            </Typography>
            <TextField
              disabled={true}
              margin="dense"
              fullWidth
              required
              id={`gst_no`}
              label="GST Number"
              name={`gst_no`}
              placeholder="GST Number"
              autoComplete={"off"}
              value={formik.values?.gst_no}
              onChange={formik.handleChange}
              // onBlur={formik.handleBlur}
              // error={formik.touched.gst_no && Boolean(formik.errors.gst_no)}
              // helperText={formik.touched.gst_no && formik.errors.gst_no}
            />
          </Grid>
          <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="start"
              sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
            >
              Incorporation Number:
            </Typography>
            <TextField
              disabled={true}
              margin="dense"
              fullWidth
              required
              id={`incorporation_number`}
              label="Incorporation Number"
              name={`incorporation_number`}
              placeholder="Incorporation Number"
              autoComplete={"off"}
              value={formik.values?.incorporation_number}
              onChange={formik.handleChange}
              // onBlur={formik.handleBlur}
              // error={
              //   formik.touched.incorporation_number &&
              //   Boolean(formik.errors.incorporation_number)
              // }
              // helperText={
              //   formik.touched.incorporation_number &&
              //   formik.errors.incorporation_number
              // }
            />
          </Grid>
        </Grid>

        <Grid item md={6} lg={6} xl={6} xs={12} sx={{ marginTop: 2 }}>
          <Divider orientation={"horizontal"} sx={commonColor?.styles} />

          <Typography
            variant="h6"
            align="start"
            sx={{ fontWeight: 600, color: "#2074af", marginTop: 2 }}
          >
            Financial Information
          </Typography>
        </Grid>

        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          mt={2}
        >
          {/* <Grid item md={6} lg={6} xl={6} xs={12}>
          <Typography
            variant="h6"
            align="start"
            sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
          >
            Business Entity Type :
          </Typography>
          <br />
          <FormControl fullWidth>
            <InputLabel id="bet">Business Entity Type</InputLabel>
            <Select
             disabled={true}
              margin="dense"
              required
              fullWidth
              id="bet"
              label="Select Priority Sector"
              placeholder="Select Priority Sector"
              name="bet"
              autoFocus
              value={formik.values.bet}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.bet && Boolean(formik.errors.bet)}
              helperText={formik.touched.bet && formik.errors.bet}
              autoComplete="off"
              // onChange={(e) => handleChange(e)}
            >
              {betList?.data?.map((item) => (
                <MenuItem value={item._id} key={item._id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={6} lg={6} xl={6} xs={12}>
          <Typography
            variant="h6"
            align="start"
            sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
          >
            District:
          </Typography>
          <br />
          <FormControl fullWidth>
            <InputLabel id="pc">District</InputLabel>
            <Select
             disabled={true}
              margin="dense"
              required
              fullWidth
              id="district"
              label="District"
              placeholder="District"
              name="district"
              autoFocus
              value={formik.values.district}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              // error={
              //   formik.touched.district && Boolean(formik.errors.district)
              // }
              // helperText={formik.touched.district && formik.errors.district}
              autoComplete="off"
              // onChange={(e) => handleChange(e)}
            >
              {districtList?.data?.map((item) => (
                <MenuItem value={item._id} key={item._id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid> */}
          <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="start"
              sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
            >
              Initial Investment :
            </Typography>
            <FormControl fullWidth>
              <TextField
                disabled={true}
                margin="dense"
                required
                fullWidth
                id="initial_investment"
                label="Initial Investment"
                placeholder="Initial Investment"
                name="initial_investment"
                autoComplete="Company Name"
                value={formik.values.initial_investment}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.initial_investment &&
                  Boolean(formik.errors.initial_investment)
                }
                helperText={
                  formik.touched.initial_investment &&
                  formik.errors.initial_investment
                }
              />
            </FormControl>
          </Grid>
          <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="start"
              sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
            >
              Revenue :
            </Typography>
            <FormControl fullWidth>
              <TextField
                disabled={true}
                margin="dense"
                required
                fullWidth
                id="revenue"
                label="Revenue"
                name="revenue"
                autoComplete="Company Name"
                value={formik.values.revenue}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.revenue && Boolean(formik.errors.revenue)}
                helperText={formik.touched.revenue && formik.errors.revenue}
              />
            </FormControl>
          </Grid>
          <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="start"
              sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
            >
              Funding Received :
            </Typography>
            <FormControl fullWidth>
              <TextField
                disabled={true}
                margin="dense"
                required
                fullWidth
                id="funding_received"
                label="Funding Received"
                placeholder="Social Impact"
                name="funding_received"
                autoComplete="off"
                type="textarea"
                rows={3}
                multiline
                value={formik.values.funding_received}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.funding_received &&
                  Boolean(formik.errors.funding_received)
                }
                helperText={
                  formik.touched.funding_received &&
                  formik.errors.funding_received
                }
              />
            </FormControl>
          </Grid>
          <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="start"
              sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
            >
              Details of Funding:
            </Typography>
            <FormControl fullWidth>
              <TextField
                disabled={true}
                margin="dense"
                required
                fullWidth
                id="funding_details"
                label="Details of Funding"
                placeholder="Details of Funding"
                name="funding_details"
                autoComplete="funding_details"
                type="textarea"
                rows={3}
                multiline
                value={formik.values.funding_details}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.funding_details &&
                  Boolean(formik.errors.funding_details)
                }
                helperText={
                  formik.touched.funding_details &&
                  formik.errors.funding_details
                }
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid item md={6} lg={6} xl={6} xs={12} sx={{ marginTop: 2 }}>
          <Divider orientation={"horizontal"} sx={commonColor?.styles} />

          <Typography
            variant="h6"
            align="start"
            sx={{ fontWeight: 600, color: "#2074af", marginTop: 2 }}
          >
            Employement Information
          </Typography>
        </Grid>

        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          mt={2}
        >
          <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="start"
              sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
            >
              Number of Employees :
            </Typography>
            <FormControl fullWidth>
              <TextField
                disabled={true}
                margin="dense"
                type="number"
                inputProps={{ min: 0 }}
                required
                fullWidth
                id="number_of_employees"
                label="Number of Employees  "
                placeholder="Number of Employees  "
                name="number_of_employees"
                autoComplete="off"
                value={formik.values.number_of_employees}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.number_of_employees &&
                  Boolean(formik.errors.number_of_employees)
                }
                helperText={
                  formik.touched.number_of_employees &&
                  formik.errors.number_of_employees
                }
              />
            </FormControl>
          </Grid>
          <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="start"
              sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
            >
              Number of Himachali employees :
            </Typography>
            <FormControl fullWidth>
              <TextField
                disabled={true}
                margin="dense"
                type="number"
                inputProps={{ min: 0 }}
                required
                fullWidth
                id="number_of_himachali_employees"
                label="Number of Himachali Employees"
                placeholder="Number of Himachali Employees"
                name="number_of_himachali_employees"
                autoComplete="off"
                value={formik.values.number_of_himachali_employees}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.number_of_himachali_employees &&
                  Boolean(formik.errors.number_of_himachali_employees)
                }
                helperText={
                  formik.touched.number_of_himachali_employees &&
                  formik.errors.number_of_himachali_employees
                }
              />
            </FormControl>
          </Grid>
          <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="start"
              sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
            >
              Number of Female Employees :
            </Typography>
            <FormControl fullWidth>
              <TextField
                disabled={true}
                margin="dense"
                type="number"
                inputProps={{ min: 0 }}
                required
                fullWidth
                id="number_of_female_employees"
                label="Number of Female Employees"
                placeholder="Number of Female Employees"
                name="number_of_female_employees"
                autoComplete="off"
                value={formik.values.number_of_female_employees}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.number_of_female_employees &&
                  Boolean(formik.errors.number_of_female_employees)
                }
                helperText={
                  formik.touched.number_of_female_employees &&
                  formik.errors.number_of_female_employees
                }
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid item md={6} lg={6} xl={6} xs={12} sx={{ marginTop: 2 }}>
          <Divider orientation={"horizontal"} sx={commonColor?.styles} />

          <Typography
            variant="h6"
            align="start"
            sx={{ fontWeight: 600, color: "#2074af", marginTop: 2 }}
          >
            Business Plan
          </Typography>
        </Grid>

        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          mt={2}
        >
          {/* Company Name */}
          <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="start"
              sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
            >
              Executive Summary
            </Typography>
            <FormControl fullWidth>
              <TextField
                disabled={true}
                margin="dense"
                required
                fullWidth
                type="textarea"
                rows={2}
                multiline
                id="executive_summary"
                label="Executive Summary"
                placeholder="Executive Summary"
                name="executive_summary"
                autoComplete="off"
                value={formik.values.executive_summary}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.executive_summary &&
                  Boolean(formik.errors.executive_summary)
                }
                helperText={
                  formik.touched.executive_summary &&
                  formik.errors.executive_summary
                }
              />
            </FormControl>
          </Grid>
          <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="start"
              sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
            >
              Market Analysis:
            </Typography>
            <FormControl fullWidth>
              <TextField
                disabled={true}
                margin="dense"
                required
                fullWidth
                id="market_analysis"
                label=" Market Analysis"
                placeholder="Market Analysis"
                name="market_analysis"
                autoComplete="off"
                value={formik.values.market_analysis}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.market_analysis &&
                  Boolean(formik.errors.market_analysis)
                }
                helperText={
                  formik.touched.market_analysis &&
                  formik.errors.market_analysis
                }
              />
            </FormControl>
          </Grid>
          <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="start"
              sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
            >
              Products/Services Description:
            </Typography>
            <FormControl fullWidth>
              <TextField
                disabled={true}
                margin="dense"
                required
                fullWidth
                id="products_description"
                label=" Products/Services Description"
                placeholder=" Products/Services Description"
                name="products_description"
                autoComplete="off"
                // type="textarea"
                // rows={3}
                // multiline
                value={formik.values.products_description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.products_description &&
                  Boolean(formik.errors.products_description)
                }
                helperText={
                  formik.touched.products_description &&
                  formik.errors.products_description
                }
              />
            </FormControl>
          </Grid>
          <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="start"
              sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
            >
              Marketing and Sales Strategy:
            </Typography>
            <FormControl fullWidth>
              <TextField
                disabled={true}
                margin="dense"
                required
                fullWidth
                id="details_of_funding"
                label="Marketing and Sales Strategy"
                placeholder="Marketing and Sales Strategy"
                name="marketing_sales_strategy"
                autoComplete="off"
                // type="textarea"
                // rows={3}
                // multiline
                value={formik.values.marketing_sales_strategy}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.marketing_sales_strategy &&
                  Boolean(formik.errors.marketing_sales_strategy)
                }
                helperText={
                  formik.touched.marketing_sales_strategy &&
                  formik.errors.marketing_sales_strategy
                }
              />
            </FormControl>
          </Grid>
          <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="start"
              sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
            >
              Financial Projections:
            </Typography>
            <FormControl fullWidth>
              <TextField
                disabled={true}
                margin="dense"
                required
                fullWidth
                id="financial_projections"
                label=" Financial Projections"
                placeholder="Financial Projections"
                name="financial_projections"
                autoComplete="off"
                // type="textarea"
                // rows={3}
                // multiline
                value={formik.values.financial_projections}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.financial_projections &&
                  Boolean(formik.errors.financial_projections)
                }
                helperText={
                  formik.touched.financial_projections &&
                  formik.errors.financial_projections
                }
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid item md={6} lg={6} xl={6} xs={12} sx={{ marginTop: 2 }}>
          <Divider orientation={"horizontal"} sx={commonColor?.styles} />

          <Typography
            variant="h6"
            align="start"
            sx={{ fontWeight: 600, color: "#2074af", marginTop: 2 }}
          >
            Document Uploads
          </Typography>
        </Grid>

        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          mt={2}
        >
          <Grid item md={12} lg={12} xl={12} xs={12}>
            <Typography
              variant="h6"
              align="start"
              sx={{ fontWeight: 500, fontSize: `${fontSize}px` }}
            >
              Documents:
            </Typography>
            <TableContainer component={Paper} fullWidth>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Document</TableCell>
                    {/* <TableCell>File</TableCell> */}
                    {hasName ? <TableCell>Name</TableCell> : null}
                    {hasPreview ? <TableCell>Preview</TableCell> : null}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {formik.values.rows?.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{row.document}</TableCell>
                      {/* <TableCell>
                      <CloudUploadIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => handleIconClick(index)}
                      />
                      <input
                        type="file"
                        accept=".pdf"
                        style={{ display: "none" }}
                        ref={(el) => (fileInputRef.current[index] = el)}
                        onChange={(event) => handleFileUpload(index, event)}
                      />
                    </TableCell> */}
                      {/* <TableCell>{row.name}</TableCell> */}

                      <TableCell>
                        {row.preview && (
                          <Link
                            to={row.preview}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <VisibilityIcon />
                          </Link>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        {role === "dic" || role === "superadmin" ? (
          <>
            <Grid item md={6} lg={6} xl={6} xs={12} sx={{ marginTop: 2 }}>
              <Divider orientation={"horizontal"} sx={commonColor?.styles} />

              <Typography
                variant="h6"
                align="start"
                sx={{ fontWeight: 600, color: "#2074af", marginTop: 2 }}
              >
                GM/DIC Section
              </Typography>
            </Grid>

            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              mt={2}
            >
              <Grid item md={6} lg={6} xl={6} xs={12}>
                <Typography
                  variant="h6"
                  align="start"
                  sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
                >
                  Status:
                </Typography>
                <FormControl fullWidth sx={{ marginTop: 2 }}>
                  <InputLabel id="Select Status">Status</InputLabel>
                  <Select
                    labelId="Select Status"
                    required
                    fullWidth
                    id="dic_status"
                    label="Select Status"
                    name="dic_status"
                    autoComplete="off"
                    placeholder="Status"
                    value={formik.values.dic_status}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.dic_status &&
                      Boolean(formik.errors.dic_status)
                    }
                    helperText={
                      formik.touched.dic_status && formik.errors.dic_status
                    }
                  >
                    <MenuItem value="">--Select Status--</MenuItem>
                    <MenuItem value="reverted">Revert</MenuItem>
                    <MenuItem value="accepted">Forward</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={6} lg={6} xl={6} xs={12} mt={1}>
                <Typography
                  variant="h6"
                  align="start"
                  sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
                >
                  Remarks:
                </Typography>
                <TextField
                  label="Remarks"
                  required
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  name="dic_remarks"
                  id="dic_remarks"
                  value={formik.values.dic_remarks}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.dic_remarks &&
                    Boolean(formik.errors.dic_remarks)
                  }
                  helperText={
                    formik.touched.dic_remarks && formik.errors.dic_remarks
                  }
                />
              </Grid>
            </Grid>
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className="button-right"
            >
              <Button
                type="button"
                variant="contained"
                onClick={() => {
                  navigate("/recognition");
                }}
                // disabled={active === 0}
              >
                Previous
              </Button>
              <Box ml={2} display="inline-block">
                <LoadingButton
                  variant="contained"
                  onClick={() => {
                    handleDicSection();
                  }}
                  // disabled={formik.values.nodal_success==="approved"?true:false}
                  endIcon={<SendIcon />}
                  // loading={loading}
                  loadingPosition="end"
                  type="submit"
                >
                  <span>Submit</span>
                </LoadingButton>
              </Box>
            </Grid>
          </>
        ) : null}
        {(role === "hod" || role === "superadmin" || role === "admin") &&
        dicStatus == "accepted" ? (
          <>
            <Grid item md={6} lg={6} xl={6} xs={12} sx={{ marginTop: 2 }}>
              <Divider orientation={"horizontal"} sx={commonColor?.styles} />

              <Typography
                variant="h6"
                align="start"
                sx={{ fontWeight: 600, color: "#2074af", marginTop: 2 }}
              >
                HOD/Directorate Section
              </Typography>
            </Grid>

            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              mt={2}
            >
              <Grid item md={6} lg={6} xl={6} xs={12}>
                <Typography
                  variant="h6"
                  align="start"
                  sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
                >
                  Status:
                </Typography>
                <FormControl fullWidth sx={{ marginTop: 2 }}>
                  <InputLabel id="Select Status">Status</InputLabel>
                  <Select
                    labelId="Select Status"
                    required
                    fullWidth
                    id="hod_status"
                    label="Select Status"
                    name="hod_status"
                    autoComplete="off"
                    placeholder="Status"
                    value={formik.values.hod_status}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.hod_status &&
                      Boolean(formik.errors.hod_status)
                    }
                    helperText={
                      formik.touched.hod_status && formik.errors.hod_status
                    }
                  >
                    <MenuItem value="">--Select Status--</MenuItem>
                    <MenuItem value="rejected">Reject</MenuItem>
                    <MenuItem value="approved">Approve</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={6} lg={6} xl={6} xs={12} mt={1}>
                <Typography
                  variant="h6"
                  align="start"
                  sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
                >
                  Remarks:
                </Typography>
                <TextField
                  label="Remarks"
                  required
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  name="hod_remarks"
                  id="hod_remarks"
                  value={formik.values.hod_remarks}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.hod_remarks &&
                    Boolean(formik.errors.hod_remarks)
                  }
                  helperText={
                    formik.touched.hod_remarks && formik.errors.hod_remarks
                  }
                />
              </Grid>
            </Grid>
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className="button-right"
            >
              <Box ml={2} display="inline-block">
                <LoadingButton
                  variant="contained"
                  onClick={() => {
                    handleDirectorSection();
                  }}
                  endIcon={<SendIcon />}
                  loadingPosition="end"
                  type="submit"
                >
                  <span>Submit</span>
                </LoadingButton>
              </Box>
            </Grid>
          </>
        ) : null}
      </Paper>
    </Box>
  );
};

export default RecognitionPreview;
