import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { api } from '../../services/api';
import { useEffect,useState } from 'react';


export default function StackedBarChart() {
  const [barLogs] = api.endpoints.barChartLogs.useLazyQuery();
  const [pending, setPending] = useState([]);
  const [approved, setApproved] = useState([]);
  const [name, setName] = useState([]);
  const barLogsFun = async () => {
    try {
      const barData = await barLogs(); // Fetch the data  
      // Ensure barData and its nested properties are defined before destructuring
      if (barData?.data?.data) {
        const { pending, approved, namelist } = barData.data.data;
        setPending(pending);
        setApproved(approved);
        setName(namelist);
      } else {
        console.error("barData structure is not as expected:", barData);
      }
    } catch (error) {
      console.error("Error fetching barLogs:", error);
    }
  };
  
  useEffect(() => {
    barLogsFun();
  }, []);
  
  // return;
  return (
    <BarChart
      width={550}
      height={280}
      series={[
        { data: pending, label: 'pending', id: 'pId', stack: 'total',color: 'red' },
        { data: approved, label: 'approved', id: 'aId', stack: 'total',color: 'green' },
      ]}
      xAxis={[{ data: name, scaleType: 'band' }]}
    />
  );
}
