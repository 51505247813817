import { ProtectedRoute } from "../../ProtectedRoute";
import Sidebar from "../Sidebar";

const renderSidebar = (element) => {
  return(
  <>
    <ProtectedRoute element={<Sidebar { ...{element}}/>} />
    
  </>
)};
export default renderSidebar;