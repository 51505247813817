import * as yup from 'yup';

const userSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  email: yup
    .string()
    .nullable()
    .email('Enter a valid email'),
  phone: yup.string().nullable(),
  password: yup
    .string()
    .nullable()
    .min(8, 'Password should be at least 8 characters long'),
  bio: yup.string().nullable(),
  image: yup.string().nullable(),
  gender: yup.string().nullable(),
});

export default userSchema;
