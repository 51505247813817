import { Box, Button, Grid, Paper, Switch, Typography,Stack,Chip } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Add, Delete, Edit } from "@mui/icons-material/";
import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import {
    useDeleteCustomFieldMutation,
  useGetCustomFieldQuery,
  useUpdateCustomFieldMutation,
} from "../../services/api";
import { useLocation,useNavigate } from 'react-router-dom';
import { green, blue, orange } from '@mui/material/colors';
import swal from "sweetalert";
import { toast, ToastContainer } from "react-toastify";

// const page = req.query.page*1 || 1;
// const limit =  req.query.limit*1 || {};

const StyledGridOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  "& .ant-empty-img-1": {
    fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
  },
  "& .ant-empty-img-2": {
    fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
  },
  "& .ant-empty-img-3": {
    fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
  },
  "& .ant-empty-img-4": {
    fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
  },
  "& .ant-empty-img-5": {
    fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
    fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
  },
}));

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg
        style={{ flexShrink: 0 }}
        width="240"
        height="200"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Rows</Box>
    </StyledGridOverlay>
  );
}
const getColorForRole = (field) => {
    switch (field) {
      case 'text':
        return green[500];
      case 'number':
        return blue[500];
      case 'date':
        return orange[500];
      default:
        return 'defaultColor'; // Set default color if role name doesn't match any predefined color
    }
  };

const CustomFieldIndex = () => {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const { data: customFieldList, isLoading, isError } = useGetCustomFieldQuery({
    page: paginationModel.page + 1,
    limit: paginationModel.pageSize
  }); // Get Incubation list

  const [customFieldDelete] = useDeleteCustomFieldMutation(); // Get Incubation list
  const [customFieldUpdate] = useUpdateCustomFieldMutation();
  const location = useLocation();
  const navigate = useNavigate();



  const handleChange = async (params) => {
    const newStatus = params.documentStatus === "active" ? "inactive" : "active";
    const res= await customFieldUpdate({
      id: params?._id,
      payload: { documentStatus: newStatus },
    });
       if(res.data.success){
      toast.success("Status is updated Successfully.", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  
  };
  const handleDelete = (id) => {
    swal({
      title: "Are you sure?",
      text: "Do you want delete the Custom Field!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        customFieldDelete(id);
        swal("Custom Field is deleted Successfully.!", {
          icon: "success",
        });
      } else {
        swal("Custom Field is safe!");
      }
    });
  };


  const columns = useMemo(() => {
    return [
      // {
      //   field: "_id",
      //   headerName: "ID",
      //   flex: 0.5,
      // },
      {
        field: "custom_fields",
        headerName: "Field",
        flex: 0.7,
        renderCell: (params) =>{
            return Array.isArray(params?.row?.custom_fields) && params?.row?.custom_fields.map((val, index) => (
              <Stack spacing={1} alignItems="center" key={val?._id}>
                <Stack direction="row" spacing={1}>
                  <Chip
                    label={val?.field.toUpperCase()}
                    sx={{ backgroundColor: getColorForRole(val.type) }} // Set background color based on role name
                  />
                </Stack>
              </Stack>
            ));
          },
      },
      {
        field: "documentStatus",
        headerName: "Status",
        flex: 0.7,
        renderCell: (params) => (
          <Switch
            value={params.row._id}
            checked={params.row.documentStatus === "active"}
            onChange={() => {
              handleChange(params.row);
            }}
            inputProps={{ "aria-label": "controlled" }}
          />
        ),
        
      },
      {
        field: "actions",
        headerName: "Actions",
        type: "actions",
        getActions: (params) => [
          <GridActionsCellItem
             color="success"
            icon={<Edit />}
            onClick={(event) => {
              navigate(location.pathname+`/upsert/${params?.row?._id}`)
            }}
            label="Edit"
          />,
          <GridActionsCellItem
             color="error"
            icon={<Delete />}
            onClick={() => {
              handleDelete(params.row._id);
            }}
            label="Delete"
          />,
        ],
      },
    ];
  }, []);
  const handlePageSizeChange = useCallback((data) => {
    setPaginationModel(data);
  }, [setPaginationModel])

  return (
    <Box marginTop={"6em"} padding={4}>
      {/* Inner container */}
      <ToastContainer />
       <Paper className="paper-card">
        {/* Header */}
        <Grid container>
          <Grid item lg={9} md={9} sm={9} xs={9}>
            <Typography variant="h5">Custom Fields</Typography>
          </Grid>
          <Grid className="button-right" item lg={3} md={3} sm={3} xs={3}>
            <Button
              variant="contained"
              onClick={(e) => {
               navigate(location.pathname + `/upsert`)
              }}
              startIcon={<Add />}
            >
              <Typography color="white" variant="body1">
                Add new
              </Typography>
            </Button>
          </Grid>
        </Grid>

        {/* Table */}
        <Box marginTop={2} width="100%">
          <DataGrid
            columns={columns}
            autoHeight
            getRowId={(row) => row._id}
            rowCount={customFieldList?.total}
            getRowHeight={() => "auto"}
            pageSizeOptions={[10, 25, 50, 100]}
            loading={isLoading}
            pagination
            columnBuffer={columns.length + 1}
            rowBuffer={10}
            paginationModel={paginationModel}
            paginationMode="server"
            onPageSizeChange={(newPageSize) => handlePageSizeChange(newPageSize)}
            onPaginationModelChange={(data) => handlePageSizeChange(data)}
            slots={{
              noRowsOverlay: CustomNoRowsOverlay,
              toolbar: GridToolbar,
            }}
            sx={{
              "--DataGrid-overlayHeight": "100%",
              width: "100%",
              marginBottom: "16px",
            }}
            rows={customFieldList?.data || []}
          />
        </Box>
      </Paper>
    </Box>
  );
};

export default CustomFieldIndex;
