import React from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Box, Button, Container, Divider, Grid, useTheme } from "@mui/material";
import image from "../../Assests/event-banner.jpg";
import CustomizedProgressBars from "../CustomProgress/ProgressBar";
import black_bg from "../../Assests/Event-bg.png";
import { upcomingImages } from "./constant";
import { useTranslation } from "react-i18next";
const currentDate = new Date();
const options = { day: "numeric", month: "long", year: "numeric" };
const formattedDate = currentDate.toLocaleDateString("en-US", options);

const UpcomingEvents = () => {
  const { t } = useTranslation();
  const theme=useTheme()
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        background: `url(${theme.palette.mode==="dark"?null:black_bg}) center center no-repeat`, // Center the background image
        backgroundSize: "cover",
        height: "100%",
        width: "100%",
        padding: "50px 0", // Equal padding
        justifyContent: "space-around",
        backgroundColor: (theme) => theme.palette.background.default,
        color: (theme) => theme.palette.text.primary,
      }}
    >
      <Container maxWidth="xl">
        <Grid container spacing={1}>
          <Grid textAlign={"center"} sx={{ marginBottom: 3 }} item xs={12}>
            <Typography
              component="h1"
              ml={2}
              sx={{ fontWeight: 700, fontSize: "x-large", color: "#fff" }}
            >
              {t("upcoming_events")}
            </Typography>
            <CustomizedProgressBars className={"section_sep"} val={50} />
          </Grid>

          <Grid item lg={8} xs={12}>
            <Paper
              sx={{
                padding: (theme) => theme.spacing(2),
              }}
            >
              <img
                src={image}
                style={{
                  fontSize: "larger",
                  width: "100%",
                  height: "100%",
                  marginBottom: "20px",
                  borderRadius: 5,
                }}
              />
              <Typography
                component="h1"
                sx={{ fontWeight: 700, fontSize: "large" }}
              >
                {t("start_up_title")}
              </Typography>
              <Typography className="event-date">{formattedDate}</Typography>
              <Typography className="para-margin" variant="p" component="p">
                {t("upcoming_text")}
              </Typography>

              <Button variant="contained"> {" Know more>>"}</Button>
            </Paper>
          </Grid>
          <Grid item lg={4} xs={12} sx={{}}>
            <Container
              sx={{
                padding: (theme) => theme.spacing(2),
                backgroundColor: "#fff",
                height: "100%", // Set height to 100%
                maxHeight: "643px", // Set maximum height as needed
                marginRight: 10,
                overflowY: "scroll", // Enable vertical scroll
                borderRadius: 1,
                backgroundColor: (theme) => theme.palette.background.default,
                color: (theme) => theme.palette.text.primary,
              }}
            >
              {upcomingImages.map((val) => (
                <Box
                  className="event-right-box"
                  key={val?.id}
                  sx={{ padding: 1, margin: 0 }}
                >
                  <Typography
                    sx={{ color: "#000", fontWeight: 500, marginTop: 2 }}
                    component="h3"
                  >
                    {t('date')}
                  </Typography>
                  <img
                    src={val?.img}
                    style={{
                      width: "100%",
                      marginBottom: "10px",
                      borderRadius: 5,
                      marginTop: 20,
                    }}
                    alt="Image Alt Text"
                  />
                  <Typography
                    sx={{ color: "blue", fontWeight: 700, marginTop: 2 }}
                    component="h2"
                  >
                   {t('himayalan_startup_trek')}
                  </Typography>
                </Box>
              ))}
            </Container>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default UpcomingEvents;
