import React, { useRef } from "react";
import Cubators from "../Incubators/index.jsx";
import { SliderV3 } from "../../Components/Slider.js/Slider.jsx";
import IframeSection from '../Iframe/IframeSection.jsx';
import HpMap from '../HpMap/HpMap.jsx';
import Details from '../Details/Details.jsx';
import EcoSystem2 from "../Ecosystem/index.jsx";
import UpcomingEvents from "../Events/UpcomingEvents.jsx";
import Navbarv2 from "../../Components/Navbar/indexv2.jsx";
import Footerv2 from "../Footer/indexv2.jsx";


const Homev2 = ({mainContentRef}) => {
  return (
    <>
      {/* <Navbarv2 /> */}
      <SliderV3 />
      {/* <SlickSlider/> */}
      <IframeSection />
      <HpMap {...{mainContentRef}}/> 
      <UpcomingEvents/>
      <Details />
      <EcoSystem2 />
      <Cubators />
      {/* <Footerv2 {...{home:true}}/> */}
    </>
  );
};

export default Homev2;
