import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Typography, Button, Grid } from "@mui/material";
import { CheckTokenValidity } from "../Helpers/CheckTokenValidity";

const PageNotFound = () => {
  const navigate = useNavigate();
  const [isValid, setIsValid] = useState(false);
  const token = localStorage.getItem("token");
  async function checkToken() {
    const value = await CheckTokenValidity(token);
    setIsValid(value)
  }

  useEffect(() => {
    checkToken();
  }, []);
  //update the navigation in the component

  return (
    <Container sx={{ backgroundColor: (theme) =>
      theme.palette.background.default,
    color: (theme) => theme.palette.text.primary}} maxWidth="xxl">
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{ height: "100vh" }}
      >
        <Grid item>
          <Typography
            variant="h1"
            sx={{
              fontSize: { xs: 150, md: 300 },
              color: "#000000",
              textAlign: "center",
              backgroundColor: (theme) =>
                theme.palette.background.default,
              color: (theme) => theme.palette.text.primary
            }}
          >
            404
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h3" sx={{ color: "red", textAlign: "center" }}>
            Page Not Found.
          </Typography>
        </Grid>
        <Grid item>
          <Button
            onClick={() => navigate(isValid ? `/dashboard` : `/sign-in`)}
            variant="contained"
            color="primary"
          >
            {isValid ? "Go To Dashboard" : "Back To Login"}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PageNotFound;
