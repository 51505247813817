import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import * as yup from "yup";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { IconButton, InputAdornment, Paper, useTheme } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  api,
  useLoginUserMutation,
  useLoginUserOtpMutation,
  usePostOTPMutation,
} from "../../services/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { setLoginDetails, setSideBarContent } from "../../Redux/GlobalSlice";
import smallLogo from "../../Assests/hp_icon.png";
import black_bg from "../../Assests/Event-bg.png";
import {
  LoadCanvasTemplate,
  loadCaptchaEnginge,
  validateCaptcha,
} from "react-simple-captcha";
import { CheckTokenValidity } from "../../Helpers/CheckTokenValidity";

const validateSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),

  password: yup
    .string("Please enter password")
    .required("Password is required"),
});

const LoginTabs = () => {
  const [value, setValue] = useState("1"); // State to manage active tab
  const [showPassword, setShowPassword] = useState(false);
  const [showOTPField, setShowOTPField] = useState(false);
  // const [open, setOpen] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loginUser] = useLoginUserMutation();
  const [userData] = api.endpoints.applicationDetail.useLazyQuery();
  const [loginUserOtp] = useLoginUserOtpMutation();
  const [postMob] = usePostOTPMutation();
  const theme=useTheme()

  // const {data:routeList}= useMenuDetailsQuery();
  const [routeList] = api.endpoints.menuDetails.useLazyQuery();

  const [captcha, setCaptcha] = useState("");
  // const [captcha1, setCaptcha1] = useState("");

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      // mobile: "",
      // otp: "", // Added OTP field
    },
    validationSchema: validateSchema,

    onSubmit: async (values) => {
      const {
        data: loginData,
        error,
        isLoading,
      } = await loginUser({ ...values });
      if (!validateCaptcha(captcha) == true) {
        toast.error("Captcha did not match", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        loadCaptchaEnginge(6);
        return;
      }
      if (error) {
        toast.error(error.data?.message ?? "Something went wrong", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      if (loginData?.success) {
        dispatch(setLoginDetails(loginData?.data));
        localStorage.setItem("user_district", loginData?.data?.district);
        localStorage.setItem("role", loginData?.data?.role[0]?.name);
        if(loginData?.data?.role[0]?.name.toLowerCase()==="mentor")localStorage.setItem("user_id", loginData?.data?.id);
        const token = loginData.data.token;
        if (!localStorage.getItem("personal_details")) {
          const response = await userData(token);
          if (response?.data?.success) {
            localStorage.setItem(
              "userData",
              JSON.stringify(response?.data?.data)
            );
          }
        }
        if (!localStorage.getItem("token")) {
          localStorage.setItem("token", token);
          const res = await routeList(token);
          const { data } = res?.data?.total;
          dispatch(setSideBarContent(data));
         localStorage.getItem("role").toLowerCase()==="mentor"? navigate("/mentor-dashboard"):navigate("/dashboard");
        } else if (localStorage.getItem("token")) {
          localStorage.getItem("role").toLowerCase()==="mentor"? navigate("/mentor-dashboard"):navigate("/dashboard");
        }
      }
    },
  });
  async function checkToken(){
    const token = localStorage.getItem('token'); 
    try {
      if (!token) {
        navigate("/sign-in");
      }else{
        const value= await CheckTokenValidity(token,navigate)
        if(value){
           navigate("/dashboard");
        }
  
      }
        
    } catch (error) {
        console.error("Invalid token, redirecting to login:", error);
    }
}

  useEffect(() => {
    checkToken();
  }, []);

  

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  useEffect(() => {
    // setTimeout(()=>{
    loadCaptchaEnginge(6, "grey");
    // },100)
  }, [value]);

  const handleLoginWithOTP = () => {
    navigate("/sign-in-otp");
    // formik.setFieldValue("login_with_otp", true);
  };
  return (
    <Box
    component="main"
    maxWidth="xxl"
    sx={{
      display: "flex",
      flexDirection: "row",
      background: `url(${theme.palette.mode==="dark"?null:black_bg}) center center no-repeat`, // Center the background image
      backgroundSize: "cover",
      width: "100%",
      height: "80%",
      justifyContent: "space-around",
      backgroundColor: (theme) =>
        theme.palette.background.default,
      color: (theme) => theme.palette.text.primary
    }}
    >
      <Box>
          <Container component="main" className="login-page d-flex h-100 justify-content-center align-items-center" maxWidth="xs">
              <CssBaseline />
              <ToastContainer />

              <Paper
                sx={{
                  padding: "2em 2em",
                  borderRadius: "3em",
                  marginBottom: "4em",
                  marginTop: "3em",
                }}
                elevation={5}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <LockOutlinedIcon />
                        </Avatar> */}
                  <img src={smallLogo} alt="" />
                  {
                    <Typography component="h1" variant="h5">
                      Sign In
                    </Typography>
                  }

                  <Box
                    component="form"
                    onSubmit={formik.handleSubmit}
                    noValidate
                    sx={{ mt: 1 }}
                  >
                    <TextField
                      margin="dense"
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                      autoFocus
                    />

                    {/* PASSWORD */}
                    <TextField
                      margin="dense"
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type={showPassword ? "text" : "password"}
                      id="password"
                      autoComplete="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.password &&
                        Boolean(formik.errors.password)
                      }
                      helperText={
                        formik.touched.password && formik.errors.password
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleTogglePasswordVisibility}
                              edge="end"
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Box className="captcha-continer">
                        <LoadCanvasTemplate
                          reloadText="Get a new code"
                          reloadColor="white"
                        />
                        <Box className="input-block">
                          <TextField
                            margin="dense"
                            required
                            fullWidth
                            name="captcha"
                            type="text"
                            id="captcha"
                            placeholder="Enter Verification code"
                            value={captcha}
                            onChange={(e) => setCaptcha(e.target.value)}
                          />
                        </Box>
                      </Box>
                    </Grid>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 1, mb: 1 }}
                    >
                      Sign In
                    </Button>
                    <Grid className="or-text">
                      OR
                    </Grid>
                    <Button
                      fullWidth
                      variant="contained"
                      color="secondary"
                      sx={{ mt: 1, mb: 1 }}
                      onClick={handleLoginWithOTP}
                    >
                      Login with OTP
                    </Button>
                    <Grid container textAlign={"center"}>
                      <Grid item xs>
                        <Link
                          to="/"
                          variant="body2"
                          style={{ textDecoration: "none" }}
                        >
                          Forgot password?
                        </Link>
                      </Grid>
                      <Grid item textAlign={"center"} width={"100%"}>
                        <Link
                          to="/sign-up"
                          variant="body2"
                          style={{
                            textDecoration: "none",
                            textAlign: "center",
                          }}
                        >
                          {"Don't have an account? Sign Up"}
                        </Link>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Paper>
            </Container>
          </Box>
    </Box>
  );
};

export default LoginTabs;
