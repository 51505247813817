
export const CheckTokenValidity = (token,navigate) => {
    if(!token) return;
    const tokenParts = token.split(" ");
    const actualToken = tokenParts.length > 1 ? tokenParts[1] : tokenParts[0];
    const payloadBase64 = actualToken?.split(".")[1];
    if (!payloadBase64) return;

    const decodedPayload = JSON?.parse(atob(payloadBase64));
    const exp = decodedPayload.exp;
    const currentTime = Math.floor(Date.now() / 1000);

    if (currentTime > exp) {
        navigate('/sign-in')
        return false;
    } else {
        return true;
    }
};
