import React, { useRef, useMemo, useState } from "react";
import {
  Box,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  Paper,
  Select,
  Stack,
  Switch,
  Typography,
  TextField,
  Checkbox,
  MenuItem,
  Button,
} from "@mui/material";
import JoditEditor from "jodit-react";
import { useFormik } from "formik";
import * as yup from "yup";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useNavigate, useParams } from "react-router-dom";
import {
  api,
  useCreateCmsMutation,
  useGetCategoryQuery,
  useGetPostTypeQuery,
  useUpdateCmsMutation,
} from "../../services/api";
import CustomizedProgressBars from "../CustomProgress/ProgressBar";
import { ToastContainer, toast } from "react-toastify";

export function TextEditor() {
  const editor = useRef(null);
  const { id } = useParams();
  const config = useMemo(() => {
    return {
      readonly: false,
      placeholder: id? "" :"Start typing...",
      style: {
        height: "300px",
      },
      uploader: {
        insertImageAsBase64URI: true,
      },
      defaultMode: "1",
    };
  }, []);
  const schema = yup.object().shape({
    content: yup.string(),
    title: yup.string().required("title is required"),
    selectedCategories: yup.array().required(),
    customfields: yup.array().required(),
    post_typeId: yup.string(),
    image: yup.string(),

  });
  const [imagePreview, setImagePreview] = useState(null);
  const { data: categoryList, isLoading, isError } = useGetCategoryQuery();
  const { data: postTypeList } = useGetPostTypeQuery();
  const [getCustomFieldList] =
    api.endpoints.getCustomFieldPosttype.useLazyQuery();
  const [createCms] = useCreateCmsMutation();
  const [updateCms] = useUpdateCmsMutation();
  const navigate = useNavigate();
  const [getSingleCms] = api.endpoints.singleCms.useLazyQuery();

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    formik.setFieldValue("image", event.target.files[0]);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const formik = useFormik({
    initialValues: {
      content: "",
      title: "",
      selectedCategories: [],
      customfields: [],
      post_typeId: "",
      image: "",
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      try {
        const extrafields = {};
        values.customfields.forEach((field) => {
          // Retrieve field value from formik.values
          const fieldValue = values[field.field];
          // Add field and value to the extrafields object
          extrafields[field.field] = fieldValue;
        });
      const formData = new FormData();
      formData.append("image", values?.image);
      formData.append("title", values?.title);
      formData.append("content", values?.content);
      formData.append("post_typeId", formik.values.post_typeId);
      formData.append("categoryId", values?.selectedCategories);
      formData.append("extrafields", JSON.stringify(extrafields));
        if(id){
        //call api here to submit the data from front-end here
        const obj={
          id:id,
          payload:formData
        }
        const res = await updateCms(obj);
        
        if (res?.data?.success) {
          await formik.setValues((prev) => ({
            ...prev,
            content:'',
            title:'',
            selectedCategories: [],
            // customfields:categoryId,
            post_typeId:'',
            image:"",
          }));
          navigate("/cms");
        }else{
          toast.error(res?.error?.data?.message||"Something went wrong!", {
            position: "top-center",
            autoClose:  2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } 
        }else{
      
          //call api here to submit the data from front-end here
          const {data} = await createCms(formData);
          
          if (data?.success) {
            await formik.setValues((prev) => ({
              ...prev,
              content:'',
              title:'',
              selectedCategories: [],
              // customfields:categoryId,
              post_typeId:'',
              image:"",
            }));
            navigate("/cms");
          }
        }
      } catch (error) {
        console.log("error=>", error);
      }
    },
  });

  const handleCheckboxChange = (id, checked) => {
    if (checked) {
      // Add the category to the selectedCategories array
      formik.setFieldValue("selectedCategories", [
        ...formik.values.selectedCategories,
        id,
      ]);
    } else {
      // Remove the category from the selectedCategories array
      formik.setFieldValue(
        "selectedCategories",
        formik.values.selectedCategories.filter(
          (categoryId) => categoryId !== id
        )
      );
    }
  };
  const handlePostChange = async (e) => {
    formik.setFieldValue("post_typeId", e.target.value._id);
    const { data } = await getCustomFieldList({
      id: e.target.value._id,
    });
    if (data?.success) {
      formik.setValues((prev) => ({
        ...prev,
        customfields: data?.data?.custom_fields,
      }));
    }
    
  };
  React.useEffect(() => {
    const UpdateCms = async () => {
      if (id) {
        try {
          const {
            data: { data },
          } = await getSingleCms(id);
          const { categoryId, content, image, post_typeId, title } =
          data?.postdata;
          await formik.setValues((prev) => ({
            ...prev,
            content,
            title,
            selectedCategories: categoryId,
            // customfields:categoryId,
            // post_typeId:post_typeId.join(','),
            image,
          }));
          formik.setFieldValue("post_typeId", post_typeId.join(','));
         
          formik.setFieldValue("image", image);
         
          // setImagePreview("http://10.1.5.7:5001/"+image)
        } catch (error) {
          console.error("Error fetching permission data:", error);
        }
      }
    };
    UpdateCms();
  }, [id]);

  
  return (
    <Box marginTop={"6em"} padding={4}>
    <Grid
      container
      md={12}
      lg={12}
      xl={12}
      xs={12}
      sx={{ gap:2 }}
      // onSubmit={(e) => {
      //   e.preventDefault();
      //   // Handle form submission here if needed
      // }}
    >
      <ToastContainer />
      <Grid md={12} lg={12} xl={12} xs={12}>
          <Typography
            textAlign={"center"}
            variant="h5"
            fontWeight={"600"}
            mb={1}
          >
           CMS
          </Typography>
          <CustomizedProgressBars
          className={"section_sep"}
            variant="determinate"
            val={50}
          />
        </Grid>
      <Grid md={12} lg={12} xl={12} xs={12}>
        <Paper className="paper-card" elevation={3} sx={{ p: 2 }}>
          <Grid item>
            <Typography
              variant="h6"
              align="start"
              sx={{ fontWeight: 500, fontSize: "16px" }}
            >
              Title:
            </Typography>
            <FormControl fullWidth mb={2}>
              <TextField
                margin="dense"
                required
                fullWidth
                id="title"
                label="Title"
                name="title"
                autoComplete="off"
                value={formik.values.title}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.title && Boolean(formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title}
              />
            </FormControl>
          </Grid>
          {/* <Grid item>
            <Typography
              variant="h6"
              align="start"
              sx={{ fontWeight: 500, fontSize: "16px" }}
            >
              Category:
            </Typography>
            <FormControl fullWidth>
              <TextField
                margin="dense"
                required
                fullWidth
                id="category"
                label="category"
                name="category"
                autoComplete="off"
                value={formik.values.category}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.category && Boolean(formik.errors.category)
                }
                helperText={formik.touched.category && formik.errors.category}
              />
            </FormControl>
          </Grid> */}
          <Grid mb={2} item>
            <JoditEditor
              ref={editor}
              value={formik?.values?.content}
              config={config}
              tabIndex={1} // tabIndex of textarea
              onChange={(data) => {
                formik.setFieldValue("content", data);
              }}
            />
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <InputLabel id="district">Select Post Type</InputLabel>
              <Select
                labelId="Post Type"
                required
                // fullWidth
                id="Post Type"
                label="Post Type"
                name="Post Type"
                autoComplete="off"
                placeholder="Post Type"
                onChange={(e) => handlePostChange(e)}
              >
                {postTypeList?.data?.map((item) => (
                  <MenuItem key={item.id} value={item}>
                    {item.post_type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {formik.values.customfields.length > 0 ? (
            <>
              {Array.isArray(formik.values.customfields) &&
                formik.values.customfields.map((val) => {
                  return (
                    <React.Fragment key={val?._id}>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <TextField
                          margin="dense"
                          fullWidth
                          type={val?.type}
                          // disabled
                          required
                          id={`field-${val?._id}`}
                          label={val?.field?.toUpperCase()}
                          name={`${val?.field}`}
                          placeholder="Field"
                          autoComplete={"off"}
                          // value={formik.values.fieldvalues}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </Grid>
                    </React.Fragment>
                  );
                })}
            </>
          ) : null}
        </Paper>
      </Grid>
      <Grid container spacing={3}>
        <Grid item lg={6} md={6} sm={6} xs={6}>
        <Paper className="paper-card">
          <Typography
            variant="h6"
            align="start"
            sx={{ fontWeight: 500, fontSize: "16px" }}
          >
            Category:
          </Typography>
          {!isLoading &&
            categoryList?.data?.map((item) => {          
              return (
                <Grid container display="flex" key={item._id}>
                  <Grid item>
                    <Checkbox
                      checked={formik.values.selectedCategories.includes(
                        item._id
                      )}
                      onChange={(e) =>
                        handleCheckboxChange(item._id, e.target.checked)
                      }
                      name="read"
                    />
                  </Grid>
                  <Grid item mt={1}>
                    <Typography>{item.name}</Typography>
                  </Grid>
                </Grid>
              );
            })}

          {/* <Grid item>
            <Checkbox checked={false} onChange={() => {}} name="read" />
          </Grid>
          <Grid item>
            <Checkbox checked={false} onChange={() => {}} name="read" />
          </Grid> */}
        </Paper>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={6}>
        <Paper className="paper-card" elevation={3}>
          <Typography
            variant="h6"
            align="start"
            sx={{ fontWeight: 500, fontSize: "16px" }}
          >
            Featured Image:
          </Typography>
          <br />
          <FormControl fullWidth>
            <input
              accept="image/*"
              id="image"
              type="file"
              onChange={handleImageChange}
            />
            <Grid>
              <br />
              <Grid item>
                <Typography
                  variant="h6"
                  align="start"
                  sx={{ fontWeight: 500, fontSize: "16px" }}
                >
                  {id?null:"Preview"} Image:
                </Typography>
              </Grid>
              <br />
              <Grid item>
                {imagePreview && (
                  <Typography>
                    <img
                      src={imagePreview}
                      alt="Preview"
                      style={{
                        width: "100%",
                        height: "235px",
                        borderRadius: "8px",
                      }}
                    />
                  </Typography>
                )}
              </Grid>
            </Grid>
          </FormControl>
        </Paper>
        </Grid>

      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
      <Button
        type="submit"
        // disabled={!isSubmitEnable}
        variant="contained"
        onClick={formik.handleSubmit} 
      >
        Submit
      </Button>
      </Grid>

    </Grid>
    </Box>
  );
}
 
