import * as Yup from 'yup';

// Regular expressions for PAN and Aadhaar validation
const panRegExp = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
const aadhaarRegExp = /^\d{12}$/;
const pinRegExp = /^\d{6}$/;

const validationSchema = Yup.object().shape({
  name_of_the_founder: Yup.string()
    .required('Name of the founder is required')
    .max(100, 'Name of the founder should be at most 100 characters long'),
  name_of_the_cofounder: Yup.string().nullable(),
  contact: Yup.string()
    .required('Contact number is required')
    .matches(/^\d{10}$/, 'Contact number must be exactly 10 digits'),
  pan: Yup.string()
    .required('PAN is required')
    .matches(panRegExp, 'Invalid PAN format'),
  aadhaar: Yup.string()
    .required('Aadhaar is required')
    .matches(aadhaarRegExp, 'Aadhaar must be exactly 12 digits'),
  address_of_the_founder: Yup.string()
    .required('Address of the founder is required')
    .max(255, 'Address of the founder should be at most 255 characters long'),
  lac: Yup.string()
  .required('Legislative Assembly Contituency is required'),
  parliment: Yup.string().required('Parliament  is required'),
  // constituency:Yup.string().required('Contituency is required'),
  state: Yup.string()
    .required('State is required')
    .max(100, 'State should be at most 100 characters long'),
  district: Yup.string()
    .required('District is required')
    .max(100, 'District should be at most 100 characters long'),
  tehsil: Yup.string()
    .required('Tehsil is required')
    .max(100, 'Tehsil should be at most 100 characters long'),
  block: Yup.string()
    .required('Block is required')
    .max(100, 'Block should be at most 100 characters long'),
  pin: Yup.string()
    .required('PIN is required')
    .matches(pinRegExp, 'PIN must be exactly 6 digits')
});

export default validationSchema;