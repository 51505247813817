export function PermissionCheck({ content = [], setPer = () => {}, pathname=null }) {
  let permissions = {};
  if (Array.isArray(content)) {
    content.forEach((val) => {
      if(val?.frontend_url===pathname){  
        permissions = { ...permissions, [val.frontend_url.slice(1).replace(/-/g, '_')]: val?.permission };
      }else{
        permissions={...permissions, [val.frontend_url.slice(1).replace(/-/g, '_')]:val.permission}
      }

    });
    setPer(permissions);
  }
}
