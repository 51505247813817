const NavRouter = [
  {
    id: 1,
    key: "/",
    value: "home",
  },
  {
    id: 2,
    key: "/about-us",
    value: "About Us",
  },
  {
    id: 3,
    key: "/startup-hub",
    value: "Startup Hub",
  },
  {
    id: 4,
    key: "/ecosystems",
    value: "Ecosystems",
  },
  {
    id: 5,
    key: "/notifications",
    value: "Notifications",
  },
  {
    id: 6,
    key: "/account",
    value: "Account",
  },
  {
    id: 7,
    key: "/sign-up",
    value: "Sign Up",
  },

  {
    id: 8,
    key: "/MentorRegister",
    value: "MentorRegister",
  },
];
export default NavRouter;

export const NavRouting = [
  {
    id: 1,
    key: "/",
    value: "home",
  },
  // {
  //   id: 2,
  //   key: "/about-us",
  //   value: "About Us",
  // },
  // {
  //   id: 3,
  //   key: "/startup-hub",
  //   value: "Startup Hub",
  // },

  {
    id: 4,
    key: "/incubation-centre",
    value: "Incubation Centre",
  },

  {
    id: 5,
    key: "/grievance-form",
    value: "Grievance Form",
  },

  {
    id: 6,
    key: "/proceedings",
    value: "Proceedings",
  },

  // {
  //   id: 7,
  //   key: "/ecosystems",
  //   value: "Ecosystems",
  // },
  // {
  //   id: 8,
  //   key: "/notifications",
  //   value: "Connnect With Mentor",
  // },
  // {
  //   id: 9,
  //   key: "/account",
  //   value: "Account",
  // },
  {
    id: 10,
    key: "/sign-in",
    value: "Login",
  },
  {
    id: 11,
    key: "/sign-up",
    value: "Register",
  },
  {
    id: 12,
    key: "/sign-in",
    value: "Direct Application",
  },
  {
    id: 13,
    key: "/grievance-queries",
    value: "Grievance Queries",
  },
];
