import {
  Box,
  Grid,
  List,
  ListItem,
  Typography,
  Link as MaterialLink,
  useTheme,
  Container,
} from "@mui/material";
import BlueBG from "../../Assests/footer-bg.jpg";
import Logo from "../../Assests/logo-footer.png";
import { Link, useLocation } from "react-router-dom";
import FooterRoute from "./FooterRoute";
// import "./style.scss";
// ASSETS
import Facebook from "../../Assests/Facebook@2x.png";
import closebtn from "../../Assests/twitter-X.png";
import { useTranslation } from "react-i18next";
const Footerv2 = ({ home }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Box
      className="footer"
      sx={{  position: "relative", padding: 0 ,backgroundColor: (theme) => theme.palette.background.default,
        color: (theme) => theme.palette.text.primary}}
    >
      {home && home !== undefined ? (
        <Box className="my-footer"
          component="div"
          pt={6}
          pb={4}
          sx={{ backgroundImage: ` url(${theme.palette.mode==="dark"?null:BlueBG})` ,  backgroundColor: (theme) => theme.palette.background.default,
           }}
        >
          <Container maxWidth="xl">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                <Link className="footer-logo" to="/">
                  <Box 
                    component="img"
                    src={Logo}
                    alt="Logo not found"
                    // sx={{
                    //   height: "70px",
                    //   maxWidth: "100%",
                    //   color: "#000000",
                    // }}
                  />
                </Link>
                <Box className="about-footer">
                  <Typography
                  variant="p"
                  component="p"
                >
                  {t("footer_text")}
                </Typography>

                </Box>
                <Box className="footer-social-icon" display={"flex"} gap={2}>
                  <MaterialLink>
                    <Box
                      component="img"
                      src={Facebook}
                      sx={{
                        height: "35px",
                        width: "35px",
                        borderRadius: 4,
                      }}
                    />
                  </MaterialLink>
                  <MaterialLink>
                    <Box
                      component="img"
                      src={closebtn}
                      sx={{ height: "35px", width: "35px" }}
                    />
                  </MaterialLink>
                </Box>
              </Grid>

              <Grid className="foot-menu" item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Typography className="font-bold"
                  variant="h5"
                  component="h5"
                >
                  {t("Quick Links")}
                </Typography>
                
                <List className="footer-menu" sx={{ listStyleType: "disc",  color: (theme) => theme.palette.text.primary}}>
                  {FooterRoute.map((item) => (
                    <ListItem className="foot-link-li" sx={{ display: "list-item",color: (theme) => theme.palette.text.primary }} key={item.id}>
                      {/* <ListItemButton className="footer-text" > */}
                      <Link 
                        to={{ pathname: `${item?.key}` }}
                        style={{
                          textDecoration:'none',
                          color:  theme.palette.text.primary
                          // color: "#fff",
                        }}
                      >
                        {t(item?.value)}
                      </Link>
                      {/* </ListItemButton> */}
                    </ListItem>
                  ))}
                </List>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={3}
                lg={3}
                xl={3}
              >
                <Typography className="font-bold"
                  variant="h5"
                  component="h5"
                >
                  {t("Contact Us")}
                </Typography>

                <Grid className="foot-contact-info">
                  <Grid className="helpline-list">
                    <Typography
                      variant="body1"
                      color="textPrimary"
                      fontWeight={700}
                    >
                      {t("Helpline")} :&nbsp;
                    </Typography>
                    <Typography
                  variant="p"
                  component="p"
                >
                 +91-79739-46782
                </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      color="textPrimary"
                      fontWeight={700}
                    >
                      {t("Address")} :
                    </Typography>
                    <Typography
                  variant="p"
                  component="p"
                >
                  {t("address1")}
                </Typography>

                  </Grid>
                </Grid>
              </Grid>
              {/* <Box mt={2} style={{ display: "flex", justifyContent: "flex-end" }}>
  <img src={Map} alt="Map" style={{ height: "100%", width: "20%" }} />
</Box> */}
            </Grid>
          </Container>
        </Box>
      ) : null}

      {/* Footer Bottom */}
      <Box component={"div"} className="footer-main">
        <Container maxWidth="xl">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Typography variant="p" color={"#ffffff"} fontWeight={"500"}>
                &#169; Copyright 2024 Startup Himachal
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Typography
                variant="p"
                className="footer-bottom-text"
                color={"#ffffff"}
              >
                Terms of use | Privacy Policies | Disclaimer | Sitemap
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
      {/* <Grid className="footer-bottom" 
      // style={{position: 'fixed', bottom: 0, left: 0, width: '100%',}}
      >
        <Container maxWidth="lg">
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Typography variant="p" color={"#ffffff"} fontWeight={"500"}>
                &#169; Copyright 2024 Startup Himachal
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="p"
                className="footer-bottom-text"
                color={"#ffffff"}
              >
                Terms of use | Privacy Policies | Disclaimer | Sitemap
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Grid> */}
      {/* <Container spacing={2}>
          <Grid item xs={6}>
          <Typography
              variant="p"
              color={"#ffffff"}
              fontWeight={"500"}
            >
              &#169; Copyright 2024 Startup Himachal
            </Typography>
          </Grid>
          <Grid item xs={6}>
          <Typography
                variant="p"
                className="footer-bottom-text"
                color={"#ffffff"}
              >
                Terms of use | Privacy Policies | Disclaimer | Sitemap
              </Typography>
          </Grid>
         </Container> */}
    </Box>
  );
};

export default Footerv2;
