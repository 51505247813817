import { lightBlue } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    primary: {
        main: lightBlue
    },
    secondary: {
        main: ''
    },
    color: {
      orange: "#F4912F",
      blueDark: "#1761A0",
      purple: "#E12EAB",
      black: "#0A0A0A",
      gray: "#ccc",
      darkGray: "#616161",
      lightOrange: "rgb(244,145,47)",
      lightPink: 'rgb(243,234,234)'
    },
    typography: {
        fontFamily: [
          'Poppins',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif'
        ].join(','),
      },
    introText: {
        fontFamily: 'Poppins',
        }
        
})

export default theme;